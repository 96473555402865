import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../models/question-base';
import { startWith, map } from 'rxjs/operators';
import { AsEnumerable } from 'linq-es2015';
import { DropdownQuestion } from '../../models/question-dropdown';
import { Observable } from 'rxjs';

@Component({
	selector: 'fitech-workspace-autocomplete-md',
	templateUrl: './autocomplete-md.component.html',
	styleUrls: ['./autocomplete-md.component.scss']
})
export class AutocompleteMdComponent implements OnInit {
	@Input() question: DropdownQuestion;
	@Input() form: UntypedFormGroup;
	filteredOptions: Observable<{ key: string; value: string; data?: any; display?: string; icon?: string }[]>;
	@Input() headerLabel: string;
	@Input() showUndoButton: boolean;
	constructor() { }

	ngOnInit() {
		this.form.controls[this.question.key].valueChanges.subscribe((x) => this.onValueChanged(x));
		this.setOptions();
		this.question.optionsHasChanged.subscribe((options) => {
			this.refreshOptions();
		});
	}

	setOptions() {
		return (this.filteredOptions = this.form.controls[this.question.key].valueChanges.pipe(
			startWith(''),
			map((value) => {
				return AsEnumerable(this.question.options)
					.Where((x) => {
						const val = this.getInputValueAsString(value);
						return x.value && x.value.toLowerCase().includes(val.toLowerCase());
					})
					.ToArray();
			})
		));
	}

	refreshOptions() {
		this.setOptions().subscribe();
		this.validateIfValueIsInData(this.form.controls[this.question.key].value);
	}

	onValueChanged(value: any): void {
		if (value != null) {
			const result = this.validateIfValueIsInData(value);
			if (result && typeof value === 'string') {
				this.setValue(value);
			}
			this.question.icon = value.icon ? value.icon : undefined;
		}
	}

	undo() {
		this.form.controls[this.question.key].setValue(this.question.value);
		this.form.controls[this.question.key].markAsPristine();
		this.form.controls[this.question.key].markAsUntouched();
	}

	private setValue(value: any) {
		const valueToSet = AsEnumerable(this.question.options)
			.Where((x) => x.value.toLowerCase() === (value || '').toLowerCase())
			.FirstOrDefault();
		if (valueToSet) {
			this.form.controls[this.question.key].setValue(valueToSet);
		}
	}

	private validateIfValueIsInData(value: any) {
		const val = this.getInputValueAsString(value);
		const isError = !AsEnumerable(this.question.options).Any(
			(x) => x.value.toLowerCase() === (val || '').toLowerCase()
		);
		if (isError) {
			this.form.controls[this.question.key].setErrors({ incorrect: true });
		} else {
			this.form.controls[this.question.key].setErrors(null);
		}
		return !isError;
	}

	public generateDisplayText(item: any) {
		if (item) {
			return item.display || item.value;
		}
	}

	private getInputValueAsString(value: any): string {
		let val = '';
		if (value && typeof value === 'string') {
			val = value;
		} else if (value) {
			val = value.value;
		}
		return val;
	}
}
