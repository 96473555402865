export const environment = {
	name: 'sensors',
	production: true,
	apiUrl: 'https://connected-machines-frontendapi-sensors.azurewebsites.net/api/',
	apiVersion: 'v2',
	masterDataApiUrl: 'https://connected-machines-frontendapi-sensors.azurewebsites.net',
	elkApmServerUrl: 'https://connected-machines-logs.westeurope.cloudapp.azure.com:8200',
	dataScienceApiUrl: 'https://connected-machines-machinestates-api-prod.azurewebsites.net/api/',
	dataScienceApiHostMasterKey: 'czBXPmGiFwx_goKn4YzggvbLJTKl3nL7tNTo4yzSYwSiAzFuIMIeKQ==',
	context: 'Machines',
	here: {
		appId: 'oHgVwjcHdWcS4xt2fO77',
		appCode: 'WlsVileFgyAPmA0oDpqwHQ',
	},
	logoSrc: '../assets/logo/logo-main-page2.svg',
	logoAdSrc: '../assets/logo/logo_fideltronik.svg',
	forceUploadRecipeInDev: true,
};
