import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { StringUtils } from '@fitech-workspace/core-lib';
import { NotificationService } from './notification.service';
import { ErrorResponse, Result } from '../models';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlingService {
	private readonly _fallbaackErrorMessage = 'Operation failed. Please try again.';

	constructor(private _notificationService: NotificationService) {}

	handleRequest<T>(request$: Observable<T>): Observable<T> {
		return request$.pipe(
			tap((results: any) => this.handleSuccess(results)),
			catchError((error: HttpErrorResponse) => {
				this.handleError(error);
				return throwError(error);
			})
		);
	}

	handleError(error: HttpErrorResponse, fallbackErrorMessage?: string): void {
		switch (error.status) {
			case HttpStatusCode.Forbidden:
				this._notificationService.showError(`The user doesn't have enough permissions for this operation.`);
				break;
			default: {
				const errorMessage: string = this.getErrorMessage(error, true, fallbackErrorMessage);
				if (errorMessage) {
					this._notificationService.showError(errorMessage);
				}
				break;
			}
		}
	}

	isForbidden(error: HttpErrorResponse): boolean {
		return error.status === HttpStatusCode.Forbidden;
	}

	handleResultError<T>(observable: Observable<T>, showNotification: boolean = true, checkDuplicates?: boolean, duration?: number): Observable<Result<T>> {
		return observable.pipe(
			map((data: T) => {
				return Result.success<T>(data);
			}),
			catchError((err: HttpErrorResponse) => {
				const errorMessage = this.getErrorMessage(err, true, 'There is a problem with at least one request. Try again');
				if (showNotification) {
					this._notificationService.showError(errorMessage, checkDuplicates ? checkDuplicates : true, duration ? duration : 6000);
				}
				return of(Result.fail<T>(errorMessage));
			})
		);
	}

	private getErrorMessage(error: HttpErrorResponse, showErrorIdMessage: boolean = true, fallbackErrorMessage?: string): string {
		const errorResponse: ErrorResponse = error.error as ErrorResponse;

		if (typeof errorResponse !== 'undefined' && errorResponse !== null) {
			const message: string = errorResponse.message;
			return showErrorIdMessage && !StringUtils.isNullOrWhiteSpace(errorResponse.errorId)
				? `${message}\n\nIn order to identify error send this trace ID to administrator: '${errorResponse.errorId}'.`
				: message;
		}

		const errorMessage: string = error.error as string;
		if (typeof errorMessage === 'string' && errorMessage.length > 0) {
			return errorMessage;
		}

		return fallbackErrorMessage ?? this._fallbaackErrorMessage;
	}

	private handleSuccess(results: any): void {
		const changesNumber: number = Array.isArray(results) ? results.length : 1;
		this._notificationService.showSuccess(`Successfully saved changes (${changesNumber}).`);
	}
}
