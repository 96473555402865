import { Injectable, Optional, Inject, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IHistoryLogQl } from '../models/history-log-ql.model';

export const AUDIT_API_URL = new InjectionToken<string>('AUDIT_API_URL');

@Injectable({
    providedIn: 'root'
})
export class AuditQlRepository {
    protected apiUrl: string;
    constructor(
        protected httpClient: HttpClient,
        @Inject(AUDIT_API_URL)
        apiUrl: string,
    ) {
        this.apiUrl = apiUrl;
    }

    public getChanges(tableName: string, keyValue: number): Observable<IHistoryLogQl[]> {
        const url = `/api/audit/${tableName}/${keyValue}`;
        return this.httpClient.get<IHistoryLogQl[]>(this.apiUrl + url);
    }


}
