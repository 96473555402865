export enum QueryNameGraphQLEnum {
	LocationsQuery = 'locationsQuery',
	ZonesQuery = 'zonesQuery',
	LinesQuery = 'linesQuery',
	NotificationTopicsQuery = 'notificationTopicsQuery',
	NotificationRecipientsQuery = 'notificationRecipientsQuery',
	DevicesQuery = 'devicesQuery',
	SimCardsQuery = 'simCardsQuery',
	SensorsQuery = 'sensorsQuery',
	ProductsQuery = 'productsQuery',
	SensorsGroupsQuery = 'sensorsGroupsQuery',
	ShiftsQuery = 'shiftsQuery',
	MachineStatusesQuery = 'machineStatusesQuery',
	SingleOperationStatusesQuery = 'singleOperationStatusesQuery',
	ComponentsQuery = 'componentsQuery',
	TagsQuery = 'tagsQuery',
	FeedersQuery = 'feedersQuery',
	ToolsQuery = 'toolsQuery',
	ComponentFeederQuery = 'componentFeederQuery',
	ShiftLocationsQuery = 'shiftLocationsQuery',
	FingersQuery = 'fingersQuery',
}
