import { Injectable, EventEmitter, Inject } from '@angular/core';
import { AsEnumerable, asEnumerable } from 'linq-es2015';
import { map } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { State } from '../models/state.model';
import { IMapMarkerData } from '@fitech-workspace/shared/ui/map-lib';
import { Sensor } from '../models/sensor.model';
import { BackendApiService } from './backend-api.service';
import { MachineState } from '../models/machine-state.model';
import { Machine } from '../models/machine.model';
import { SensorFilterService } from '../services/sensor-filter.service';
import { PlanMarker } from '../models/markers/plan-marker';
import { MachineMarker } from '../models/markers/machine-marker';
import { Alarm } from '../models/alarm.model';
import { IMapMarkerFactory, MAP_MARKERS_FACTORY } from '../models/markers/map-marker.factory';
declare let L;

@Injectable({
  providedIn: 'root'
})
export class PlanRepositoryService {

  private selectedItem: any;

  dataChanged: EventEmitter<{ fitMap: boolean }> = new EventEmitter();
  selectionChanged: EventEmitter<{
    item: any;
    sourceOfSelection: string;
  }> = new EventEmitter();
  alarmsAcked = new EventEmitter();

  private alarms: Alarm[] = [];
  private currentStates: State[] = [];
  private planMarkers: IMapMarkerData[] = [];
  private sensors: Sensor[] = [];
  private alarmsNotAcked: Alarm[] = [];

  constructor(
    private apiService: BackendApiService,
    @Inject(MAP_MARKERS_FACTORY) private mapMarkerFactory: IMapMarkerFactory,
    private sensorFilterService: SensorFilterService) {
  }


  selectItem(item: any, sourceOfSelection: string, emitChange: boolean = true) {
    this.selectedItem = item;
    if (emitChange) {
      this.selectionChanged.emit({
        item: item,
        sourceOfSelection: sourceOfSelection
      });
    }
  }

  onDataChange(emitChange: boolean, fitMap: boolean) {
    if (emitChange) {
      this.dataChanged.emit({ fitMap: fitMap });
    }
  }

  reloadFromApi(
    locationId: number,
    from: Date,
    to: Date,
    fitMap: boolean,
    showAlarmsDeck: boolean,
    clientIds?: number[]
  ): Observable<IMapMarkerData[]> {
    const actions = [];
    const loadCurrentStates = this.loadCurrentStatesFromApi(locationId, clientIds);
    const loadAlarmsNotAcked = this.loadAlarmsNotAckedFromApi(showAlarmsDeck ? null : locationId);
    actions.push(loadCurrentStates);
    actions.push(loadAlarmsNotAcked);
    return forkJoin(actions).pipe(
      map(res => {
        this.currentStates = this.extractStates(this.sensorFilterService.filterStates(res[0] as any));
        if (this.currentStates) {
          this.currentStates = AsEnumerable(this.currentStates)
            .ToArray();
        }

        this.currentStates.forEach(currentState => {
          if (currentState instanceof MachineState) {
            const machineState = currentState as MachineState;
            machineState.sensorsStates.forEach(sensorsState => {
              (res[1] as any).forEach(alarm => {
                if (alarm.sensorId === sensorsState.sourceId) {
                  this.alarms.push(alarm);
                }
              });
            });
          }
        });

        this.sensors = State.getSensors(this.currentStates).concat(MachineState.getSensors(this.currentStates));

        this.planMarkers = PlanMarker.getPlanMarkers(this.currentStates.map(
          state => (state.source instanceof Sensor) ? state as State : undefined));

        const machineMarkers = AsEnumerable(this.currentStates)
          .Where(state => (state.source instanceof Machine))
          .Select(state => this.mapMarkerFactory.getMarker(state, state.source.type))
          .ToArray();

        this.planMarkers = this.planMarkers.concat(machineMarkers);

        this.onDataChange(true, fitMap);
        return this.planMarkers;
      })
    );
  }

  loadAlarmsNotAckedFromApi(locationId?: number) {
    return this.apiService.GetAlarmsNotAcked(null, null, locationId).pipe(
      map(res => {
        this.alarmsNotAcked = res;
        return res;
      })
    );
  }

  loadCurrentStatesFromApi(locationId?: number, clientIds?: number[], onlyWithSamplingMs?: boolean): Observable<State[]> {
    return this.apiService.getCurrentStates(locationId, clientIds, onlyWithSamplingMs).pipe(
      map(res => {
        this.currentStates = res;
        return res;
      })
    );
  }

  loadAlarmsFromApi(
    locationId: number,
    from: Date,
    to: Date
  ): Observable<Alarm[]> {
    return this.apiService.getAlarms(from, to, null, locationId, null).pipe(
      map(res => {
        this.alarms = res;
        return res;
      })
    );
  }

  extractStates(data: State[]) {
    let tempMachineStates: MachineState[] = [];
    let tempStates: State[] = [];
    data.map(x => {
      const sensor = x.source as Sensor;
      if (sensor.machineId) {
        let machineState = tempMachineStates.find(state => state.sourceId === sensor.machineId);
        if (!machineState) {
          machineState = new MachineState(sensor);
          tempMachineStates.push(machineState);
        }

        machineState.sensorsStates.push(x);
        const lastAlarmSensor = AsEnumerable(machineState.sensorsStates).Where(state => state.alarmGuid ? true : false).OrderByDescending(state => state.timestamp).FirstOrDefault();
        machineState.alarm = lastAlarmSensor ? lastAlarmSensor.alarm : null;

        return
      }
      tempStates.push(x);
    });
    return [
      ...tempStates,
      ...tempMachineStates
    ]
  }

  getPlanMarkerById(id: number): IMapMarkerData {
    return asEnumerable(this.planMarkers)
      .Where(x => x.id === id)
      .FirstOrDefault();
  }

  getPlanMarkers(): IMapMarkerData[] {
    return this.planMarkers;
  }

  getSensorAlarms(): Alarm[] {
    return this.alarms;
  }

  getSensorStates(): State[] {
    return this.currentStates;
  }

  getSensorAlarmsNotAcked(): Alarm[] {
    return this.alarmsNotAcked;
  }

  getSelectedItem(): any {
    return this.selectItem;
  }

  ackAlarms(alarmGuids: string[], clientIds: number[] = null) {
    return this.apiService.ChangeAlarmState(clientIds, alarmGuids, 'Acknowledged').pipe(map(x => this.alarmsAcked.emit()));
  }

  setDisplayedAlarms(alarmGuids: string[], clientIds: number[] = null) {
    return this.apiService.ChangeAlarmState(clientIds, alarmGuids, 'Displayed').pipe(map(x => this.alarmsAcked.emit()));
  }

  validateAlarms(alarmGuids: string[], isReal: boolean, clientIds: number[] = null) {
    const state = isReal ? 'VerifiedAsReal' : 'VerifiedAsFalse';
    return this.apiService.ChangeAlarmState(clientIds, alarmGuids, state).pipe(map(x => this.alarmsAcked.emit()));
  }

  ackAlarmsBySensorId(sourceId: number) {
    const guids = AsEnumerable(this.alarmsNotAcked).Where(x => x.sensorId == sourceId).Select(x => x.guid).ToArray();
    return this.apiService.ChangeAlarmState(null, guids, 'Acknowledged').pipe(map(x => this.alarmsAcked.emit()));
  }
}
