export enum RolePermissionBackendEnum {
	AdminClientsWrite = 'Permissions.Backend.Admin.Clients.Write',
	AlarmsNotAckedRead = 'Permissions.Backend.Alarms.NotAcked.Read',
	AlarmsHistoryRead = 'Permissions.Backend.Alarms.History.Read',
	AlarmsHistoryThinRead = 'Permissions.Backend.Alarms.HistoryThin.Read',
	AlarmsThresholdsRead = 'Permissions.Backend.Alarms.Thresholds.Read',
	AlarmsWrite = 'Permissions.Backend.Alarms.Write',
	AlgorithmsRead = 'Permissions.Backend.Algorithms.Read',
	AlgorithmsWrite = 'Permissions.Backend.Algorithms.Write',
	AlgorithmsStagesRead = 'Permissions.Backend.Algorithms.Stages.Read',
	AlgorithmsStagesWrite = 'Permissions.Backend.Algorithms.Stages.Write',
	AlgorithmsActionsRead = 'Permissions.Backend.Algorithms.Actions.Read',
	AlgorithmsActionsWrite = 'Permissions.Backend.Algorithms.Actions.Write',
	HeartbeatsRead = 'Permissions.Backend.Heartbeats.Read',
	KpiGanttStatesRead = 'Permissions.Backend.Kpi.GanttStates.Read',
	KpiProductionCountersRead = 'Permissions.Backend.Kpi.ProductionCounters.Read',
	KpiTimeWorkingRead = 'Permissions.Backend.Kpi.TimeWorking.Read',
	KpiPcbaBuildTimeRead = 'Permissions.Backend.Kpi.PcbaBuildTime.Read',
	KpiOeeStatisticsRead = 'Permissions.Backend.Kpi.OeeStatistics.Read',
	KpiTactTimeRead = 'Permissions.Backend.Kpi.TactTime.Read',
	MasterDataDevicesRead = 'Permissions.Backend.MasterData.Devices.Read',
	MasterDataDevicesWrite = 'Permissions.Backend.MasterData.Devices.Write',
	MasterDataSensorsGroupsRead = 'Permissions.Backend.MasterData.SensorsGroups.Read',
	MasterDataZonesRead = 'Permissions.Backend.MasterData.Zones.Read',
	MasterDataLinesRead = 'Permissions.Backend.MasterData.Lines.Read',
	MasterDataToolsRead = 'Permissions.Backend.MasterData.Tools.Read',
	MasterDataItemsRead = 'Permissions.Backend.MasterData.Items.Read',
	MasterDataPointStatusesRead = 'Permissions.Backend.MasterData.PointStatuses.Read',
	MasterDataDesignatorsRead = 'Permissions.Backend.MasterData.Designators.Read',
	MasterDataSensorsRead = 'Permissions.Backend.MasterData.Sensors.Read',
	MasterDataComponentsRead = 'Permissions.Backend.MasterData.Components.Read',
	MeasurementsCurrentRead = 'Permissions.Backend.Measurements.Current.Read',
	MeasurementCurrentTechnicianRead = 'Permissions.Backend.Measurements.Current.Technician.Read',
	MeasurementsCurrentBySourcePropertiesRead = 'Permissions.Backend.Measurements.CurrentBySourceProperties.Read',
	MeasurementsCurrentBySourcePropertiesTransposedRead = 'Permissions.Backend.Measurements.CurrentBySourcePropertiesTransposed.Read',
	MeasurementsHistorySummedRead = 'Permissions.Backend.Measurements.HistorySummed.Read',
	MeasurementsHistorySummedBySourcePropertiesRead = 'Permissions.Backend.Measurements.HistorySummedBySourceProperties.Read',
	MeasurementsHistoryAggregatedRead = 'Permissions.Backend.Measurements.HistoryAggregated.Read',
	MeasurementsHistoryAuditRead = 'Permissions.Backend.Measurements.HistoryAudit.Read',
	MeasurementsKpiSensorsRead = 'Permissions.Backend.Measurements.KpiSensors.Read',
	RecipesRead = 'Permissions.Backend.Recipes.Read',
	RecipesWrite = 'Permissions.Backend.Recipes.Write',
	RecipesStatusWrite = 'Permissions.Backend.Recipes.Status.Write',
	SoftwarePackagesRead = 'Permissions.Backend.SoftwarePackages.Read',
	SoftwarePackagesWrite = 'Permissions.Backend.SoftwarePackages.Write',
	SoftwarePackagesSend = 'Permissions.Backend.SoftwarePackages.Send',
	SoftwarePackagesSubpackagesRead = 'Permissions.Backend.SoftwarePackages.Subpackages.Read',
	ThtLogsRead = 'Permissions.Backend.ThtLogs.Read',
	ThtLogsShiftProbeStatsRead = 'Permissions.Backend.ThtLogs.ShiftProbeStats.Read',
	ThtLogsFileTccRead = 'Permissions.Backend.ThtLogs.FileTcc.Read',
	ThtLogsItemsStatisticsRead = 'Permissions.Backend.ThtLogs.ItemsStatistics.Read',
	ThtLogsPlacementSpeedRead = 'Permissions.Backend.ThtLogs.PlacementSpeed.Read',
	VersionsRead = 'Permissions.Backend.Versions.Read',
	AuditRead = 'Permissions.Backend.Audit.Read',
	ClientsRead = 'Permissions.Backend.Clients.Read',
	ClientsWrite = 'Permissions.Backend.Clients.Write',
	RolesRead = 'Permissions.Backend.Roles.Read',
	RolesWrite = 'Permissions.Backend.Roles.Write',
	RolesPermissionsRead = 'Permissions.Backend.RolesPermissions.Read',
	UsersRead = 'Permissions.Backend.Users.Read',
	UsersSelfRead = 'Permissions.Backend.Users.Self.Read',
	UsersWrite = 'Permissions.Backend.Users.Write',
	UsersLockoutWrite = 'Permissions.Backend.Users.Lockout.Write',
	UsersResetPasswordWrite = 'Permissions.Backend.Users.ResetPassword.Write',
	UsersConfirmEmailWrite = 'Permissions.Backend.Users.ConfirmEmail.Write',
	GraphQLZonesRead = 'Permissions.Backend.GraphQL.Zones.Read',
	GraphQLZonesWrite = 'Permissions.Backend.GraphQL.Zones.Write',
	GraphQLLinesRead = 'Permissions.Backend.GraphQL.Lines.Read',
	GraphQLLinesWrite = 'Permissions.Backend.GraphQL.Lines.Write',
	GraphQLNotificationTopicsRead = 'Permissions.Backend.GraphQL.NotificationTopics.Read',
	GraphQLNotificationTopicsWrite = 'Permissions.Backend.GraphQL.NotificationTopics.Write',
	GraphQLNotificationRecipientsRead = 'Permissions.Backend.GraphQL.NotificationRecipients.Read',
	GraphQLNotificationRecipientsWrite = 'Permissions.Backend.GraphQL.NotificationRecipients.Write',
	GraphQLLocationsRead = 'Permissions.Backend.GraphQL.Locations.Read',
	GraphQLLocationsWrite = 'Permissions.Backend.GraphQL.Locations.Write',
	GraphQLDevicesRead = 'Permissions.Backend.GraphQL.Devices.Read',
	GraphQLDevicesWrite = 'Permissions.Backend.GraphQL.Devices.Write',
	GraphQLSimCardsRead = 'Permissions.Backend.GraphQL.SimCards.Read',
	GraphQLSimCardsWrite = 'Permissions.Backend.GraphQL.SimCards.Write',
	GraphQLSensorsRead = 'Permissions.Backend.GraphQL.Sensors.Read',
	GraphQLSensorsWrite = 'Permissions.Backend.GraphQL.Sensors.Write',
	GraphQLProductsRead = 'Permissions.Backend.GraphQL.Products.Read',
	GraphQLProductsWrite = 'Permissions.Backend.GraphQL.Products.Write',
	GraphQLSensorsGroupsRead = 'Permissions.Backend.GraphQL.SensorsGroups.Read',
	GraphQLSensorsGroupsWrite = 'Permissions.Backend.GraphQL.SensorsGroups.Write',
	GraphQLShiftsRead = 'Permissions.Backend.GraphQL.Shifts.Read',
	GraphQLShiftsWrite = 'Permissions.Backend.GraphQL.Shifts.Write',
	GraphQLMachineStatusesRead = 'Permissions.Backend.GraphQL.MachineStatuses.Read',
	GraphQLMachineStatusesWrite = 'Permissions.Backend.GraphQL.MachineStatuses.Write',
	GraphQLSingleOperationStatusesRead = 'Permissions.Backend.GraphQL.SingleOperationStatuses.Read',
	GraphQLSingleOperationStatusesWrite = 'Permissions.Backend.GraphQL.SingleOperationStatuses.Write',
	GraphQLComponentsRead = 'Permissions.Backend.GraphQL.Components.Read',
	GraphQLComponentsWrite = 'Permissions.Backend.GraphQL.Components.Write',
	GraphQLTagsRead = 'Permissions.Backend.GraphQL.Tags.Read',
	GraphQLTagsWrite = 'Permissions.Backend.GraphQL.Tags.Write',
	GraphQLFeedersRead = 'Permissions.Backend.GraphQL.Feeders.Read',
	GraphQLFeedersWrite = 'Permissions.Backend.GraphQL.Feeders.Write',
	GraphQLToolsRead = 'Permissions.Backend.GraphQL.Tools.Read',
	GraphQLToolsWrite = 'Permissions.Backend.GraphQL.Tools.Write',
	GraphQLComponentFeederRead = 'Permissions.Backend.GraphQL.ComponentFeeder.Read',
	GraphQLComponentFeederWrite = 'Permissions.Backend.GraphQL.ComponentFeeder.Write',
	GraphQLShiftLocationsRead = 'Permissions.Backend.GraphQL.ShiftLocations.Read',
	GraphQLShiftLocationsWrite = 'Permissions.Backend.GraphQL.ShiftLocations.Write',
	GraphQLFingersRead = 'Permissions.Backend.GraphQL.Fingers.Read',
	GraphQLFingersWrite = 'Permissions.Backend.GraphQL.Fingers.Write',
	UserSettingsRead = 'Permissions.Backend.UserSettings.Read',
	UserSettingsWrite = 'Permissions.Backend.UserSettings.Write',
}
