import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionsMap } from '../models';

export abstract class PermissionsMapService {
	isInitialized = false;

	protected abstract readonly map: PermissionsMap;

	private _checkedPermissionsMap: PermissionsMap = new Map<string, string[]>();
	private _uniqueBackendPermissions: string[] = [];
	private _assignedBackendPermissionsSubject = new BehaviorSubject<string[]>([]);

	get assignedBackendPermissions$(): Observable<string[]> {
		return this._assignedBackendPermissionsSubject.asObservable();
	}

	checkFrontendPermissions(keys: string[], updateBackendPermissions: boolean = true): void {
		keys.forEach((key: string) => this.checkFrontendPermission(key));
		if (!updateBackendPermissions) {
			return;
		}
		this.updateBackendPermissions();
	}

	uncheckFrontendPermissions(keys: string[], updateBackendPermissions: boolean = true): void {
		keys.forEach((key: string) => this.uncheckFrontendPermission(key));
		if (!updateBackendPermissions) {
			return;
		}
		this.updateBackendPermissions();
	}

	clearChecked(): void {
		this._checkedPermissionsMap.clear();
		this.isInitialized = false;
		this._uniqueBackendPermissions = [];
		this._assignedBackendPermissionsSubject.next(this._uniqueBackendPermissions);
	}

	setIsInitialized(): void {
		this.isInitialized = true;
	}

	private checkFrontendPermission(key: string): void {
		const values = this.map.get(key);
		if (!values) {
			return;
		}

		this._checkedPermissionsMap.set(key, [...values]);
	}

	private uncheckFrontendPermission(key: string): void {
		this._checkedPermissionsMap.delete(key);
	}

	private updateBackendPermissions(): void {
		const set = new Set<string>();
		this._checkedPermissionsMap.forEach((values: string[]) => {
			values.forEach((value: string) => set.add(value));
		});
		this.isInitialized = true;
		this._uniqueBackendPermissions = Array.from(set);
		this._assignedBackendPermissionsSubject.next(this._uniqueBackendPermissions);
	}
}
