import { Component, OnInit } from '@angular/core';
import { AsEnumerable } from 'linq-es2015';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'fitech-workspace-chips-with-icon-renderer',
  templateUrl: './chips-with-icon-renderer.component.html',
  styleUrls: ['./chips-with-icon-renderer.component.scss']
})

export class ChipsWithIconRendererComponent implements ICellRendererAngularComp {
  public params: any;
  data: IChipsWithIconData[] = [];
  private _goToPageOnClick: boolean;

  constructor(private router: Router) {

  }

  refresh(params: any): boolean {
    return true;
  }

  agInit(params: any): void {
    let data = params.data.getChipsWithIcon(params.colDef.field, params.cssClass);

    if (params.sortBy) {
      data = AsEnumerable(data).OrderBy(x => x[params.sortBy]).ToArray();
    }
    if (params.sortBy && params.sortThanBy) {
      data = AsEnumerable(data).OrderBy(x => x[params.sortBy]).ThenBy(x => x[params.sortThanBy]).ToArray();
    }

    this.data = data;
  }

  afterGuiAttached?(params?: any): void {
    this.params = params;
  }

  onClick(item: IChipsWithIconData) {
    if (item.onClickUrl)
      this.goToPageWithFilter(item.onClickUrl.url, item.onClickUrl.gridFilter);
  }

  goToPageWithFilter(routeName: string, gridFilter?: string) {
    let params = undefined;
    if (gridFilter) {
      params = { queryParams: { gridFilter: gridFilter } }
    }
    this.router.navigate([routeName], params);
  }

}
export interface IChipsWithIconData {
  cssClass: string;
  value: string;
  icon: string;
  tooltip: string;
  id: number;
  onClickUrl?: { url: string, gridFilter: string };
}
export interface IChipsWithIconDataProvider {
  getChipsWithIcon(field: string, cssClass: string, icon: string): IChipsWithIconData[];
}