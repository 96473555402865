import { AsEnumerable } from 'linq-es2015';
import { IGpsPosition, IMapMarkerData, IMapMarkerDataV2 } from '@fitech-workspace/shared/ui/map-lib';
import * as moment from 'moment';
import { MachineState } from '../machine-state.model';
import { Sensor } from '../sensor.model';
import { State } from '../state.model';
import { MachineIconFactory } from './machine-icon.factory';
import { AlarmLifetimeThresholdEnum } from '../alarm-lifetime-threshold.enum';

export class MachineMarker implements IMapMarkerData, IMapMarkerDataV2 {

  id: any;
  iconClass: string;
  iconHtml: string;
  stateColor: { state: string, color: string, priority: number };
  gps: IGpsPosition;
  lastTimestamp: Date;

  extraData?: {
    machineName?: string;
    machine: MachineState;
  };

  constructor(machine?: MachineState) {
    if (machine) {
      for (const property in machine) {
        if (machine.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>machine)[property];
        }
      }
      this.id = machine.sourceId;
      this.lastTimestamp = machine['timestamp'];

      this.extraData = { machineName: machine.source.name, machine: machine };
      this.setState(machine);
      const iconData = new MachineIconFactory().getIcon(this);

      this.iconHtml = iconData.iconHtml;
      this.iconClass = iconData.iconClass;
      this.gps = {
        lat: machine.source.positionX,
        lng: machine.source.positionY
      };
    }
  }


  get hasAsyncCommunication(): boolean {
    if (this.extraData && this.extraData.machine && this.extraData.machine.defaultSensorState
      && this.extraData.machine.defaultSensorState.source)
      return this.extraData.machine.defaultSensorState.source.type.endsWith('digi')
    return null;
  }

  static getMachineMarkers(data: MachineState[]): MachineMarker[] {
    const markers: MachineMarker[] = [];
    if (data && data.length > 0) {
      data.forEach(x => {
        markers.push(new MachineMarker(x));
      });
    }
    return markers;
  }
  static generateTooltipText(marker: IMapMarkerData) {
    return `<div class="marker-tooltip-text">
    ${marker.extraData.machineName}
   </div>`;

  }

  generateTooltipText() {
    return MachineMarker.generateTooltipText(this);
  }

  openDialog(dialog) {
    throw new Error("Not implemented");
  }

  setState(machine: MachineState) {
    if (moment(this.lastTimestamp) < moment().add(-6, 'hours') && !this.hasAsyncCommunication) {
      this.stateColor = Sensor.getSensorStateColor('inactive');
    } else if (machine.alarmIsLive) {
      this.stateColor = Sensor.getSensorStateColor(('alarm-' + machine.alarm.type).toLowerCase());
    } else {
      this.stateColor = Sensor.getSensorStateColor('active');
    }
  }
}
