import { Component, OnInit, Input, Inject } from '@angular/core';
import { UserService, AUTH_ENV_CONFIG } from '../../services/user.service';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService, LanguageService } from '@fitech-workspace/core-lib';
import { Router } from '@angular/router';
import { ApiService, ForgotPasswordReq } from '@fitech-workspace/shared/data-access/master-data-api-lib';

@Component({
  selector: 'fitech-workspace-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss']
})
export class PasswordForgotComponent implements OnInit {
  @Input() logoSrc: string;
  private environmentConfig;
  returnUrl: any;
  $counter: any;
  secondsToRedirect: number;
  isInProgress: boolean;
  form: UntypedFormGroup;
  email = new UntypedFormControl();
  emailInput: string;
  passwordInput: string;
  isSubmitting = false;
  isSubmitted = false;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder,
    private apiClient: ApiService,
    private userService: UserService,
    private languageService: LanguageService,
    @Inject(AUTH_ENV_CONFIG) environmentConfig: any) {
    this.environmentConfig = environmentConfig;
    if (this.environmentConfig) {
      this.logoSrc = this.environmentConfig.logoSrc;
    }
  }

  ngOnInit() {
    this.createFormGroups(this.formBuilder);
    this.onChanges();
  }

  createFormGroups(fb: UntypedFormBuilder) {
    this.form = fb.group({
      email: this.email
    });

    this.email.setValidators([Validators.required, Validators.email]);
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(val => {
      if (val.email) {
        this.isSubmitted = false;
      }
    });
  }

  shouldDisableAction() {
    return this.isSubmitting || this.isSubmitted || this.email.invalid;
  }

  forgotPassword(): void {
    this.isSubmitting = true;
    this.apiClient.forgotPassword(this.createForgotPasswordReq())
      .subscribe(
        res => {
          this.isSubmitting = false;
          this.isSubmitted = true;
          this.notificationService.showSuccess('Email was sent');
        },
        err => {
          this.isSubmitting = false;
          this.notificationService.showInfo('Email was not sent');
        }
      );
  }

  createForgotPasswordReq(): ForgotPasswordReq {
    const forgotPasswordReq: ForgotPasswordReq = {
      email: this.email.value,
      context: encodeURIComponent(this.userService.getAppContext()),
      languageCode: this.languageService.currentLanguage || null,
      baseUrl: location.origin,
    };
    return forgotPasswordReq;
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.forgotPassword();
    }
  }
  isValid(key) {
    return this.form.controls[key].valid;
  }
  isTouched(key) {
    return this.form.controls[key].touched;
  }
  getErrorMsg(key) {
    let errorMsg = '';
    if (this.isTouched(key)) {
      if (this.form.controls[key].hasError('required')) {
        errorMsg += 'Field is required. ';
      }
      if (this.form.controls[key].hasError('email')) {
        errorMsg += 'Field does not meet e-mail pattern requirement. ';
      }
    }
    return errorMsg;
  }
}

