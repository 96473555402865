import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
	CellClickedEvent,
	CellDoubleClickedEvent,
	CellValueChangedEvent,
	ColDef,
	Column,
	ColumnApi,
	GetQuickFilterTextParams,
	GetRowIdFunc,
	GridApi,
	GridOptions,
	GridReadyEvent,
	GridSizeChangedEvent,
	IDatasource,
	IRowNode,
	ModelUpdatedEvent,
	Module,
	ModuleRegistry,
	RowDragEndEvent,
	RowNode,
} from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { ActivatedRoute } from '@angular/router';
import {
	ScreenSizeService,
	UserSettingDto,
	UserSettingParameterValue,
	UserSettingsFacadeBaseService,
	UserSettingsFactoryService,
	UserSettingsHelper,
	UserSettingsLocationDto,
} from '@fitech-workspace/core-lib';
import { Observable, Subscription, of } from 'rxjs';
import { delay, filter, take, tap } from 'rxjs/operators';
import { AutocompleteRendererComponent } from './../grid-renderers/autocomplete-renderer/autocomplete-renderer.component';
import { CustomHeaderComponent } from '../custom-header/custom-header.component';
import { CustomTooltip } from '../custom-tooltip/custom-tooltip.component';
import { RowModelTypeEnum } from '../enums';
import { GridColumnsUserSettingEnum } from '../enums/grid-columns-user-setting.enum';
import { AvatarRendererComponent } from '../grid-renderers/avatar-renderer/avatar-renderer.component';
import { ButtonRendererComponent } from '../grid-renderers/button-renderer/button-renderer.component';
import { CheckboxRendererComponent } from '../grid-renderers/checkbox-renderer/checkbox-renderer.component';
import { ChipsRendererComponent } from '../grid-renderers/chips-renderer/chips-renderer.component';
import { ChipsWithIconRendererComponent } from '../grid-renderers/chips-with-icon-renderer/chips-with-icon-renderer.component';
import { DragButtonRendererComponent } from '../grid-renderers/drag-button-renderer/drag-button-renderer.component';
import { LabelledButtonRendererComponent } from '../grid-renderers/labelled-button-renderer/labelled-button-renderer.component';
import { LabelledChipsRendererComponent } from '../grid-renderers/labelled-chips-renderer/labelled-chips-renderer.component';
import { LinkRendererComponent } from '../grid-renderers/link-renderer/link-renderer.component';
import { NumericEditorComponent } from '../grid-renderers/numeric-editor/numeric-editor.component';
import { PinnedCellRendererComponent } from '../grid-renderers/pinned-cell-renderer/pinned-cell-renderer.component';
import { SimpleAvatarRendererComponent } from '../grid-renderers/simple-avatar-renderer/simple-avatar-renderer.component';
import { SimpleChipsWithIconRendererComponent } from '../grid-renderers/simple-chips-with-icon-renderer/simple-chips-with-icon-renderer.component';
import { TextRendererComponent } from '../grid-renderers/text-renderer/text-renderer.component';
import { GridSharedMessage } from '../grid-shared-message';
import { GridSharedService } from '../grid-shared.service';
import { GridPinnedBottomRowConfig, InfiniteScrollProperties } from '../models';
import { GridColumn } from '../models/grid-column.type';

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule, InfiniteRowModelModule]);

@Component({
	selector: 'fitech-workspace-grid',
	templateUrl: './grid.component.html',
	styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	@Input() rows: any[];
	@Input() columns: GridColumn[];
	@Input() isLoadingResults: boolean;
	@Input() gridSizeMargin: string;
	@Input() selectedActionRows: any[] = [];
	@Input() actionButtons = [];
	@Input() gridId: string;
	@Input() pagination = true;
	@Input() pageSize = 100;
	@Input() rowHeight = 50;
	@Input() autoSizeColumns = false;
	@Input() rowSelection = 'multiple';
	@Input() enableCellTextSelection = false;
	@Input() showToolPanel = false;
	@Input() refreshColumnsOnModelUpdate = false;
	@Input() isPermissionRead = true;
	@Input() storeUserSettings = true;
	@Input() sectionLocationName = '';
	@Input() rowBuffer = 10;
	@Input() suppressColumnVirtualisation = false;
	@Input() suppressRowVirtualisation = false;
	@Input() debounceVerticalScrollbar = false;
	@Input() rowModelType: RowModelTypeEnum = RowModelTypeEnum.ClientSide;
	@Input() infiniteScrollProperties: InfiniteScrollProperties;
	@Input() pinnedBottomRowConfig: GridPinnedBottomRowConfig;

	@Output() cellClicked = new EventEmitter<CellClickedEvent>();
	@Output() cellDoubleClicked = new EventEmitter<CellDoubleClickedEvent>();
	@Output() rowSelected = new EventEmitter<any[]>();
	@Output() rowDataChanged = new EventEmitter<any>();
	@Output() filterChanged = new EventEmitter<any>();
	@Output() cellValueChanged = new EventEmitter<any>();
	@Output() rowDragEnd = new EventEmitter<any>();

	@ViewChild('gridContainer') private _gridContainer: ElementRef;
	@ViewChild('selectionListContainer') private _selectionListContainer: ElementRef;

	defaultColDef: any;
	gridApi: GridApi;
	gridColumnApi: ColumnApi;
	context: any;
	frameworkComponents: any;
	paginationPageSize: any;
	paginationNumberFormatter: any;
	selectedRows: any[] = [];
	onGetNotificationsSubs: any;
	gridOptions: GridOptions;
	getRowId: GetRowIdFunc;

	isSidebarEnabled = false;
	togglingColumns: GridColumn[];
	visibleColumnNames: string[];

	pinnedBottomRowData: any[];

	modules: Module[] = [];

	isPrepared = false;

	// infinite scroll
	cacheBlockSize: number;
	maxBlocksInCache: number;
	cacheOverflowSize: number;
	maxConcurrentDatasourceRequests: number;
	infiniteInitialRowCount: number;
	blockLoadDebounceMillis: number;

	// user settings
	private _viewLocationName: string;
	private readonly _elementLocationName = 'grid';
	private readonly _columnsTag = 'columns';
	private _userSettings$: Observable<UserSettingDto[]>;
	private _userSettings: UserSettingDto[];

	private readonly _compensationHeight = 50;
	private _gridOffsetHeight: number;

	private _onResizeSub: Subscription;
	private _subscriptions = new Subscription();
	private _pendingFilterValue: string;

	private get _userSettingsLocation(): UserSettingsLocationDto {
		return this._userSettingsFactoryService.createUserSettingsLocation(this._viewLocationName, this.sectionLocationName, this._elementLocationName);
	}

	constructor(
		public sharedService: GridSharedService,
		private _screenSizeService: ScreenSizeService,
		private _userSettingsFacadeService: UserSettingsFacadeBaseService,
		private _userSettingsFactoryService: UserSettingsFactoryService,
		private _route: ActivatedRoute
	) {
		this.defaultColDef = {
			sortable: true,
			filter: true,
			resizable: true,
			headerComponent: <new () => CustomHeaderComponent>CustomHeaderComponent,
			headerComponentParams: {
				menuIcon: 'fa-bars',
			},
		};
		this.context = { componentParent: this };
		this.frameworkComponents = {
			chipsRenderer: ChipsRendererComponent,
			chipsWithIconRenderer: ChipsWithIconRendererComponent,
			simpleChipsWithIconRenderer: SimpleChipsWithIconRendererComponent,
			avatarRenderer: AvatarRendererComponent,
			simpleAvatarRenderer: SimpleAvatarRendererComponent,
			buttonRenderer: ButtonRendererComponent,
			labelledChipsRenderer: LabelledChipsRendererComponent,
			labelledButtonRenderer: LabelledButtonRendererComponent,
			linkRenderer: LinkRendererComponent,
			textRenderer: TextRendererComponent,
			dragRenderer: DragButtonRendererComponent,
			numericEditor: NumericEditorComponent,
			checkboxRenderer: CheckboxRendererComponent,
			customTooltip: CustomTooltip,
			autocompleteRenderer: AutocompleteRendererComponent,
			pinnedCellRenderer: PinnedCellRendererComponent,
		};
		this.paginationNumberFormatter = function (params: any): string {
			return '[' + params.value.toLocaleString() + ']';
		};
		this.onGetNotificationsSubs = this.sharedService.getNotifications().subscribe((notification: GridSharedMessage) => {
			if (notification.gridId === this.gridId) {
				if (notification.type === 'filter') {
					this.applyFilter(notification.data.filterValue);
				}
				if (notification.type === 'exportToCsv') {
					this.exportToCsv();
				}
			}
		});
	}

	ngOnInit(): void {
		this.paginationPageSize = this.pageSize;
		this._viewLocationName = this.getRouteConfigPath();
		this.sectionLocationName = this.sectionLocationName ? this.sectionLocationName : this.gridId ?? '';
		this._userSettings$ = this.storeUserSettings
			? this._userSettingsFacadeService.localUserSettings$(this._viewLocationName, this.sectionLocationName, this._elementLocationName)
			: of([]);
		this.isPrepared = !this.storeUserSettings;

		if (this.showToolPanel) {
			this.togglingColumns = this.columns.filter((x: ColDef) => !!x.headerName);
			this.visibleColumnNames = this.columns.map((x: ColDef) => x.headerName);
		}

		this._subscriptions.add(
			this._userSettings$.subscribe((userSettings: UserSettingDto[]): void => {
				this.resolveUserSettings(userSettings);
			})
		);

		if (this.rowModelType === 'infinite') {
			this.setInfiniteScroll(this.infiniteScrollProperties);
		}
	}

	ngAfterViewInit(): void {
		this._gridOffsetHeight = this._gridContainer.nativeElement.offsetHeight;

		this._onResizeSub = this._screenSizeService.onResize$
			.pipe(
				filter(() => this.isSidebarEnabled && this._selectionListContainer?.nativeElement),
				delay(500)
			)
			.subscribe(() => {
				this._gridOffsetHeight = this._gridContainer.nativeElement.offsetHeight;
				this._selectionListContainer.nativeElement.style.maxHeight = `${this._gridOffsetHeight - this._compensationHeight}px`;
			});
	}

	ngOnDestroy(): void {
		this.onGetNotificationsSubs?.unsubscribe();
		this._subscriptions.unsubscribe();
		this._onResizeSub?.unsubscribe();
	}

	ngOnChanges(changes: { [propKey: string]: SimpleChange }): void {
		for (const propName in changes) {
			if (propName === 'rows') {
				this.refreshGrid();
			}
			if (propName === 'columns') {
				this.updateColumnsBasedOnUserSettings();
				this.setColumns();
			}
		}
	}

	onModelUpdated(event: ModelUpdatedEvent): void {
		if (!event.newData) {
			return;
		}

		this.fitGridSizeColumns();

		if (this.autoSizeColumns) {
			this.autoSizeAll();
		}

		if (this.refreshColumnsOnModelUpdate) {
			this.updateColumnsBasedOnUserSettings();
			this.setColumns();
		}
	}

	onPageSizeChanged(): void {
		this.gridApi.paginationSetPageSize(this.pageSize);
	}

	onGridReady(params: GridReadyEvent): void {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.gridOptions = this.gridApi['gridOptionsService']?.gridOptions;
		this.gridOptions.rowHeight = this.rowHeight;
		this.gridOptions.tooltipShowDelay = 100;

		if (!this.isPermissionRead && !this.rows?.length) {
			this.gridOptions.overlayNoRowsTemplate = 'The user does not have permission to read data.';
			this.gridApi.showNoRowsOverlay();
		}

		if (this._pendingFilterValue) {
			this.applyFilter(this._pendingFilterValue);
		}

		if (this.autoSizeColumns) {
			this.autoSizeAll();
		}

		this.refreshGrid();
		this.updateColumnFilter();
	}

	hideOverlay(): void {
		this.gridApi.hideOverlay();
	}

	showNoRowsOverlay(): void {
		this.gridOptions.overlayNoRowsTemplate = 'No results';
		this.gridApi.showNoRowsOverlay();
	}

	onGridSizeChanged(params: GridSizeChangedEvent): void {
		params.api.sizeColumnsToFit();
	}

	onCellClicked(event: CellClickedEvent): void {
		this.cellClicked.emit(event);
	}

	onCellDoubleClicked(event: CellDoubleClickedEvent): void {
		this.cellDoubleClicked.emit(event);
	}

	onRowDragEnd(event: RowDragEndEvent): void {
		this.rowDragEnd.emit(event);
	}

	onSelectionChanged(): void {
		this.rowSelected.emit(this.gridApi.getSelectedRows());
	}

	onFilterChanged(): void {
		this.filterChanged.emit();
		this.updateFilterModel();
	}

	onCellValueChanged(event: CellValueChangedEvent): void {
		this.cellValueChanged.emit(event);
	}

	onRowDataChanged(): void {
		this.setSelection();
		this.rowDataChanged.emit();
	}

	onColumnVisibleChange(event: MatSelectionListChange): void {
		const option = event.options[0];

		const cols = this.getAllGridColumns();

		const column = cols.find((col: Column) => col.getColDef().headerName === option.value);

		this.gridColumnApi.setColumnVisible(column, option.selected);

		this.storeColumnsVisibilitySetting([column.getColDef().headerName], option.selected);
	}

	selectAllFilterColumns(): void {
		if (this.gridColumnApi.getAllDisplayedColumns().length === this.columns.length) {
			return;
		}
		const hiddenColumns: ColDef[] = this.getAllHiddenColDefs();

		const cols = this.getAllGridColumns();

		this.gridColumnApi.setColumnsVisible(cols, true);

		this.visibleColumnNames = this.columns.map((x: ColDef) => x.headerName);
		this.storeColumnsVisibilitySetting(this.getHeaderNames(hiddenColumns), true);
	}

	clearAllFilterColumns(): void {
		if (this.visibleColumnNames.length === this.getPinnedColumnsNames().length) {
			return;
		}

		const cols = this.getAllGridColumns();

		const filteredCols = cols.filter((x: Column) => !x.getColDef().pinned);

		this.gridColumnApi.setColumnsVisible(filteredCols, false);

		const visibleNotPinnedColumns: ColDef[] = this.getAllVisibleColDefs(false);

		this.visibleColumnNames = this.getPinnedColumnsNames();
		this.storeColumnsVisibilitySetting(this.getHeaderNames(visibleNotPinnedColumns), false);
	}

	toggleSidebar(): void {
		this.isSidebarEnabled = !this.isSidebarEnabled;

		this.setColumns();

		if (!this.isSidebarEnabled) {
			this._userSettingsFacadeService.checkBatchedUserSettings();
		}

		of(true)
			.pipe(
				take(1),
				delay(100),
				tap(() => {
					if (this._selectionListContainer) {
						this._selectionListContainer.nativeElement.style.maxHeight = `${this._gridOffsetHeight - this._compensationHeight}px`;
					}
				})
			)
			.subscribe();
	}

	applyFilter(filterValue: string): void {
		if (this.gridApi) {
			this.gridApi.setQuickFilter(filterValue.replace(/ /g, '.'));
			this._pendingFilterValue = null;
		} else {
			this._pendingFilterValue = filterValue;
		}
	}

	setColumns(): void {
		this.fixFilteringWhenSpaceInValue();

		if (this.gridApi && this.gridColumnApi && this.columns) {
			this.gridApi.setColumnDefs(this.columns);
		}
	}

	resetFilters(): void {
		this.gridApi.setFilterModel(null);
	}

	refreshCells(): void {
		this.gridApi?.refreshCells();
	}

	refreshGrid(): void {
		this.fitGridSizeColumns();
		this.refreshPinnedBottomRow();
	}

	refreshPinnedBottomRow(): void {
		const isPinnedBottomRow: boolean =
			typeof this.pinnedBottomRowConfig?.getPinnedBottomRow !== 'undefined' && this.pinnedBottomRowConfig?.getPinnedBottomRow !== null;
		const isAnyRow: boolean = this.rows?.length > 0;
		if (!isPinnedBottomRow || !isAnyRow) {
			this.pinnedBottomRowData = null;
			return;
		}

		this.pinnedBottomRowData = [this.pinnedBottomRowConfig.getPinnedBottomRow(this.rows)];
		this.gridApi?.setPinnedBottomRowData(this.pinnedBottomRowData);
	}

	selectRows(rows: any[]): void {
		this.selectedActionRows = rows;
		this.setSelection();
	}

	sizeToFit(): void {
		this.gridApi.sizeColumnsToFit();
	}

	setInfiniteScroll(properties?: InfiniteScrollProperties): void {
		this.rowModelType = RowModelTypeEnum.Infinite;
		this.cacheBlockSize = properties?.cacheBlockSize ?? 100;
		this.maxBlocksInCache = properties?.maxBlocksInCache ?? 10;
		this.cacheOverflowSize = properties?.cacheOverflowSize ?? 1;
		this.maxConcurrentDatasourceRequests = properties?.maxConcurrentDatasourceRequests ?? 2;
		this.infiniteInitialRowCount = properties?.infiniteInitialRowCount ?? 1;
		this.blockLoadDebounceMillis = properties?.blockLoadDebounceMillis ?? null;
	}

	reloadInfiniteScroll(dataSource: IDatasource): void {
		this.gridApi.setDatasource(dataSource);
	}

	hideInfiniteScrollSpinner(startRow: number, endRow: number): void {
		const rowNodes: IRowNode<any>[] = [];
		this.gridApi.forEachNode((rowNode: IRowNode<any>) => {
			const index: number = rowNode.rowIndex;
			if (typeof rowNode.data === 'undefined' && index >= startRow && index <= endRow) {
				rowNode.failedLoad = true;
				rowNodes.push(rowNode);
			}
		});
		if (rowNodes.length > 0) {
			this.gridApi.redrawRows({ rowNodes: rowNodes });
		}
	}

	getRowsData(): any[] {
		const results: any[] = [];
		this.gridApi.forEachNode((rowNode: RowNode) => results.push(rowNode.data));
		return results;
	}

	getFilteredRowsData(): any[] {
		const results: any[] = [];
		this.gridApi.forEachNodeAfterFilterAndSort((rowNode: RowNode) => results.push(rowNode.data));
		return results;
	}

	toggleLoadingOverlay(showOverlay: boolean): void {
		if (showOverlay) {
			this.gridApi?.showLoadingOverlay();
		} else {
			if (this.gridOptions?.loadingOverlayComponent) {
				this.gridApi?.hideOverlay();
			}
		}
	}

	exportToCsv(fileName?: string, columnSeparator?: string): void {
		this.gridApi.exportDataAsCsv({ fileName: fileName ?? 'export', columnSeparator: columnSeparator ?? ';' });
	}

	trackByHeaderName(index: number, column: GridColumn): string {
		return column.headerName;
	}

	setGetRowId(getRowId: GetRowIdFunc): void {
		this.getRowId = getRowId;
	}

	private fitGridSizeColumns(): void {
		if (this.gridApi && this.gridColumnApi && this.columns) {
			this.autoSizeAll();
			this.sizeToFit();
		}
	}

	private setSelection(): void {
		if (!this.gridApi) {
			return;
		}

		this.gridApi.selectAll();
		this.gridApi.forEachNode((node: RowNode) => {
			node.setSelected(false);
			if (this.selectedActionRows) {
				this.selectedActionRows.forEach((element: any) => {
					if (Object.is(node.data, element)) {
						node.setSelected(true);
					}
				});
			}
		});

		this.selectedRows = [];
	}

	private fixFilteringWhenSpaceInValue(): void {
		this.columns?.forEach((col: ColDef) => {
			col.getQuickFilterText = (params: GetQuickFilterTextParams): string => {
				if (params.value && typeof params.value !== 'object') {
					return params.value.toString().replace(/ /g, '.');
				}
				return params.value;
			};
		});
	}

	private autoSizeAll(): void {
		const allColumnIds = [];
		if (this.gridColumnApi.getColumns()) {
			this.gridColumnApi.getColumns().forEach((column: Column) => {
				allColumnIds.push(column.getColId());
			});
			this.gridColumnApi.autoSizeColumns(allColumnIds);
		}
	}

	private getAllHiddenColDefs(): ColDef[] {
		return this.gridColumnApi
			.getAllColumns()
			.filter((column: Column) => !column.isVisible())
			.map((column: Column): ColDef => column.getColDef());
	}

	private getAllVisibleColDefs(includePinned: boolean = false): ColDef[] {
		return this.gridColumnApi
			.getAllDisplayedColumns()
			.filter((column: Column) => includePinned || !column.isPinned())
			.map((column: Column): ColDef => column.getColDef());
	}

	private getPinnedColumnsNames(): string[] {
		return this.columns.filter((col: ColDef) => col.pinned).map((x: ColDef) => x.headerName);
	}

	private getAllGridColumns(): Column[] {
		return this.gridOptions.columnApi.getAllGridColumns();
	}

	private getHeaderNames(colDefs: ColDef[]): string[] {
		return colDefs.map((colDef: ColDef): string => colDef.headerName);
	}

	private resolveUserSettings(userSettings: UserSettingDto[]): void {
		this._userSettings = userSettings;
		if (this.isPrepared) {
			return;
		}
		this.updateColumnsBasedOnUserSettings();
		this.isPrepared = true;
	}

	private updateColumnsBasedOnUserSettings(): void {
		if (!this._userSettings?.length) {
			return;
		}

		this.columns.forEach((column: ColDef) => {
			const columnUserSetting: UserSettingDto = this.getColumnUserSetting(column.headerName);
			if (!columnUserSetting) {
				return;
			}
			const isVisible: UserSettingParameterValue = UserSettingsHelper.getParameterValue(columnUserSetting, GridColumnsUserSettingEnum.Visibility);
			if (typeof isVisible === 'boolean') {
				column.initialHide = !isVisible;
			}
		});

		this.visibleColumnNames = this.getHeaderNames(this.columns.filter((column: ColDef) => !column.initialHide));
	}

	private getColumnUserSetting(name: string): UserSettingDto {
		return this._userSettings.find((userSetting: UserSettingDto) => userSetting.name === name && userSetting.tag === this._columnsTag);
	}

	private storeColumnsVisibilitySetting(columns: string[], value: boolean): void {
		if (!this.storeUserSettings || !columns?.length) {
			return;
		}
		const userSettings: UserSettingDto[] = columns.map((column: string): UserSettingDto => {
			const existingColumnUserSetting: UserSettingDto = this.getColumnUserSetting(column);
			if (!existingColumnUserSetting) {
				return this._userSettingsFactoryService.createLocalSimple(
					this._userSettingsLocation,
					column,
					GridColumnsUserSettingEnum.Visibility,
					value,
					this._columnsTag
				);
			}

			return this._userSettingsFactoryService.updateWithSimpleParameter(existingColumnUserSetting, GridColumnsUserSettingEnum.Visibility, value);
		});
		this._userSettingsFacadeService.pushBatchUserSettings(userSettings);
	}

	private getRouteConfigPath(): string {
		return `/${this._route.pathFromRoot
			.filter((route: ActivatedRoute) => !!route.routeConfig?.path)
			.map((route: ActivatedRoute) => route.routeConfig.path)
			.join('/')}`;
	}

	private updateFilterModel(): void {
		const model = this.gridApi.getFilterModel();

		if (model) {
			this.sharedService.setFilterModel(this._viewLocationName, model);
		}
	}

	private updateColumnFilter(): void {
		const stateModel = this.sharedService.getFilterModel(this._viewLocationName);

		if (stateModel) {
			this.gridApi.setFilterModel(stateModel);
		}
	}
}
