import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'fitech-workspace-mat-select-search',
	templateUrl: './mat-select-search.component.html',
	styleUrls: ['./mat-select-search.component.scss'],
})
export class MatSelectSearchComponent implements OnInit, OnDestroy {
	@Input() control: UntypedFormControl;
	@Input() placeholder = 'Search';
	@Input() noResultsText = 'No results';
	@Input() isNoResults = false;
	@Input() isSelectOpened: boolean;
	@Input() isSelectButtons = false;

	@Output() setFormControl = new EventEmitter<UntypedFormControl>();

	@ViewChild('searchInput') private _searchInputRef: ElementRef<HTMLInputElement>;

	ngOnInit(): void {
		if (!this.control) {
			this.control = new UntypedFormControl('');
			this.setFormControl.emit(this.control);
		}
	}

	ngOnDestroy(): void {
		this.clearValue();
	}

	toggleSelect(isOpened: boolean): void {
		if (isOpened) {
			this.focus();
		} else {
			this.clearValue();
		}
	}

	clearValue(autoFocus: boolean = false): void {
		if (this.control.value === '') {
			return;
		}

		this.control.setValue('');

		if (autoFocus) {
			this.focus();
		}
	}

	private focus(): void {
		this._searchInputRef.nativeElement.focus();
	}
}
