<!-- BOM -->
<div class="label">{{ question.label | translate }}</div>
<input #fileInput hidden="true" type="file" onclick="this.value=null" (change)="selectFile($event)" [accept]="question.accept" />
<div class="file-input-container">
	<div *ngIf="!isImgType && fileLength > 0" class="value" (click)="fileInput.click()" [matTooltip]="fileValue">
		Length: {{ fileLength }}
	</div>
	<div *ngIf="imageSource" class="value" (click)="fileInput.click()" [matTooltip]="fileValue">
		<img [src]="imageSource" alt="thumbnail">
	</div>
	<button class="btn btn--primary" type="button" style="height: 38px; margin-top: 12px" [disabled]="fileSelected" (click)="fileInput.click()">
		Select file
	</button>
	<button
		*ngIf="isImgType"
		class="btn btn--primary"
		type="button"
		style="height: 38px; margin-top: 12px"
		[disabled]="!fileSelected"
		(click)="uploadFile()">
		Upload file
	</button>
	<button
		class="btn btn-outline-primary"
		type="button"
		style="height: 38px; margin-top: 12px"
		[disabled]="fileLength == 0 && !isImgType"
		(click)="clear()">
		Remove file
	</button>
	<button
		*ngIf="form.controls[question.key].dirty && showUndoButton"
		class="btn-undo"
		type="button"
		mat-button
		mat-icon-button
		matTooltip="Undo changes"
		aria-label="Undo changes"
		class="btn-undo"
		(click)="undo()">
		<mat-icon>undo</mat-icon>
	</button>
</div>
