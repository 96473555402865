import { ISensorAddedWithAlarm } from '../models/machine-components.model';
import { SensorsChartWithAlarmsComponent } from './sensors-chart-with-alarms/sensors-chart-with-alarms.component';
import { SensorsChartComponent } from './sensors-chart/sensors-chart.component';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { IChartSeriesConfig } from '@fitech-workspace/shared/ui/chart-lib';
import { Sensor } from '@fitech-workspace/fisense-common-lib';

@Component({
  selector: 'fitech-workspace-machine-sensors-charts',
  templateUrl: './machine-sensors-charts.component.html',
  styleUrls: ['./machine-sensors-charts.component.scss']
})
export class MachineSensorsChartsComponent implements OnInit {
  @ViewChild('sensorsChart', { static: true }) sensorsChart: SensorsChartComponent;
  @ViewChild('sensorsChartWithAlarms', { static: true }) sensorsChartWithAlarms: SensorsChartWithAlarmsComponent;


  @Input('isCurrentStateActive') isCurrentStateActive: boolean;
  constructor() { }

  ngOnInit() {

  }


  chartWithoutAlarmsResize() {
    this.sensorsChart.lineChart.resize();
  }

  chartWithAlarmsResize() {
    this.sensorsChartWithAlarms.lineChart.resize();
  }

  addSeriesWithAlarm(options: ISensorAddedWithAlarm) {
    this.sensorsChartWithAlarms.addSeriesWithAlarm(options);
  }

  addSeries(chartSeries: IChartSeriesConfig) {
    this.sensorsChart.lineChart.addSeries(chartSeries);
  }

  removeSeries(sensor: Sensor) {
    this.sensorsChart.lineChart.removeSeries(sensor.name)
  }
}
