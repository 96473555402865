import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../../models/question-base';

@Component({
  selector: 'fitech-workspace-form-question',
  templateUrl: './dynamic-form-input.component.html',
  styleUrls: ['./dynamic-form-input.component.scss']
})
export class DynamicFormInputComponent {

  @Input() question: QuestionBase<any>;
  @Input() form: UntypedFormGroup;
  @Input() headerLabel: string;
  @Input() showUndoButton: boolean;

  undo() {
    this.form.controls[this.question.key].setValue(this.question.value);
    this.form.controls[this.question.key].markAsPristine();
    this.form.controls[this.question.key].markAsUntouched();
  }

  public generateDisplayText(item: any) {
    if (item) {
      return item.value;
    }
  }

}
