import { RoleDto } from '@fitech-workspace/shared/data-access/master-data-api-lib';

export class UserRole implements RoleDto {
	id: number;
	name: string;
	isAdmin: boolean;
	isDefault: boolean;

	constructor(data?: RoleDto) {
		if (data) {
			for (const property in data) {
				if (data.hasOwnProperty(property)) {
					(<any>this)[property] = (<any>data)[property];
				}
			}
		}
	}

	static fromJS(data: any): UserRole {
		data = typeof data === 'object' ? data : {};
		const result = new UserRole();
		result.init(data);
		return result;
	}

	init(data?: any): void {
		if (data) {
			this.id = data['id'];
			this.name = data['name'];
		}
	}

	toJSON(data?: any): any {
		data = typeof data === 'object' ? data : {};
		data['id'] = this.id;
		data['name'] = this.name;
		return data;
	}

	toString(): string {
		return this.name;
	}
}

UserRole.prototype.toString = function () {
	return this.name;
};
