import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { CustomValidators, NotificationService } from '@fitech-workspace/core-lib';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'fitech-workspace-login-request-for-roles',
	templateUrl: './login-request-for-roles.component.html',
	styleUrls: ['./login-request-for-roles.component.scss'],
})
export class LoginRequestForRolesComponent implements OnInit, OnDestroy {
	@Input() logoSrc: string;

	loginData: {
		fullName: null | string;
		externalEmail: null | string;
		roles: { name: string; description?: string }[];
	};

	form: UntypedFormGroup;

	isInProgress: boolean;
	isMessageBox = true;
	message: string;

	private _subscriptions = new Subscription();

	constructor(private _notificationService: NotificationService, private _userService: UserService, private _activateRoute: ActivatedRoute) {}

	ngOnInit(): void {
		this._subscriptions.add(
			this._activateRoute.params.subscribe((params: Params) => {
				const data = params['data'];
				if (!data) {
					this.setInvalidDataMessage();
					return;
				}

				const buf = this.decodeUnicode(data);
				this.loginData = JSON.parse(buf);
				if (!this.loginData || !this.loginData?.externalEmail || !this.loginData?.roles?.length) {
					this.setInvalidDataMessage();
					return;
				}

				this.isMessageBox = false;
				this.initForm();
			}),
		);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	trackByName(index: number, role: { name: string; description?: string }): string {
		return role.name;
	}

	sendMessage(): void {
		const selectedRoles: string[] = Object.keys(this.form.value).filter((roleName: string) => !!this.form.value[roleName]);
		this.isInProgress = true;

		this._userService.requestForRolesForUserAd(this.loginData.externalEmail, selectedRoles).subscribe(
			() => {
				this.isInProgress = false;
				this.isMessageBox = true;
				this.message = 'Thank you. You will be informed by email when the account will be activated.';
			},
			() => {
				this._notificationService.showError('Error sending request');
				this.isInProgress = false;
			},
		);
	}

	private initForm(): void {
		this.form = new UntypedFormGroup({}, CustomValidators.atLeastOneCheckboxSelectedValidator());
		this.loginData.roles.forEach((role: { name: string; description?: string }) => {
			this.form.addControl(role.name, new UntypedFormControl(false));
		});
	}

	private setInvalidDataMessage(): void {
		this.isMessageBox = true;
		this.message = 'Invalid login data. Please inform the admin.';
	}

	private decodeUnicode(data: any): string {
		// Going backwards: from bytestream, to percent-encoding, to original string.
		return decodeURIComponent(
			atob(data)
				.split('')
				.map((c: string) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
				.join(''),
		);
	}
}
