import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'tra-quick-filter',
  templateUrl: './quick-filter.component.html',
  styleUrls: ['./quick-filter.component.scss']
})
export class QuickFilterComponent implements OnInit, OnDestroy {
  @Input() options: QuickFilterComponentOptions;
  @Output() filterChanged = new EventEmitter<string>();
  quickFilter: string = '';
  private timerQuickFilter: any;
  private text: string;

  constructor() {
  }

  ngOnInit() {
    if (!this.options) {
      this.options = {
        showSearchButton: false,
        searchOnTextChange: true,
      }

    }
  }


  clearQuickFilter() {
    this.filterChanged.emit('');
    this.quickFilter = '';
  }

  applyQuickFilterOnClick() {
    this.applyQuickFilter(this.text);
  }

  onKeyDown(event) {
    if (event.key === "Enter") {
      this.applyQuickFilter(this.text);
    }
  }

  applyQuickFilterOnTextChange(filterValue: string) {

    this.text = filterValue;

    if (this.options.searchOnTextChange) {
      //delay in filtering
      if (this.timerQuickFilter != null) {
        clearTimeout(this.timerQuickFilter);
      }

      this.timerQuickFilter = setTimeout(() => {
        this.filterChanged.emit(filterValue);
        this.quickFilter = filterValue;
      }, 500);
    }
  }

  applyQuickFilter(filterValue: string) {
    this.filterChanged.emit(filterValue);
    this.quickFilter = filterValue;
  }


  ngOnDestroy(): void {
    if (this.timerQuickFilter != null) {
      this.timerQuickFilter.clearTimeout();
    }
  }
}

export interface QuickFilterComponentOptions {
  showSearchButton?: boolean;
  searchOnTextChange?: boolean;
}
