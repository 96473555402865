import { Injectable } from '@angular/core';
import { PermissionsMap, PermissionsMapService } from '@fitech-workspace/auth-lib';
import { RolePermissionBackendEnum, RolePermissionFrontendEnum } from '../enums';

@Injectable()
export class CmPermissionsMapService extends PermissionsMapService {
	protected map: PermissionsMap = new Map<RolePermissionFrontendEnum, RolePermissionBackendEnum[]>([
		// Dashboard
		[RolePermissionFrontendEnum.Dashboard, [RolePermissionBackendEnum.MeasurementsCurrentRead]],

		// Components
		[RolePermissionFrontendEnum.ComponentsItemsRead, [RolePermissionBackendEnum.GraphQLComponentsRead]],
		[RolePermissionFrontendEnum.ComponentsItemsWrite, [RolePermissionBackendEnum.GraphQLComponentsWrite, RolePermissionBackendEnum.AlgorithmsRead]],
		[RolePermissionFrontendEnum.ComponentsFeedersRead, [RolePermissionBackendEnum.GraphQLFeedersRead]],
		[RolePermissionFrontendEnum.ComponentsFeedersWrite, [RolePermissionBackendEnum.GraphQLFeedersWrite]],
		[RolePermissionFrontendEnum.ComponentsToolsRead, [RolePermissionBackendEnum.GraphQLToolsRead]],
		[RolePermissionFrontendEnum.ComponentsToolsWrite, [RolePermissionBackendEnum.GraphQLToolsWrite]],
		[RolePermissionFrontendEnum.ComponentsFingersRead, [RolePermissionBackendEnum.GraphQLFingersRead]],
		[RolePermissionFrontendEnum.ComponentsFingersWrite, [RolePermissionBackendEnum.GraphQLFingersWrite, RolePermissionBackendEnum.MasterDataToolsRead]],

		// Updates
		[
			RolePermissionFrontendEnum.UpdatesRead,
			[
				RolePermissionBackendEnum.SoftwarePackagesRead,
				RolePermissionBackendEnum.SoftwarePackagesSubpackagesRead,
				RolePermissionBackendEnum.MeasurementsCurrentBySourcePropertiesTransposedRead,
				RolePermissionBackendEnum.MasterDataSensorsGroupsRead,
			],
		],
		[RolePermissionFrontendEnum.UpdatesSend, [RolePermissionBackendEnum.SoftwarePackagesSend]],
		[RolePermissionFrontendEnum.UpdatesWrite, [RolePermissionBackendEnum.SoftwarePackagesWrite]],

		// Recipes
		[
			RolePermissionFrontendEnum.RecipesGeneratorRead,
			[
				RolePermissionBackendEnum.RecipesRead,
				RolePermissionBackendEnum.MasterDataItemsRead,
				RolePermissionBackendEnum.MasterDataSensorsGroupsRead,
				RolePermissionBackendEnum.GraphQLComponentsRead,
				RolePermissionBackendEnum.AlgorithmsRead,
			],
		],
		[RolePermissionFrontendEnum.RecipesGeneratorSend, [RolePermissionBackendEnum.RecipesStatusWrite]],
		[RolePermissionFrontendEnum.RecipesGeneratorWrite, [RolePermissionBackendEnum.RecipesWrite, RolePermissionBackendEnum.GraphQLComponentsWrite]],
		[RolePermissionFrontendEnum.RecipesAlgorithmsRead, [RolePermissionBackendEnum.AlgorithmsRead, RolePermissionBackendEnum.AlgorithmsStagesRead]],
		[RolePermissionFrontendEnum.RecipesAlgorithmsWrite, [RolePermissionBackendEnum.AlgorithmsWrite]],
		[RolePermissionFrontendEnum.RecipesActionsRead, [RolePermissionBackendEnum.AlgorithmsStagesRead]],
		[RolePermissionFrontendEnum.RecipesActionsWrite, [RolePermissionBackendEnum.AlgorithmsActionsWrite]],

		// Management
		[RolePermissionFrontendEnum.ManagementLocationsRead, [RolePermissionBackendEnum.GraphQLLocationsRead]],
		[RolePermissionFrontendEnum.ManagementLocationsWrite, [RolePermissionBackendEnum.GraphQLLocationsWrite]],
		[RolePermissionFrontendEnum.ManagementZonesRead, [RolePermissionBackendEnum.GraphQLZonesRead]],
		[RolePermissionFrontendEnum.ManagementZonesWrite, [RolePermissionBackendEnum.GraphQLZonesWrite, RolePermissionBackendEnum.GraphQLLocationsRead]],
		[RolePermissionFrontendEnum.ManagementLinesRead, [RolePermissionBackendEnum.GraphQLLinesRead]],
		[RolePermissionFrontendEnum.ManagementLinesWrite, [RolePermissionBackendEnum.GraphQLLinesWrite, RolePermissionBackendEnum.GraphQLZonesRead]],
		[RolePermissionFrontendEnum.ManagementSensorsGroupsRead, [RolePermissionBackendEnum.GraphQLSensorsGroupsRead]],
		[
			RolePermissionFrontendEnum.ManagementSensorsGroupsWrite,
			[
				RolePermissionBackendEnum.GraphQLSensorsGroupsWrite,
				RolePermissionBackendEnum.GraphQLTagsRead,
				RolePermissionBackendEnum.GraphQLLinesRead,
				RolePermissionBackendEnum.GraphQLZonesRead,
			],
		],
		[RolePermissionFrontendEnum.ManagementDevicesRead, [RolePermissionBackendEnum.GraphQLDevicesRead]],
		[RolePermissionFrontendEnum.ManagementDevicesWrite, [RolePermissionBackendEnum.GraphQLDevicesWrite]],
		[RolePermissionFrontendEnum.ManagementSensorsRead, [RolePermissionBackendEnum.GraphQLSensorsRead]],
		[
			RolePermissionFrontendEnum.ManagementSensorsWrite,
			[
				RolePermissionBackendEnum.GraphQLSensorsWrite,
				RolePermissionBackendEnum.GraphQLSensorsGroupsRead,
				RolePermissionBackendEnum.GraphQLLinesRead,
				RolePermissionBackendEnum.GraphQLZonesRead,
				RolePermissionBackendEnum.GraphQLDevicesRead,
			],
		],
		[RolePermissionFrontendEnum.ManagementMachineStatesRead, [RolePermissionBackendEnum.GraphQLMachineStatusesRead]],
		[RolePermissionFrontendEnum.ManagementMachineStatesWrite, [RolePermissionBackendEnum.GraphQLMachineStatusesWrite]],
		[RolePermissionFrontendEnum.ManagementOperationStatusesRead, [RolePermissionBackendEnum.GraphQLSingleOperationStatusesRead]],
		[
			RolePermissionFrontendEnum.ManagementOperationStatusesWrite,
			[RolePermissionBackendEnum.GraphQLSingleOperationStatusesWrite, RolePermissionBackendEnum.GraphQLTagsRead],
		],
		[RolePermissionFrontendEnum.ManagementNotificationsRead, [RolePermissionBackendEnum.GraphQLNotificationRecipientsRead]],
		[
			RolePermissionFrontendEnum.ManagementNotificationsWrite,
			[
				RolePermissionBackendEnum.GraphQLNotificationRecipientsWrite,
				RolePermissionBackendEnum.GraphQLNotificationTopicsRead,
				RolePermissionBackendEnum.GraphQLZonesRead,
				RolePermissionBackendEnum.GraphQLSensorsRead,
			],
		],
		[RolePermissionFrontendEnum.ManagementShiftsRead, [RolePermissionBackendEnum.GraphQLShiftsRead]],
		[RolePermissionFrontendEnum.ManagementShiftsWrite, [RolePermissionBackendEnum.GraphQLShiftsWrite, RolePermissionBackendEnum.GraphQLLocationsRead]],
		[RolePermissionFrontendEnum.ManagementUsersRead, [RolePermissionBackendEnum.UsersRead]],
		[RolePermissionFrontendEnum.ManagementUsersWrite, [RolePermissionBackendEnum.UsersWrite, RolePermissionBackendEnum.RolesRead]],
		[RolePermissionFrontendEnum.ManagementUsersPasswordReset, [RolePermissionBackendEnum.UsersConfirmEmailWrite]],
		[RolePermissionFrontendEnum.ManagementRolesRead, [RolePermissionBackendEnum.RolesRead, RolePermissionBackendEnum.RolesPermissionsRead]],
		[RolePermissionFrontendEnum.ManagementRolesWrite, [RolePermissionBackendEnum.RolesWrite]],
		[RolePermissionFrontendEnum.ManagementClientsRead, [RolePermissionBackendEnum.ClientsRead]],
		[RolePermissionFrontendEnum.ManagementClientsWrite, [RolePermissionBackendEnum.ClientsWrite]],
		[
			RolePermissionFrontendEnum.ManagementFloorplanRead,
			[RolePermissionBackendEnum.GraphQLLocationsRead, RolePermissionBackendEnum.GraphQLSensorsRead, RolePermissionBackendEnum.GraphQLSensorsGroupsRead],
		],
		[
			RolePermissionFrontendEnum.ManagementFloorplanWrite,
			[RolePermissionBackendEnum.GraphQLSensorsWrite, RolePermissionBackendEnum.GraphQLSensorsGroupsWrite],
		],
		[RolePermissionFrontendEnum.ManagementChangeClients, []],

		// Alarms
		[RolePermissionFrontendEnum.MachinesAlarms, [RolePermissionBackendEnum.AlarmsHistoryRead, RolePermissionBackendEnum.MasterDataSensorsGroupsRead]],

		// Monitoring
		[
			RolePermissionFrontendEnum.MonitoringFloorplan,
			[
				RolePermissionBackendEnum.GraphQLLocationsRead,
				RolePermissionBackendEnum.MeasurementsCurrentRead,
				RolePermissionBackendEnum.AlarmsNotAckedRead,
				RolePermissionBackendEnum.AlarmsHistoryRead,
			],
		],
		[
			RolePermissionFrontendEnum.MonitoringSensorsAlarms,
			[
				RolePermissionBackendEnum.GraphQLLocationsRead,
				RolePermissionBackendEnum.MeasurementsCurrentRead,
				RolePermissionBackendEnum.AlarmsNotAckedRead,
				RolePermissionBackendEnum.AlarmsHistoryRead,
			],
		],
		[
			RolePermissionFrontendEnum.MonitoringTrendViewer,
			[
				RolePermissionBackendEnum.GraphQLLocationsRead,
				RolePermissionBackendEnum.MeasurementsCurrentRead,
				RolePermissionBackendEnum.MeasurementsHistoryAggregatedRead,
			],
		],
		[RolePermissionFrontendEnum.MonitoringAlarmsDeck, []],

		// Reports
		[
			RolePermissionFrontendEnum.ReportsProduction,
			[
				RolePermissionBackendEnum.ThtLogsRead,
				RolePermissionBackendEnum.ThtLogsItemsStatisticsRead,
				RolePermissionBackendEnum.ThtLogsFileTccRead,
				RolePermissionBackendEnum.MasterDataSensorsGroupsRead,
				RolePermissionBackendEnum.MasterDataItemsRead,
				RolePermissionBackendEnum.MasterDataComponentsRead,
				RolePermissionBackendEnum.HeartbeatsRead,
				RolePermissionBackendEnum.MeasurementsCurrentBySourcePropertiesTransposedRead,
			],
		],
		[
			RolePermissionFrontendEnum.ReportsAggregate,
			[
				RolePermissionBackendEnum.KpiTimeWorkingRead,
				RolePermissionBackendEnum.KpiOeeStatisticsRead,
				RolePermissionBackendEnum.KpiGanttStatesRead,
				RolePermissionBackendEnum.MeasurementsHistorySummedBySourcePropertiesRead,
				RolePermissionBackendEnum.ThtLogsShiftProbeStatsRead,
				RolePermissionBackendEnum.KpiProductionCountersRead,
				RolePermissionBackendEnum.MasterDataSensorsGroupsRead,
				RolePermissionBackendEnum.MasterDataItemsRead,
				RolePermissionBackendEnum.MasterDataLinesRead,
				RolePermissionBackendEnum.GraphQLMachineStatusesRead,
				RolePermissionBackendEnum.HeartbeatsRead,
				RolePermissionBackendEnum.MeasurementsCurrentBySourcePropertiesTransposedRead,
			],
		],
		[
			RolePermissionFrontendEnum.ReportsTechnical,
			[
				RolePermissionBackendEnum.ThtLogsRead,
				RolePermissionBackendEnum.MeasurementsHistoryAggregatedRead,
				RolePermissionBackendEnum.ThtLogsPlacementSpeedRead,
				RolePermissionBackendEnum.KpiTactTimeRead,
				RolePermissionBackendEnum.KpiPcbaBuildTimeRead,
				RolePermissionBackendEnum.ThtLogsShiftProbeStatsRead,
				RolePermissionBackendEnum.MasterDataSensorsGroupsRead,
				RolePermissionBackendEnum.MasterDataSensorsRead,
				RolePermissionBackendEnum.MasterDataItemsRead,
				RolePermissionBackendEnum.MasterDataDesignatorsRead,
				RolePermissionBackendEnum.MasterDataComponentsRead,
				RolePermissionBackendEnum.MasterDataPointStatusesRead,
				RolePermissionBackendEnum.MasterDataLinesRead,
				RolePermissionBackendEnum.HeartbeatsRead,
				RolePermissionBackendEnum.MeasurementsCurrentBySourcePropertiesTransposedRead,
			],
		],
		[RolePermissionFrontendEnum.ReportShiftDashboard, [RolePermissionBackendEnum.AlarmsHistoryRead, RolePermissionBackendEnum.ThtLogsRead]],
		[
			RolePermissionFrontendEnum.ReportOverallDashboard,
			[RolePermissionBackendEnum.AlarmsHistoryRead, RolePermissionBackendEnum.KpiTactTimeRead, RolePermissionBackendEnum.ThtLogsRead],
		],
	]);

	private readonly _defaultPermissions: RolePermissionBackendEnum[] = [
		RolePermissionBackendEnum.ClientsRead,
		RolePermissionBackendEnum.UsersSelfRead,
		RolePermissionBackendEnum.UsersResetPasswordWrite,
		RolePermissionBackendEnum.VersionsRead,
	];

	provideDefaultPermissions(): string[] {
		return this._defaultPermissions;
	}
}
