import { Component, OnInit, Input, Inject } from '@angular/core';
import { UserService, AUTH_ENV_CONFIG } from '../../services/user.service';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { NotificationService } from '@fitech-workspace/core-lib';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { SetPasswordReq, ApiService } from '@fitech-workspace/shared/data-access/master-data-api-lib';

@Component({
  selector: 'fitech-workspace-password-set',
  templateUrl: './password-set.component.html',
  styleUrls: ['./password-set.component.scss']
})
export class PasswordSetComponent implements OnInit {
  @Input() logoSrc: string;
  private environmentConfig;
  isInProgress: boolean;
  form: UntypedFormGroup;
  emailControl = new UntypedFormControl();
  newPasswordControl = new UntypedFormControl();
  confirmPasswordControl = new UntypedFormControl();
  isSubmitting = false;
  isSubmitted = false;
  emailParam = '';
  codeParam = '';

  constructor(
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private apiClient: ApiService,
    private route: ActivatedRoute,
    @Inject(AUTH_ENV_CONFIG) environmentConfig: any) {
    this.environmentConfig = environmentConfig;
    if (this.environmentConfig) {
      this.logoSrc = this.environmentConfig.logoSrc;
    }

    this.route.queryParams.subscribe(params => {
      this.emailParam = params['email'];
      this.codeParam = params['code'];
    });
  }

  ngOnInit() {
    this.createFormGroups(this.formBuilder);
  }

  createFormGroups(fb: UntypedFormBuilder) {
    this.form = fb.group({
      email: this.emailControl,
      newPassword: this.newPasswordControl,
      confirmPassword: this.confirmPasswordControl
    }, { validator: this.checkPasswords });

    this.emailControl.setValidators([Validators.required, Validators.email]);
    this.newPasswordControl.setValidators([
      Validators.required,
      Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,100}')
    ]);

    if (this.emailParam)
      this.emailControl.setValue(this.emailParam);
  }

  checkPasswords(form: UntypedFormGroup) {
    const pass = form.controls.newPassword.value;
    const confirmPass = form.controls.confirmPassword.value;

    let result = null;
    if (pass !== confirmPass) {
      form.controls.confirmPassword.setErrors({ notSamePassword: true });
      result = { notSamePassword: true };

    }
    return result;
  }



  shouldDisableAction() {
    return this.isSubmitting || this.isSubmitted ||
      this.emailControl.invalid ||
      this.newPasswordControl.invalid ||
      this.confirmPasswordControl.invalid;
  }

  setPassword() {
    this.isSubmitting = true;
    const setPasswordReq = this.createSetPasswordReq();
    this.apiClient.setPassword(setPasswordReq)
      .subscribe(
        res => {
          this.isSubmitting = false;
          this.isSubmitted = true;
          this.notificationService.showSuccess('Password was set');
          this.login(setPasswordReq.email, setPasswordReq.password)
        },
        err => {
          this.isSubmitting = false;

          if (err.response != null && err.response.toString().toLowerCase().includes("invalidtoken")) {
            this.notificationService.showInfo('Password was not set. Confirmation e-mail expired. Please ask an administrator for a new one.');
          } else {
            this.notificationService.showInfo('Password was not set');
          }
        }
      );
  }

  login(userName: string, password: string) {
    this.userService
      .loginWith(userName, password)
      .subscribe();
  }

  createSetPasswordReq(): SetPasswordReq {
    const setPasswordReq: SetPasswordReq = {
      email: this.emailControl.value,
      password: this.newPasswordControl.value,
      confirmPassword: this.confirmPasswordControl.value,
      code: this.codeParam,
    };
    return setPasswordReq;
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.setPassword();
    }
  }
  isValid(key) {
    return this.form.controls[key].valid;
  }
  isTouched(key) {
    return this.form.controls[key].touched;
  }
  getErrorMsg(key) {
    let errorMsg = '';
    if (this.isTouched(key)) {
      if (this.form.controls[key].hasError('required')) {
        errorMsg += 'Field is required. ';
      }
      if (this.form.controls[key].hasError('email')) {
        errorMsg += 'Field does not meet e-mail pattern requirement. ';
      }
      if (this.form.controls[key].hasError('pattern')) {
        errorMsg += 'The password must be at least 6 characters long. Must have lower and upper case and digit.';
      }
      if (key === 'confirmPassword' && this.form.controls[key].parent.hasError('notSamePassword')) {
        errorMsg += 'The password and confirmation password do not match. ';
      }
    }
    return errorMsg;
  }
}