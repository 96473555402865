import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomMaterialModule } from './custom-material/custom-material.module';
import { AddDynamicComponentDirective, ClickOutsideDirective } from './directives';
import { LanguagePickerComponent } from './language/language-picker/language-picker.component';
import { MultiTranslateHttpLoader } from './language/multi-translate-http-loader';
import { PaginatorIntlService } from './language/paginator-intl-service';
import { ScreenSizeService } from './size-detector/screen-size.service';
import { SizeDetectorComponent } from './size-detector/size-detector.component';

@NgModule({
	imports: [CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule, RouterModule, CustomMaterialModule, TranslateModule],
	declarations: [LanguagePickerComponent, SizeDetectorComponent, AddDynamicComponentDirective, ClickOutsideDirective],
	exports: [
		CommonModule,
		HttpClientModule,
		CustomMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		LanguagePickerComponent,
		SizeDetectorComponent,
		TranslateModule,
		AddDynamicComponentDirective,
		ClickOutsideDirective,
	],
	providers: [],
})
export class CoreLibModule {
	static forRoot(): ModuleWithProviders<CoreLibModule> {
		return {
			ngModule: CoreLibModule,
			providers: [
				{
					provide: MatPaginatorIntl,
					useFactory: injectTranslateService,
					deps: [TranslateService],
				},
				ScreenSizeService,
			],
		};
	}
}

export function injectTranslateService(translate: TranslateService): PaginatorIntlService {
	const service = new PaginatorIntlService();
	service.injectTranslateService(translate);
	return service;
}

export function HttpLoaderFactory(http: HttpClient): MultiTranslateHttpLoader {
	return new MultiTranslateHttpLoader(http, [
		{ prefix: './assets/i18n/', suffix: '.json' },
		{ prefix: './assets/i18n/', suffix: '-errors.json' },
		{ prefix: './assets/i18n/', suffix: '-common.json' },
		{ prefix: './assets/i18n/', suffix: '-manual.json' },
	]);
}
