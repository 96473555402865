import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MachineState, Sensor } from '@fitech-workspace/fisense-common-lib';
import { IGpsPosition, IMapMarkerDataV2 } from '@fitech-workspace/shared/ui/map-lib';
import { AsEnumerable } from 'linq-es2015';
import { Globals } from '../../globals';
import { ThtMachineDialogComponent } from '../components/tht-machine-dialog/tht-machine-dialog.component';
import { ThtMachineDialogOptions } from '../models/tht-machine-dialog-options.model';

export class ThtMachineMarker implements IMapMarkerDataV2 {
	id: any;
	iconClass: string;
	iconHtml: string;
	stateColor: { state: string; color: string; priority: number };
	gps: IGpsPosition;
	lastTimestamp: Date;
	lastTimestampFromNow: string;
	extraData: {
		machineName: string;
		machineStatus: string;
		lastItem: string;
		machine: MachineState;
	};

	get hasAsyncCommunication(): boolean {
		if (this.extraData && this.extraData.machine && this.extraData.machine.defaultSensorState && this.extraData.machine.defaultSensorState.source) {
			return this.extraData.machine.defaultSensorState.source.type.endsWith('digi');
		}
		return null;
	}

	constructor(state?: MachineState) {
		if (!state) {
			return;
		}

		for (const property in state) {
			if (state.hasOwnProperty(property)) {
				(<any>this)[property] = (<any>state)[property];
			}
		}
		this.id = state.sourceId;
		this.lastTimestamp = state.timestamp;
		this.lastTimestampFromNow = state.timestampFromNow;

		this.gps = {
			lat: state.source.positionX,
			lng: state.source.positionY,
		};

		const lastItemSensor = AsEnumerable(state.sensorsStates).FirstOrDefault((x) => x.source.sourceProperty == 'item');
		const machineStatusSensor = AsEnumerable(state.sensorsStates).FirstOrDefault((x) => x.source.sourceProperty == 'machineStatus');

		this.extraData = {
			machineName: state.source.name,
			machine: state,
			lastItem: lastItemSensor && lastItemSensor.rawValue ? lastItemSensor.rawValue : '-',
			machineStatus: machineStatusSensor && machineStatusSensor.rawValue ? machineStatusSensor.rawValue : '-',
		};

		this.setState();
		this.setIconHtml();
	}

	setState() {
		if (Globals.MACHINE_WORK_STATUSES.includes(this.extraData.machineStatus)) {
			this.stateColor = Sensor.getSensorStateColor('active');
		} else if (Globals.MACHINE_STOP_STATUSES.includes(this.extraData.machineStatus)) {
			this.stateColor = Sensor.getSensorStateColor('inactive');
		} else if (Globals.MACHINE_DISABLED_STATUSES.includes(this.extraData.machineStatus)) {
			this.stateColor = Sensor.getSensorStateColor('disabled');
		} else if (Globals.MACHINE_ALARM_STATUSES.includes(this.extraData.machineStatus)) {
			this.stateColor = Sensor.getSensorStateColor('alarm-high');
		} else {
			this.stateColor = Sensor.getSensorStateColor('warning'.toLowerCase());
		}
	}

	openDialog(dialog: MatDialog) {
		const options: ThtMachineDialogOptions = {
			title: this.extraData.machineName,
			subTitle: '',
			machineId: this.id,
			currentMachineSensorStatesGridData: (this as any).sensorsStates,
		};

		const dialogRef = dialog.open(ThtMachineDialogComponent, {
			width: '60%',
			height: '70%',
		});
		dialogRef.componentInstance.initialize(options);
	}

	setIconHtml() {
		this.iconHtml = `
        <div class="sensor-detail" data-toggle="tooltip" trackerId="${this.id}" style="background-color:${this.stateColor.color}">
        <i class="sensor-detail-icon fas fa-cogs"></i>
        <div class="sensor-detail-container">
        <span class="two-rows-line" style="font-size: 11px">${this.extraData.machineName}</span>
        <span class="two-rows-line">${this.extraData.lastItem}</span>
        </div>
      </div>`;
	}

	generateTooltipText() {
		return `<div class="marker-tooltip-text" style="min-width: 200px">
    ${this.extraData.machineStatus}</br>
    (${this.lastTimestampFromNow})
   </div>`;
	}
}
