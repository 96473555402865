<div class='column-container'>
  <div class="header-container">
    <div class="row-container">
      <div class="title">
        <h1 class="modal-title" id="statusChartLongTitle">{{title}}
          <h4 class="modal-sub-title">{{subTitle}}</h4>
        </h1>
      </div>
    </div>
  </div>
  <div mat-dialog-content>
    <fitech-workspace-collapsible-layout (expand)="onSidePanelToggled()" (collapse)="onSidePanelToggled()"
      [sidePanelWidthMode]="'wide'">
      <div side-panel>
        <fitech-workspace-machine-sensors-list #machineSensorsList
          [currentMachineSensorStatesGridData]="currentMachineSensorStates" [trendSensorIds]="trendSensorIds"
          [machineAlarmsGridData]="machineAlarmsGridData" (dateRangeChange)="onDateRangeChanged($event)"
          [loading]="loadingAlarms" (sensorAdded)="addSensor($event)" (sensorRemoved)="removeSensor($event)"
          (sensorAddedWithAlarm)="onSensorAddedWithAlarm($event)" (currentStateChange)="onCurrentStateChange($event)">
        </fitech-workspace-machine-sensors-list>
      </div>
      <div content class="trend-viewer">
        <div class="trend-viewer__content">
          <fitech-workspace-machine-sensors-charts #sensorsCharts [isCurrentStateActive]="isCurrentStateActive">
          </fitech-workspace-machine-sensors-charts>

          <div class="trend-viewer__chart-sensors">
            <ng-container *ngFor="let sensor of trendSensors; trackBy: trackBySensorId; last as isLast">
              <fitech-workspace-trend-sensor
                [trendSensor]="sensor"
                [isLast]="isLast"
                (removed)="removeSensor(sensor)"
                (visibilityToggled)="toggleSensorVisibility(sensor)"
                (chartTypeToggled)="toggleSensorChartType(sensor)"
              ></fitech-workspace-trend-sensor>
            </ng-container>
          </div>
        </div>
      </div>
    </fitech-workspace-collapsible-layout>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">
      {{ 'Cancel' | translate}}
    </button>
  </div>
</div>
