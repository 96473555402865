import { AsEnumerable } from 'linq-es2015';
import * as moment from 'moment';

export class MeasurementSummary {
	sensorName: number;
	sensorType: string;
	locationName: string;
	zoneName: string;
	units: string;
	values: IMeasurementSummaryData[];

	constructor(data?: any) {
		if (data) {
			for (const property in data) {
				if (data.hasOwnProperty(property)) {
					(<any>this)[property] = (<any>data)[property];
				}
			}
		}
	}

	getValueByDay(day: Date, precision: number = 2) {
		const result = AsEnumerable(this.values)
			.Where((x) => x.timestamp && moment(x.timestamp).isSame(moment(day), 'day'))
			.FirstOrDefault();
		if (result) {
			return {
				timestamp: result.timestamp,
				min: result.min.toFixed(precision),
				max: result.max.toFixed(precision),
				value: result.value.toFixed(precision),
			};
		} else {
			return {
				timestamp: day,
				min: null,
				max: null,
				value: null,
			};
		}
	}
}

export interface IMeasurementSummaryData {
	timestamp: Date;
	min: number;
	max: number;
	value: number;
}
