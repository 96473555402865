import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpParamsBuilder } from '@fitech-workspace/core-lib';
import { Alarm } from '@fitech-workspace/fisense-common-lib';
import { CopyDeviceCmd } from '../../management/models/copyDeviceCmd';
import { ReportKpiOeeData } from '../../reports/models/report-kpi-oee.model';
import { ReportKpiUtilizationData } from '../../reports/models/report-kpi-utilization.model';
import { ReportPcbaBuildTimeData } from '../../reports/models/report-pcba-build-time.model';
import { ReportTactTimeData } from '../../reports/models/report-tact-time.model';
import { DesignatorDto } from '../models/designator.model';
import { MeasurementSummary } from '../models/kpi/measurement-summary.model';
import { MachineHardwareAndSoftware } from '../models/machine-hardware-and-software.model';
import { MeasurementCurrentThinDto } from '../models/measurement-current.model';
import { MeasurementHistoryWithExtendedDataDto } from '../models/measurement-history-withExtendedData';
import { SensorThinDto } from '../models/sensor.model';
import { StateDto } from '../models/state.model';

export const CM_BACKEND_API_URL = new InjectionToken<string>('CM_BACKEND_API_URL');

@Injectable({
	providedIn: 'root',
})
export class CmBackendApiService {
	constructor(
		protected httpClient: HttpClient,
		@Optional()
		@Inject(CM_BACKEND_API_URL)
		protected apiUrl?: string
	) {}

	copyDevice(copyDeviceCmd: CopyDeviceCmd): Observable<any> {
		return this.httpClient.post<any>(this.apiUrl + 'masterData/devices/copy', copyDeviceCmd);
	}

	getItemsAsync(from?: Date, to?: Date, deviceImeis?: string[], lineId?: number): Observable<string[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create()
			.append('from', moment(from).toISOString())
			.append('to', moment(to).toISOString())
			.appendArray('deviceImeis', deviceImeis)
			.append('lineId', lineId);

		return this.httpClient.get<string[]>(this.apiUrl + 'masterData/items', { params: httpParamsBuilder.build() });
	}

	getComponentNamesAsync(from?: Date, to?: Date, deviceImeis?: string[], lineId?: number): Observable<string[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create()
			.append('from', moment(from).toISOString())
			.append('to', moment(to).toISOString())
			.appendArray('deviceImeis', deviceImeis)
			.append('lineId', lineId);

		return this.httpClient.get<string[]>(this.apiUrl + 'masterData/components', { params: httpParamsBuilder.build() });
	}

	getDesignatorsAsync(from?: Date, to?: Date, deviceImeis?: string[], items?: string[]): Observable<DesignatorDto[]> {
		const params: any = {};

		if (deviceImeis != null) {
			params.deviceImeis = deviceImeis;
		}
		if (items != null) {
			params.items = items;
		}

		if (from != null) {
			params.from = moment(from).toISOString();
		}
		if (to != null) {
			params.to = moment(to).toISOString();
		}

		return this.httpClient.get<DesignatorDto[]>(this.apiUrl + 'masterData/designators', { params });
	}

	getPointStatusesAsync(onlyPassAndProcessErrors: boolean, deviceImeis: string[], from: Date, to: Date): Observable<string[]> {
		const httpParamsBuilder = HttpParamsBuilder.create()
			.append('onlyPassAndProcessErrors', onlyPassAndProcessErrors)
			.appendArray('deviceImeis', deviceImeis)
			.append('from', moment(from).toISOString())
			.append('to', moment(to).toISOString());

		return this.httpClient.get<string[]>(this.apiUrl + 'masterData/pointStatuses', { params: httpParamsBuilder.build() });
	}

	getFloorPlanSasUri(locationId: number, floorPlanUrl: string): Observable<string> {
		const httpParamsBuilder = HttpParamsBuilder.create().append('locationId', locationId).append('floorPlanUrl', floorPlanUrl);

		return this.httpClient.get<string>(this.apiUrl + 'masterData/floor-plan', { params: httpParamsBuilder.build() });
	}

	getMeasurementsCurrentBySourceProperties(sourceProperties: string[], deviceImeis: string[]): Observable<MeasurementCurrentThinDto[]> {
		const params: any = {
			sourceProperties: sourceProperties,
			deviceImeis: deviceImeis,
		};

		return this.httpClient.get<MeasurementCurrentThinDto[]>(this.apiUrl + 'getMeasurementsCurrentBySourceProperties', { params });
	}

	getMeasurementsCurrentBySourcePropertiesTransposed(sourceProperties: string[], deviceImeis: string[]): Observable<MachineHardwareAndSoftware[]> {
		const params: any = {
			sourceProperties: sourceProperties,
			deviceImeis: deviceImeis,
		};

		return this.httpClient.get<MachineHardwareAndSoftware[]>(this.apiUrl + 'getMeasurementsCurrentBySourcePropertiesTransposed', { params });
	}

	getSensorIds(sensorSourceProperties: string[], sensorsGroupIds?: number[]): Observable<SensorThinDto[]> {
		const httpParamsBuilder = HttpParamsBuilder.create()
			.appendArray('sensorSourceProperties', sensorSourceProperties)
			.appendArray('sensorsGroupsIds', sensorsGroupIds);

		return this.httpClient.get<SensorThinDto[]>(this.apiUrl + 'masterData/sensors', { params: httpParamsBuilder.build() });
	}

	getGanttStates(from: Date, to: Date, sensorsGroupIds: number[]): Observable<StateDto[]> {
		const params: any = {
			from: moment(from).toISOString(),
			to: moment(to).toISOString(),
			sensorsGroupIds: sensorsGroupIds,
		};

		return this.httpClient.get<StateDto[]>(this.apiUrl + 'kpi/ganttStates', {
			params,
		});
	}

	getProductionCounters(from: Date, to: Date, sensorsGroupIds: number[], items?: string[]): Observable<MeasurementHistoryWithExtendedDataDto[]> {
		const params: any = {
			from: moment(from).toISOString(),
			to: moment(to).toISOString(),
			sensorsGroupIds: sensorsGroupIds,
			items: items,
		};

		return this.httpClient.get<MeasurementHistoryWithExtendedDataDto[]>(this.apiUrl + 'kpi/productionCounters', { params });
	}

	GetKpiSensors(from: Date, to: Date, pointTypes: string[], zoneIds: number[], frequencyInSeconds?: number): Observable<MeasurementSummary[]> {
		const params: any = {
			from: moment(from).toISOString(),
			to: moment(to).toISOString(),
		};

		if (zoneIds != null) {
			params.zoneIds = zoneIds;
		}

		if (pointTypes != null) {
			params.pointTypes = pointTypes;
		}

		return this.httpClient.get<MeasurementSummary[]>(this.apiUrl + 'GetKpiSensors', { params }).pipe(map((x) => x.map((y) => new MeasurementSummary(y))));
	}

	getKpiTimeWorking(from: Date, to: Date, frequency: string, sensorsGroupIds: number[], lineId: number): Observable<ReportKpiUtilizationData[]> {
		const params: any = {
			from: moment(from).toISOString(),
			to: moment(to).toISOString(),
			frequency: frequency,
			sensorsGroupIds: sensorsGroupIds,
		};

		if (lineId != null) {
			params.lineId = lineId;
		}

		return this.httpClient.get<ReportKpiUtilizationData[]>(this.apiUrl + 'Kpi/timeWorking', {
			params,
		});
	}

	getTactTime(
		from: Date,
		to: Date,
		frequency: string,
		items?: string[],
		sensorsGroupIds?: number[],
		lineId?: number,
		groupByLine: boolean = false
	): Observable<ReportTactTimeData[]> {
		const httpParamsBuilder = HttpParamsBuilder.create()
			.append('from', moment(from).toISOString())
			.append('to', moment(to).toISOString())
			.append('frequency', frequency)
			.appendArray('items', items)
			.appendArray('sensorsGroupIds', sensorsGroupIds)
			.append('lineId', lineId)
			.append('groupByLine', groupByLine, true);

		return this.httpClient.get<ReportTactTimeData[]>(this.apiUrl + 'Kpi/tactTime', {
			params: httpParamsBuilder.build(),
		});
	}

	getPcbaBuildTime(from: Date, to: Date, frequency: string, sensorsGroupIds: number[], lineId: number): Observable<ReportPcbaBuildTimeData[]> {
		const params: any = {
			from: moment(from).toISOString(),
			to: moment(to).toISOString(),
			frequency: frequency,
			sensorsGroupIds: sensorsGroupIds,
		};

		if (lineId != null) {
			params.lineId = lineId;
		}

		return this.httpClient.get<ReportPcbaBuildTimeData[]>(this.apiUrl + 'Kpi/pcbaBuildTime', {
			params,
		});
	}

	getOeeStatistics(from: Date, to: Date, frequency: string, sensorsGroupIds: number[], lineId?: number): Observable<ReportKpiOeeData[]> {
		const params: any = {
			from: moment(from).toISOString(),
			to: moment(to).toISOString(),
			frequency: frequency,
			sensorsGroupIds: sensorsGroupIds,
		};

		if (lineId != null) {
			params.lineId = lineId;
		}

		return this.httpClient.get<ReportKpiOeeData[]>(this.apiUrl + 'Kpi/oeeStatistics', { params });
	}

	getAlarms(
		from: Date,
		to: Date,
		locationId?: number,
		sensorId?: number,
		deviceImeis?: string[],
		items?: string[],
		sensorsGroupIds?: number[]
	): Observable<Alarm[]> {
		const params: any = {
			from: moment(from).toISOString(),
			to: moment(to).toISOString(),
		};

		if (locationId != null) {
			params.locationId = locationId;
		}
		if (sensorId != null) {
			params.sensorId = sensorId;
		}
		if (deviceImeis != null) {
			params.deviceImeis = deviceImeis;
		}
		if (items != null) {
			params.items = items;
		}
		if (sensorsGroupIds != null) {
			params.sensorsGroupIds = sensorsGroupIds;
		}

		return this.httpClient.get<any[]>(this.apiUrl + 'GetAlarmsHistory', { params }).pipe(
			map((res) => {
				return res.map((data) => new Alarm(data));
			})
		);
	}

	getAlarmsThin(from: Date, to: Date, locationId?: number, sensorId?: number, zoneIds?: number[]): Observable<Alarm[]> {
		const params: any = {
			from: moment(from).toISOString(),
			to: moment(to).toISOString(),
		};

		if (locationId != null) {
			params.locationId = locationId;
		}
		if (zoneIds != null) {
			params.zoneIds = zoneIds;
		}
		if (sensorId != null) {
			params.sensorId = sensorId;
		}

		return this.httpClient.get<any[]>(this.apiUrl + 'GetAlarmsHistoryThin', { params }).pipe(
			map((res) => {
				return res.map((data) => new Alarm(data));
			})
		);
	}

	getComponentImgSasUri(componentId: number, imgUrl: string): Observable<string> {
		const httpParamsBuilder = HttpParamsBuilder.create().append('componentId', componentId).append('imgUrl', imgUrl);

		return this.httpClient.get<string>(this.apiUrl + 'masterData/component-img', { params: httpParamsBuilder.build() });
	}

	uploadFloorPlan(formData: FormData): Observable<string> {
		return this.httpClient.post<string>(this.apiUrl + 'masterData/floor-plan', formData);
	}

	uploadComponentImg(file: File): Observable<string> {
		const formData: FormData = new FormData();
		formData.append('image', file);

		return this.httpClient.post<string>(this.apiUrl + 'masterData/component-img', formData);
	}
}
