import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationService } from '@fitech-workspace/core-lib';
import { VersionsApiService } from '../../api/versions-api.service';
import { VersionsDto } from '../../models/versions-dto.model';

declare let $: any;

@Component({
	selector: 'fitech-workspace-versions-dialog',
	templateUrl: './versions-dialog.component.html',
	styleUrls: ['./versions-dialog.component.scss'],
})
export class VersionsDialogComponent implements OnInit, OnDestroy, AfterViewInit {
	@Output() closed = new EventEmitter<void>();

	versions: VersionsDto;
	refreshing = false;

	private _modalId = '#versionsDialog';
	private _apiSubscription: Subscription;

	constructor(private _versionsApiService: VersionsApiService, private _notificationsService: NotificationService) {}

	ngOnInit(): void {
		this.getVersionsFromApi();
	}

	ngAfterViewInit(): void {
		$(this._modalId).on('hide.bs.modal', () => this.closed.emit());
		$(this._modalId).modal('show');
	}

	ngOnDestroy(): void {
		this._apiSubscription?.unsubscribe();
	}

	refresh(): void {
		this.getVersionsFromApi(true);
	}

	closeModal(): void {
		$(this._modalId).modal('hide');
	}

	private getVersionsFromApi(force: boolean = false): void {
		this._apiSubscription?.unsubscribe();
		this._apiSubscription = null;
		this.refreshing = true;

		this._apiSubscription = this._versionsApiService.getVersions(force).subscribe(
			(result: VersionsDto) => {
				this.versions = result;
			},
			(error: HttpErrorResponse) => {
				switch (error.status) {
					case HttpStatusCode.Forbidden:
						this._notificationsService.showError(`The user doesn't have permission to read versions.`);
						break;
					default:
						this._notificationsService.showError(`Error while loading versions from API.`);
				}
			},
			() => (this.refreshing = false)
		);
	}
}
