import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { IMapMarkerDataV2 } from "@fitech-workspace/shared/ui/map-lib";
import { MachineMarker } from "./machine-marker";

export const MAP_MARKERS_FACTORY = new InjectionToken<IMapMarkerFactory>('map-markers-factory');

export interface IMapMarkerFactory {
    getMarker(data: any, type: string): IMapMarkerDataV2;
}


@Injectable({
    providedIn: 'root'
})
export class DefaultMapMarkerFactory implements IMapMarkerFactory {

    constructor() {
    };

    getMarker(data: any, type: string = null): IMapMarkerDataV2 {
        return new MachineMarker(data);
    }

}