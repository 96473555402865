import { QuestionBase } from './question-base';
import { EventEmitter } from '@angular/core';

export class DropdownQuestion extends QuestionBase<string> {
	icon?: string;
	private _options: { key: string; value: string; display?: string; icon?: string, type?: string }[] = [];
	controlType = 'dropdown';
	get options(): { key: string; value: string; display?: string; icon?: string, type?: string }[] {
		return this._options;
	}
	set options(value: { key: string; value: string; display?: string; icon?: string, type?: string }[]) {
		this._options = value;
		this.optionsHasChanged.emit(this._options);
	}
	optionsHasChanged: EventEmitter<any> = new EventEmitter();

	constructor(options: {} = {}) {
		super({
			value: options['value'],
			key: options['key'],
			label: options['label'],
			required: options['required'],
			disabled: options['disabled'],
			visible: options['visible'],
			order: options['order'],
			controlType: options['controlType'],
			validators: options['validators'],
			onChange: options['onChange'],
			showOnCloning: options['showOnCloning']
		});
		this.icon = options['icon'];
		this.options = options['options'] || [];
	}
}
