<div class="dialog">
	<div class="dialog-header">
		<div class="dialog-header__row">
			<div class="dialog-header__title">
				<h1 class="dialog-header__modal-title" id="statusChartLongTitle">
					{{ title }}
					<h4 class="dialog-header__subtitle">{{ subTitle }}</h4>
				</h1>
			</div>
		</div>
	</div>
	<div mat-dialog-content class="dialog-content">
		<div class="dialog-content__top sensors">
			<div class="sensors__line">
				<!-- machine status -->
				<fitech-workspace-hmi-indicator
					id="machine-status"
					class="sensors__machine-status for-wide-containers-1500"
					[state]="machineStatusState"
					[showButtons]="false"
					[showRawValue]="true">
				</fitech-workspace-hmi-indicator>
				<!-- last item -->
				<fitech-workspace-hmi-indicator
					id="last-item"
					class="sensors__last-item for-wide-containers-1500"
					[state]="lastItemState"
					[showButtons]="false">
				</fitech-workspace-hmi-indicator>
			</div>
			<div class="sensors__line">
				<!-- pcb counter -->
				<fitech-workspace-hmi-indicator
					id="pcb-counter"
					class="sensors__counter for-wide-containers-1500"
					[state]="pcbCounterState"
					[showButtons]="false"
					[showRawValue]="true">
				</fitech-workspace-hmi-indicator>
				<!-- element counter -->
				<fitech-workspace-hmi-indicator
					id="element-counter"
					class="sensors__counter for-wide-containers-1500"
					[state]="elementCounterState"
					[showButtons]="false"
					[showRawValue]="true">
				</fitech-workspace-hmi-indicator>
				<!-- global cycle counter -->
				<fitech-workspace-hmi-indicator
					id="global-cycle-counter"
					class="sensors__counter sensors__counter--mr-0 for-wide-containers-1500"
					[state]="globalCycleCounterState"
					[showButtons]="false"
					[showRawValue]="true">
				</fitech-workspace-hmi-indicator>
			</div>
		</div>
		<hr />
		<div class="dialog-content__buttons">
			<div>Alarms
				<button class="btn btn--icon-paddings btn--icon-secondary-hover" (click)="getAlarms()">
					<i class="fas fa-sync" [ngClass]="loadingAlarms ? 'fa-spin' : 'fa-refresh'" aria-hidden="true"></i>
				</button>
			</div>
			<div class="spacer"></div>
			<fitech-workspace-date-time-range-picker-v2
				#datePicker
				id="datePicker"
				[initialDateRange]="dateRange"
				(dateRangeChanged)="onDateRangeChanged($event)">
			</fitech-workspace-date-time-range-picker-v2>
		</div>
		<div class="dialog-content__grid">
			<fitech-workspace-grid
				#grid
				[gridId]="'alarms'"
				[rows]="machineAlarmsGridData"
				[columns]="gridColumns"
				[pagination]="false">
			</fitech-workspace-grid>
		</div>
	</div>
	<div mat-dialog-actionsc class="dialog__actions" align="end">
		<button class="btn btn--tertiary" (click)="onCancel()">{{ 'Cancel' | translate }}</button>
	</div>
</div>
