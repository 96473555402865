<div class="dynamic-input-form"
  [ngClass]="{'dynamic-edit-input': showUndoButton, 'touched':!form.controls[question.key].untouched && showUndoButton}"
  [formGroup]="form" (keydown.enter)="$event.preventDefault()">
  <mat-icon *ngIf="question.isGroupEditInconsistent" class="icon icon--group-inconsistency" aria-hidden="false"
    matTooltip="Property has different values in selected rows">
    double_arrow</mat-icon>
  <mat-checkbox color="primary" [formControlName]="question.key" [id]="question.key"
    [checked]="question.type === 'number' ? question.value === '1' : question.value">
    <mat-label>{{question.label | translate}}</mat-label>
  </mat-checkbox>
  <button *ngIf="form.controls[question.key].dirty && showUndoButton" class="btn-undo" mat-button mat-icon-button
    matTooltip="Undo changes" aria-label="Undo changes" class="btn-undo" (click)="undo()">
    <mat-icon>undo</mat-icon>
  </button>
</div>
