import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateUtils } from '@fitech-workspace/core-lib';
import { HeartbeatDto } from '../../reports/models/heartbeat-dto.model';
import { HeartbeatsApiService } from '../api/heartbeats-api.service';

@Injectable({
	providedIn: 'root',
})
export class HeartbeatsService {
	constructor(private _heartbeatsApi: HeartbeatsApiService) {}

	isMachineActive(machineName: string): Observable<boolean> {
		return this._heartbeatsApi.getHeartbeatByMachineName(machineName).pipe(
			map((res: HeartbeatDto) => {
				if (res) {
					const diffDatesInMinutes = DateUtils.getDiffDatesInMinutes(moment(), res.lastActivity);
					// machine activity frame comes every 10 minutes
					return diffDatesInMinutes > 0 && diffDatesInMinutes < 11;
				} else {
					return false;
				}
			})
		);
	}
}
