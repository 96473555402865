import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'fitech-workspace-labelled-button-renderer',
  templateUrl: './labelled-button-renderer.component.html',
  styleUrls: ['./labelled-button-renderer.component.scss']
})
export class LabelledButtonRendererComponent implements ICellRendererAngularComp {
  public params: any;
  data: ILabelledButtonData;

  refresh(params: any): boolean {
    return true;
  }

  agInit(params: any): void {
    this.data = params.data.getLabelledButtonData(params.colDef.field);
  }
  afterGuiAttached?(params?: any): void {
    this.params = params;
  }
}

export interface ILabelledButtonData {
  icon: string;
  tooltip: string;
  value: string;
  id: number;
}

export interface ILabelledButtonDataProvider {
  getLabelledButtonData(field: string): ILabelledButtonData;
}
