import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AUTH_CONFIG, AUTH_ENV_CONFIG, AuthLibModule, PermissionsMapService, User, UserService } from '@fitech-workspace/auth-lib';
import {
	CoreLibModule,
	HttpErrorsInterceptor,
	HttpLoaderFactory,
	TranslateLoader,
	TranslateModule,
	UserSettingsFacadeBaseService,
} from '@fitech-workspace/core-lib';
import { BACKEND_API_URL, BACKEND_CLIENT_TAG, FisenseCommonLibModule, MAP_MARKERS_FACTORY, iconsConfig } from '@fitech-workspace/fisense-common-lib';
import { AUDIT_API_URL, GraphQlModule, MASTER_DATA_QL_URL } from '@fitech-workspace/master-data-lib';
import { API_BASE_URL, ApiService, SharedMasterDataApiLibModule } from '@fitech-workspace/shared/data-access/master-data-api-lib';
import { ICONS_CONFIG, SharedUiIconsModule, SharedUiInputsMdModule } from '@fitech-workspace/shared/ui/controls-lib';
import { SharedUiDialogLibModule } from '@fitech-workspace/shared/ui/dialog-lib';
import { SharedUiLayoutLibModule } from '@fitech-workspace/shared/ui/layout-lib';
import { ENV_CONFIG } from '@fitech-workspace/shared/ui/map-lib';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { authConfig } from './auth.config';
import { CmMapMarkerFactoryService } from './monitoring/services/cm-map-marker-factory.service';
import { CM_BACKEND_API_URL } from './shared/api/cm-backend-api.service';
import { DATA_SCIENCE_API, DATA_SCIENCE_API_HOSTMASTERKEY } from './shared/api/cm-backend-ds-api.service';
import { CmPermissionsMapService } from './shared/services/cm-permissions-map.service';
import { ErrorHandlingEffects } from './shared/state/effects/error-handling.effects';
import { UserSettingsEffects } from './shared/state/effects/user-settings.effects';
import { UserFacadeService } from './shared/state/facades/user-facade.service';
import { UserSettingsFacadeService } from './shared/state/facades/user-settings-facade.service';
import { resetAppStateMetaReducer } from './shared/state/meta-reducers/reset-state.meta-reducer';
import { reducers } from './shared/state/reducers/reducers';
import { environment } from '../environments/environment';
import 'reflect-metadata';
import './shared/prototypes';
// eslint-disable-next-line import/order
import { SharedModule } from './shared/shared.module';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		HttpCacheInterceptorModule.forRoot(),
		AppRoutingModule,
		AuthLibModule,
		CoreLibModule.forRoot(),
		SharedMasterDataApiLibModule,
		SharedUiDialogLibModule,
		SharedUiIconsModule,
		SharedUiInputsMdModule,
		SharedUiLayoutLibModule,
		FisenseCommonLibModule,
		GraphQlModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),

		StoreModule.forRoot(reducers, {
			metaReducers: [resetAppStateMetaReducer],
			runtimeChecks: {
				strictActionImmutability: true,
				strictStateImmutability: true,
			},
		}),
		EffectsModule.forRoot([UserSettingsEffects, ErrorHandlingEffects]),
		StoreDevtoolsModule.instrument({
			logOnly: !environment.production,
		}),
		StoreRouterConnectingModule.forRoot({
			stateKey: 'routerState',
		}),
		SharedModule,
	],
	exports: [],
	bootstrap: [AppComponent],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptor, multi: true },
		{ provide: BACKEND_API_URL, useValue: environment.apiUrl },
		{ provide: BACKEND_CLIENT_TAG, useValue: 'fisense' },

		{ provide: DATA_SCIENCE_API, useValue: environment.dataScienceApiUrl },
		{ provide: DATA_SCIENCE_API_HOSTMASTERKEY, useValue: environment.dataScienceApiHostMasterKey },

		ApiService,
		{ provide: API_BASE_URL, useValue: environment.masterDataApiUrl },
		{ provide: AUTH_CONFIG, useValue: authConfig },
		{ provide: AUTH_ENV_CONFIG, useValue: environment },
		{ provide: ENV_CONFIG, useValue: environment },
		{ provide: ICONS_CONFIG, useValue: iconsConfig },

		{ provide: AUDIT_API_URL, useValue: environment.masterDataApiUrl },

		//new
		{ provide: MASTER_DATA_QL_URL, useValue: environment.masterDataApiUrl },
		{ provide: CM_BACKEND_API_URL, useValue: `${environment.apiUrl}${environment.apiVersion}/` },

		//machine
		{ provide: MAP_MARKERS_FACTORY, useClass: CmMapMarkerFactoryService },

		{ provide: PermissionsMapService, useClass: CmPermissionsMapService },

		//facades
		{ provide: UserSettingsFacadeBaseService, useExisting: UserSettingsFacadeService },
		UserFacadeService,
	],
})
export class AppModule {
	constructor(private _userService: UserService) {
		this._userService.currentUserChanged.subscribe((user: User) => {
			if (!user) {
				return;
			}
		});
	}
}
