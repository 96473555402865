//@ts-ignore

import { State } from './state.model';
import { AsEnumerable } from 'linq-es2015';
import { Sensor } from './sensor.model';
import { Machine } from './machine.model';
import { AlarmLifetimeThresholdEnum } from './alarm-lifetime-threshold.enum';
import { CommonGlobals } from './common-globals';

export class MachineState extends State {
  sensorsStates: State[] = [];

  constructor(data: any) {
    super();

    if (data instanceof Sensor) {
      this.sourceId = data.machineId;
      this.source = new Machine({
        name: data.machineName,
        id: data.machineId,
        zoneName: data.zoneName,
        positionX: data.machinePositionX,
        positionY: data.machinePositionY,
        units: '',
        sensorsGroupType: data.sensorsGroupType
      })
    }
  }

  get defaultSensorState(): State {
    const defaultSensor = AsEnumerable(this.sensorsStates).FirstOrDefault(state => {
      if (state.source instanceof Sensor)
        return state.source.isMachineDefaultSensor === true
      return false
    });
    return defaultSensor ? defaultSensor : null;
  }


  //overriding with premeditation
  get value() {
    return this.defaultSensorState ? this.defaultSensorState.value : null;
  }
  //overriding with premeditation
  get timestamp() {
    return this.defaultSensorState ? this.defaultSensorState.timestamp : null;
  }
  //overriding with premeditation
  get alarmIsActive() {
    return this.defaultSensorState ? this.defaultSensorState.alarmIsActive : false;
  }

  get alarmIsLive() {
    const alarmLastState: string = this.alarm ? this.alarm.lastState.state : null;
    let result = false;

    if (this.alarmIsActive
      || (CommonGlobals.alarmLifetimeThreshold === AlarmLifetimeThresholdEnum.UntilUserAcknowledged && alarmLastState && alarmLastState !== 'Acknowledged' && !alarmLastState.startsWith('Verified'))
      || (CommonGlobals.alarmLifetimeThreshold === AlarmLifetimeThresholdEnum.UntilUserValidatedAsRealFalse && alarmLastState && !alarmLastState.startsWith('Verified'))
    ) {
      result = true;
    }
    return result;

  }

  get alarmGuid() {
    return this.alarm ? this.alarm.guid : null;
  }

  get alarmType() {
    return this.alarm ? this.alarm.type : null;
  }

  static getSensors(currentStates: State[]): any[] {
    const sensors: any[] = [];
    currentStates.forEach(x => {
      if (x instanceof MachineState) {
        x.sensorsStates.forEach(y => {
          if (y.source) {
            sensors.push(y.source);
          }
        })
      }
    });
    return sensors;
  }

  addSensorsStates(state: State[]) {
    this.sensorsStates = [...this.sensorsStates, ...state];
  }

}
