import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalUserSettingDto, HttpParamsBuilder, UserSettingDto } from '@fitech-workspace/core-lib';
import { CM_BACKEND_API_URL } from '../../shared/api/cm-backend-api.service';
import { EntitiesCommandRequestResult, EntityCommandRequestResult, UpsertBatchUserSettingsCmd } from '../models';

@Injectable({
	providedIn: 'root',
})
export class UserSettingsApiService {
	private get _mainUrl(): string {
		return `${this._apiUrl}user-settings`;
	}

	constructor(private _httpClient: HttpClient, @Inject(CM_BACKEND_API_URL) private _apiUrl: string) {}

	getUserSettings(view?: string, section?: string, element?: string, settingTag?: string, skipGlobal: boolean = true): Observable<UserSettingDto[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create()
			.appendString('view', view)
			.appendString('section', section)
			.appendString('element', element)
			.appendString('settingTag', settingTag)
			.append('skipGlobal', skipGlobal, false);
		return this._httpClient.get<UserSettingDto[]>(`${this._mainUrl}`, { params: httpParamsBuilder.build() });
	}

	getGlobalUserSettings(userSettingTag?: string): Observable<GlobalUserSettingDto[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create().appendString('userSettingTag', userSettingTag);
		return this._httpClient.get<GlobalUserSettingDto[]>(`${this._mainUrl}/global`, { params: httpParamsBuilder.build() });
	}

	getUserSetting(userSettingId: number): Observable<UserSettingDto> {
		return this._httpClient.get<UserSettingDto>(`${this._mainUrl}/${userSettingId}`);
	}

	getBatchUserSettings(userSettingIds: number[]): Observable<UserSettingDto[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create().appendArray('userSettingIds', userSettingIds, false);
		return this._httpClient.get<UserSettingDto[]>(`${this._mainUrl}/batch`, { params: httpParamsBuilder.build() });
	}

	createGlobalUserSetting(globalUserSetting: GlobalUserSettingDto): Observable<EntityCommandRequestResult> {
		return this._httpClient.post<EntityCommandRequestResult>(`${this._mainUrl}/global`, globalUserSetting);
	}

	updateGlobalUserSetting(globalUserSetting: GlobalUserSettingDto): Observable<void> {
		return this._httpClient.put<void>(`${this._mainUrl}/global/${globalUserSetting.id}`, globalUserSetting);
	}

	upsertBatchUserSettings(command: UpsertBatchUserSettingsCmd): Observable<EntitiesCommandRequestResult> {
		return this._httpClient.post<EntitiesCommandRequestResult>(`${this._mainUrl}/batch`, command);
	}

	deleteUserSettingById(userSettingId: number): Observable<void> {
		return this._httpClient.delete<void>(`${this._mainUrl}/${userSettingId}`);
	}

	deleteUserSettingByName(userSettingName: string, view?: string, section?: string, element?: string): Observable<void> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create()
			.appendString('userSettingName', userSettingName)
			.appendString('view', view)
			.appendString('section', section)
			.appendString('element', element);
		return this._httpClient.delete<void>(`${this._mainUrl}`, { params: httpParamsBuilder.build() });
	}

	deleteBatchUserSettings(userSettingIds: number[]): Observable<void> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create().appendArray('userSettingIds', userSettingIds);
		return this._httpClient.delete<void>(`${this._mainUrl}/batch`, { params: httpParamsBuilder.build() });
	}

	deleteAllUserSettings(): Observable<void> {
		return this._httpClient.delete<void>(`${this._mainUrl}/reset`);
	}
}
