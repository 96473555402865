import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorHandlingService, NotificationService } from '@fitech-workspace/core-lib';
import { ApiService, ResetPasswordReq } from '@fitech-workspace/shared/data-access/master-data-api-lib';
import { AUTH_ENV_CONFIG, UserService } from '../../services/user.service';

@Component({
	selector: 'fitech-workspace-password-reset',
	templateUrl: './password-reset.component.html',
	styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
	@Input() logoSrc: string;

	isInProgress: boolean;
	form: UntypedFormGroup;
	currentPasswordControl = new UntypedFormControl();
	newPasswordControl = new UntypedFormControl();
	confirmPasswordControl = new UntypedFormControl();
	isSubmitting = false;
	isSubmitted = false;

	private _environmentConfig;

	constructor(
		private _notificationService: NotificationService,
		private _formBuilder: UntypedFormBuilder,
		private _userService: UserService,
		private _apiClient: ApiService,
		private _errorHandlingService: ErrorHandlingService,
		@Inject(AUTH_ENV_CONFIG) environmentConfig: any,
	) {
		this._environmentConfig = environmentConfig;
		if (this._environmentConfig) {
			this.logoSrc = this._environmentConfig.logoSrc;
		}
	}

	ngOnInit() {
		this.createFormGroups(this._formBuilder);
	}

	createFormGroups(fb: UntypedFormBuilder) {
		this.form = fb.group(
			{
				currentPassword: this.currentPasswordControl,
				newPassword: this.newPasswordControl,
				confirmPassword: this.confirmPasswordControl,
			},
			{ validator: this.checkPasswords },
		);

		this.currentPasswordControl.setValidators(Validators.required);
		this.newPasswordControl.setValidators([Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,100}')]);
	}

	checkPasswords(form: UntypedFormGroup) {
		const pass = form.controls.newPassword.value;
		const confirmPass = form.controls.confirmPassword.value;

		let result = null;
		if (pass !== confirmPass) {
			form.controls.confirmPassword.setErrors({ notSamePassword: true });
			result = { notSamePassword: true };
		}
		return result;
	}

	shouldDisableAction() {
		return (
			this.isSubmitting ||
			this.isSubmitted ||
			this.currentPasswordControl.invalid ||
			this.newPasswordControl.invalid ||
			this.confirmPasswordControl.invalid
		);
	}

	resetPassword() {
		this.isSubmitting = true;
		this._apiClient.resetPassword(this.createResetPasswordReq()).subscribe(
			(res) => {
				this.isSubmitting = false;
				this.isSubmitted = true;
				this._notificationService.showSuccess('Password was reset.');
			},
			(error: HttpErrorResponse) => {
				this.isSubmitting = false;
				this._errorHandlingService.handleError(error, 'Password was not reset. Please check does current password is correct.');
			}
		);
	}

	createResetPasswordReq(): ResetPasswordReq {
		const resetPasswordReq: ResetPasswordReq = {
			userId: this._userService.currentUser.id,
			oldPassword: this.currentPasswordControl.value,
			newPassword: this.newPasswordControl.value,
			confirmPassword: this.confirmPasswordControl.value,
		};
		return resetPasswordReq;
	}

	keyDownFunction(event) {
		if (event.keyCode === 13) {
			this.resetPassword();
		}
	}

	isValid(key) {
		return this.form.controls[key].valid;
	}

	isTouched(key) {
		return this.form.controls[key].touched;
	}

	getErrorMsg(key) {
		let errorMsg = '';
		if (this.isTouched(key)) {
			if (this.form.controls[key].hasError('required')) {
				errorMsg += 'Field is required. ';
			}
			if (this.form.controls[key].hasError('email')) {
				errorMsg += 'Field does not meet e-mail pattern requirement. ';
			}
			if (this.form.controls[key].hasError('pattern')) {
				errorMsg += 'The password must be at least 6 characters long. Must have lower and upper case and digit.';
			}
			if (key === 'confirmPassword' && this.form.controls[key].parent.hasError('notSamePassword')) {
				errorMsg += 'The password and confirmation password do not match. ';
			}
		}
		return errorMsg;
	}
}
