import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
	selector: 'fitech-workspace-checkbox-renderer',
	templateUrl: './checkbox-renderer.component.html',
	styleUrls: ['./checkbox-renderer.component.scss'],
})
export class CheckboxRendererComponent implements ICellRendererAngularComp {
	params: any;
	get checked(): boolean {
		return this.params.value && (this.params.value === 'true' || this.params.value === true);
	}

	refresh(): boolean {
		return true;
	}

	agInit(params: any): void {
		this.params = params;
	}

	afterGuiAttached?(params?: any): void {
		this.params = params;
	}
}
