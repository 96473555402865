import { Component, Input, Output, EventEmitter } from '@angular/core';
import { State } from '../../models/state.model';

@Component({
  selector: 'fitech-workspace-hmi-indicator',
  templateUrl: './hmi-indicator.component.html',
  styleUrls: ['./hmi-indicator.component.scss']
})
export class HmiIndicatorComponent {

  @Input() state: State;
  @Input() showButtons = true;
  @Input() showRawValue = false;
  @Input() isAddedToReport: boolean;

  @Output() onChartToggle: EventEmitter<State> = new EventEmitter();

  constructor() { }

  toggleOnChart(): void {
    this.onChartToggle.emit(this.state);
  }

}
