import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		MatToolbarModule,
		MatButtonModule,
		MatCardModule,
		MatInputModule,
		MatDialogModule,
		MatTableModule,
		MatMenuModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatPaginatorModule,
		MatSortModule,
		MatSelectModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatRippleModule,
		MatExpansionModule,
		MatSlideToggleModule,
		MatChipsModule,
		MatBadgeModule,
		MatButtonToggleModule,
		MatTooltipModule,
		MatSnackBarModule,
		MatSidenavModule,
		MatStepperModule,
		MatAutocompleteModule,
		MatTabsModule,
		MatDividerModule,
		MatListModule,
		MatAutocompleteModule,
		MatFormFieldModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatMomentDateModule,
		MatTreeModule,
	],
	exports: [
		CommonModule,
		MatToolbarModule,
		MatButtonModule,
		MatCardModule,
		MatInputModule,
		MatDialogModule,
		MatTableModule,
		MatMenuModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatPaginatorModule,
		MatSortModule,
		MatSelectModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatRippleModule,
		MatExpansionModule,
		MatSlideToggleModule,
		MatChipsModule,
		MatBadgeModule,
		MatButtonToggleModule,
		MatTooltipModule,
		MatSnackBarModule,
		MatSidenavModule,
		MatStepperModule,
		MatAutocompleteModule,
		MatTabsModule,
		MatDividerModule,
		MatListModule,
		MatAutocompleteModule,
		MatFormFieldModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatMomentDateModule,
		MatTreeModule,
	],
})
export class CustomMaterialModule {}
