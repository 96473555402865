import { Injectable, InjectionToken, Inject } from '@angular/core';
import { Icon } from '../models/icon.model';
import { AsEnumerable } from 'linq-es2015';
export const ICONS_CONFIG = new InjectionToken<Icon[]>('ICONS_CONFIG');

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  iconsConfig: Icon[];
  constructor(
    @Inject(ICONS_CONFIG) iconsConfig: any) {
    this.iconsConfig = iconsConfig;
  }

  getIcon(key: string) {
    return AsEnumerable(this.iconsConfig)
      .Where(x => x.key === key).FirstOrDefault();
  }

}
