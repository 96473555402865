import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { UserService } from '@fitech-workspace/auth-lib';
import { AsEnumerable } from 'linq-es2015';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const DATA_SCIENCE_API = new InjectionToken<string>('DATA_SCIENCE_API');
export const DATA_SCIENCE_API_HOSTMASTERKEY = new InjectionToken<string>('DATA_SCIENCE_API_HOSTMASTERKEY');

@Injectable({
	providedIn: 'root',
})
export class CmBackendApiForDataScienceService {
	protected machineStatesApiUrl: string;
	protected machineStatesHostMasterKey: string;
	constructor(
		private userService: UserService,
		protected httpClient: HttpClient,
		@Optional()
		@Inject(DATA_SCIENCE_API)
		machineStatesApiUrl?: string,
		@Optional()
		@Inject(DATA_SCIENCE_API_HOSTMASTERKEY)
		machineStatesHostMasterKey?: string
	) {
		this.machineStatesApiUrl = machineStatesApiUrl;
		this.machineStatesHostMasterKey = machineStatesHostMasterKey;
	}

	public getMachineStateHistory(
		from: Date,
		to: Date,
		clientIds: number[],
		machineId?: number,
		sensorId?: number,
		performanceSetpointPerHour?: number,
		minimalDowntimeStateDurationInSeconds?: number
	): Observable<any[]> {
		const params: any = {
			from: moment(from).toISOString(),
			to: moment(to).toISOString(),
			code: this.machineStatesHostMasterKey,
		};

		if (machineId != null) {
			params.machineId = machineId;
		}
		if (sensorId != null) {
			params.sensorId = sensorId;
		}
		if (clientIds != null) {
			params.clientIds = clientIds;
		} else {
			if (this.userService.currentUser.userAllowedClientsWithChildClients != null) {
				params.clientIds = AsEnumerable(this.userService.currentUser.userAllowedClientsWithChildClients)
					.Select((x) => x.clientId)
					.ToArray();
			}
		}

		if (performanceSetpointPerHour != null) {
			params.performanceSetpointPerHour = performanceSetpointPerHour;
		}

		if (minimalDowntimeStateDurationInSeconds != null) {
			params.minimalDowntimeStateDurationInSeconds = minimalDowntimeStateDurationInSeconds;
		}

		return this.httpClient.get<any[]>(this.machineStatesApiUrl + 'GetMachineStates', { params }).pipe(
			map(
				(res) => res,
				(err) => console.log(err)
			)
		);
	}
}
