export {};

declare global {
	interface Array<T> {
		/**
		 * Computes the sum of the sequence of decimal values that are obtained by
		 * invoking a transform function on each element of the input sequence.
		 * @param selector A transform function to apply to each element.
		 * @example
		 *     const total = array.sum(x => x.value);
		 */
		sum(selector: (item: T) => number): number;

		/**
		 * Returns the number of elements in a sequence.
		 * Returns a number that represents how many elements in the
		 * specified sequence satisfy a condition.
		 * @param predicate A function to test each element for a condition.
		 * @example
		 *     const value = [1, 2, 3, 4, 5, 6, 7].count((a: number) => a > 3)
		 */
		count(predicate?: (item: T) => boolean): number;

		/**
		 * Returns distnict array of objects by defined key.
		 * @param selector A selector function to get key.
		 * @example
		 *     const distincted = array.distinct(x => x.name);
		 */
		distinct(selector: (item: T) => string | number): T[];

		/**
		 * Returns first element that matches optional predication or null.
		 * @param predicate A function to test each element for a condition.
		 */
		firstOrDefault(predicate?: (item: T) => boolean): T;
	}
}

if (!Array.prototype.sum) {
	Array.prototype.sum = function <T>(this: T[], selector: (item: T) => number): number {
		return this.reduce((accumulator: number, currentValue: T) => {
			return currentValue ? accumulator + selector(currentValue) : accumulator;
		}, 0);
	};
}

if (!Array.prototype.count) {
	Array.prototype.count = function <T>(this: T[], predicate?: (item: T) => boolean): number {
		if (typeof predicate === 'undefined') {
			return this.length;
		}
		return this.reduce((accumulator: number, currentValue: T) => {
			return predicate(currentValue) ? accumulator + 1 : accumulator;
		}, 0);
	};
}

if (!Array.prototype.distinct) {
	Array.prototype.distinct = function <T>(this: T[], selector: (item: T) => string | number): T[] {
		return this.filter((item: T, index: number, self: T[]): boolean => {
			const key: string | number = selector(item);
			return self.findIndex((selftItem: T): boolean => selector(selftItem) === key) === index;
		});
	};
}

if (!Array.prototype.firstOrDefault) {
	Array.prototype.firstOrDefault = function <T>(this: T[], predicate?: (item: T) => boolean): T {
		if (!this || !this.length) {
			return null;
		}
		if (typeof predicate === 'undefined' || predicate === null) {
			return this[0];
		}
		const filtered: T[] = this.filter(predicate);
		return filtered.length ? filtered[0] : null;
	};
}
