import { Machine } from './machine.model';
import { Sensor } from './sensor.model';
import * as moment from 'moment';
import { Alarm } from './alarm.model';
import { AsEnumerable } from 'linq-es2015';

export class State {
  private _value: any;
  public get value(): any {
    return this._value;
  }
  public set value(value: any) {
    this._value = value;
  }
  rawValue: any;
  min?: number;
  max?: number;
  sensorsGroupType?: string;
  private _timestamp: Date;
  public get timestamp(): Date {
    return this._timestamp;
  }
  public set timestamp(value: Date) {
    this._timestamp = value;
  }
  alarm?: Alarm;
  private _alarmIsActive?: boolean;
  public get alarmIsActive(): boolean {
    return this._alarmIsActive;
  }
  public set alarmIsActive(value: boolean) {
    this._alarmIsActive = value;
  }
  sourceId: number;
  source?: Sensor | Machine;
  isHistoryOn = false;

  constructor(data?: any) {
    if (data) {
      this.sourceId = data['sensorId'];
      this.value = data['value'] != null ? data['value'] : data['rawValue'];
      this.rawValue = data['rawValue'];
      this.timestamp = data['timestamp'];
      this.alarmIsActive = data['alarmIsActive'];
      this.alarm = data['alarm'] ? new Alarm(data['alarm']) : null;
      this.source = Sensor.createFromState(data);
    }
  }

  get timestampFromNow(): string { return moment(this.timestamp).fromNow() };

  get alarmGuid(): string {
    return this.alarm ? this.alarm.guid : null;
  }

  get alarmType(): string {
    return this.alarm ? this.alarm.type : null;
  }

  static getSensors(currentStates: State[]): any[] {
    const sensors: any[] = [];
    currentStates.forEach(x => {
      if (x.source) {
        sensors.push(x.source);
      }
    });
    return sensors;
  }
}
