export enum RolePermissionFrontendModuleEnum {
	Components = 'Permissions.Frontend.Components.',
	ComponentsFeeders = 'Permissions.Frontend.Components.Feeders.',
	ComponentsFingers = 'Permissions.Frontend.Components.Fingers.',
	ComponentsItems = 'Permissions.Frontend.Components.Items.',
	ComponentsTools = 'Permissions.Frontend.Components.Tools.',
	Management = 'Permissions.Frontend.Management.',
	ManagementClients = 'Permissions.Frontend.Management.Clients.',
	ManagementDevices = 'Permissions.Frontend.Management.Devices.',
	ManagementFloorplan = 'Permissions.Frontend.Management.Floorplan.',
	ManagementLines = 'Permissions.Frontend.Management.Lines.',
	ManagementLocations = 'Permissions.Frontend.Management.Locations.',
	ManagementMachineStates = 'Permissions.Frontend.Management.MachineStates.',
	ManagementNotifications = 'Permissions.Frontend.Management.Notifications.',
	ManagementOperationStatuses = 'Permissions.Frontend.Management.OperationStatuses.',
	ManagementRoles = 'Permissions.Frontend.Management.Roles.',
	ManagementSensors = 'Permissions.Frontend.Management.Sensors.',
	ManagementSensorsGroups = 'Permissions.Frontend.Management.SensorsGroups.',
	ManagementShifts = 'Permissions.Frontend.Management.Shifts.',
	ManagementUsers = 'Permissions.Frontend.Management.Users.',
	ManagementZones = 'Permissions.Frontend.Management.Zones.',
	Monitoring = 'Permissions.Frontend.Monitoring.',
	Recipes = 'Permissions.Frontend.Recipes.',
	RecipesActions = 'Permissions.Frontend.Recipes.Actions.',
	RecipesAlgorithms = 'Permissions.Frontend.Recipes.Algorithms.',
	RecipesGenerator = 'Permissions.Frontend.Recipes.Generator.',
	Reports = 'Permissions.Frontend.Reports.',
	Updates = 'Permissions.Frontend.Updates.',
}
