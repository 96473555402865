import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'fitech-workspace-button-refresh',
	templateUrl: './button-refresh.component.html',
	styleUrls: ['./button-refresh.component.scss'],
})
export class ButtonRefreshComponent {
	@Input() buttonId = 'refresh-button';
	@Input() refreshing = false;
	@Input() isVerticalPaddings = false;
	@Input() isSmaller = false;

	@Output() refreshed: EventEmitter<void> = new EventEmitter<void>();

	refresh(): void {
		this.refreshed.emit();
	}
}
