<button *ngIf="(user | async) == null" mat-button [routerLink]="['/login']">{{ 'Sign in' | translate }}</button>
<button *ngIf="(user | async) != null" mat-button [matMenuTriggerFor]="menuSignIn" (menuOpened)="menuOpened()">
	{{ (user | async)?.email }}
	<i class="fas fa-user"></i>
</button>
<mat-menu #menuSignIn="matMenu" [overlapTrigger]="false">
	<button *ngIf="(user | async)?.isActiveDirectoryUser == false" mat-menu-item routerLink="/passwordreset">
		{{ ('Reset password' | translate) || 'Reset password' }}
	</button>
	<button *ngFor="let option of customOptions; trackBy: trackByKey" mat-menu-item (click)="selectOption(option)">
		{{ (option.label | translate) || option.label }}
	</button>
	<button mat-menu-item (click)="logout()">{{ ('Logout' | translate) || 'Logout' }}</button>
</mat-menu>
