import { IChartConfig, EnableStatesEnum, LineChartConfigBuilder, LineChartComponent } from '@fitech-workspace/shared/ui/chart-lib';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'fitech-workspace-sensors-chart',
  templateUrl: './sensors-chart.component.html',
  styleUrls: ['./sensors-chart.component.scss']
})
export class SensorsChartComponent implements OnInit {

  @ViewChild("chart", { static: true }) lineChart: LineChartComponent;

  lineChartConfig: IChartConfig;

  ngOnInit(): void {
    this._initializeLineChart();
  }

  private _initializeLineChart(): void {
    this.lineChartConfig = {
      autoRefreshChartData: true,
      showFrequency: true,
      showStartStopRefreshData: true,
      minMaxSeries: EnableStatesEnum.EnabledAndSetOff,
      chartOptions: new LineChartConfigBuilder().setDefault().setCustom({ toolbox: { show: false } }).build()
    }

    this.lineChart.initialize(this.lineChartConfig);
  }
}
