<div class="dynamic-input-form "
    [ngClass]="{'dynamic-edit-input': showUndoButton, 'dirty': form.controls[question.key].dirty && showUndoButton && form.controls[question.key].valid }"
    [formGroup]="form" (keydown.enter)="$event.preventDefault()">
    <mat-icon *ngIf="question.isGroupEditInconsistent" class="icon icon--group-inconsistency" aria-hidden="false"
        matTooltip="Property has different values in selected rows">
        double_arrow</mat-icon>
    <mat-form-field appearance="standard" class="dynamic-input">
        <mat-label>{{question.label | translate}}</mat-label>
        <input matInput readonly [value]="color" [style.background]="color" [formControlName]="question.key" [id]="question.key"
            [cpAlphaChannel]="'always'" [cpOutputFormat]="'rgba'" [cpOKButton]="true" [cpSaveClickOutside]="false"
            [cpOKButtonClass]="'btn btn--primary btn-xs'" [(colorPicker)]="color"
            (colorPickerSelect)="onChangedColor($event)" />
    </mat-form-field>
    <button *ngIf="form.controls[question.key].dirty && showUndoButton" mat-button mat-icon-button
        matTooltip="Undo changes" aria-label="Undo changes" class="btn-undo" (click)="undo()">
        <mat-icon>undo</mat-icon>
    </button>
</div>
