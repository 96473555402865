import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { delayWhen, tap, retryWhen } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { Globals } from '../../../../../apps/machines-app/src/app/globals';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private notificationService: NotificationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // normal GET method, can be retry once
    // POST is not being retried
    let retriesLeft = 1;
    let delayInMs = 700;

    Globals.internetConnectionError = false;

    // user validation request (ends with openid-configuration) always runs first
    // and if azure have some delay (webapp cold start) we need to wait longer and retry 3x
    if (request.url.endsWith('openid-configuration')) {
      retriesLeft = 3;
      delayInMs = 5000;
    }

    return next.handle(request).pipe(
      retryWhen(errors => {
        return errors.pipe(
          tap(error => {

            // retry only GET methods
            if (request.method !== 'GET') {
              retriesLeft = 0;
            }

            if (!navigator.onLine || error.statusText === 'Unknown Error') {
              this.notificationService.showError('No Internet Connection', true);
              Globals.internetConnectionError = true;
            }

            if (error instanceof HttpErrorResponse) {
              if (error.status === 401) {
                retriesLeft = 0; // do not retry
                this.notificationService.showError('Session expired. Please login again.', true)
                this.router.navigateByUrl('/login');
              }
              if (error.status === 500 && typeof error.error === 'string' && error.error.includes('Execution Timeout Expired')) {
                this.notificationService.showError('Execution Timeout Expired');
              }
              if (error.status === 403) {
                retriesLeft = 0; // do not retry
                // this._notificationService.handleError('Access forbidden');
              } else {
                retriesLeft--; // retry
                if (retriesLeft <= 0) {
                  // this._notificationService.handleHttpError(error);
                }
              }
            }

            // every retries sequence ends with error
            if (retriesLeft <= 0) {
              throw error;
            }
          }),
          // retry delay
          delayWhen(() => timer(delayInMs))
        )
      })
    );

  }

}
