import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { AsEnumerable } from 'linq-es2015';

@Component({
  selector: 'fitech-workspace-chips-renderer',
  templateUrl: './chips-renderer.component.html',
  styleUrls: ['./chips-renderer.component.scss']
})

export class ChipsRendererComponent implements ICellRendererAngularComp {
  public params: any;
  data: IChipsData[] = [];

  refresh(params: any): boolean {
    return true;
  }

  agInit(params: any): void {
    var data: any[] = [];

    if (typeof (params.data[params.colDef.field]) === 'string') {
      data = this.splitStringToChipsData(params);
    } else {
      if (params.data.getChips)
        data = params.data.getChips(params.colDef.field, params.cssClass);
    }

    if (params.sortBy) {
      data = AsEnumerable(data).OrderBy(x => x[params.sortBy]).ToArray();
      if (params.sortBy && params.sortThanBy) {
        data = AsEnumerable(data).OrderBy(x => x[params.sortBy]).ThenBy(x => x[params.sortThanBy]).ToArray();
      }
    }
    if (params.sortByDesc) {
      data = AsEnumerable(data).OrderByDescending(x => x[params.sortByDesc]).ToArray();
      if (params.sortByDesc && params.sortThanBy) {
        data = AsEnumerable(data).OrderByDescending(x => x[params.sortByDesc]).ThenBy(x => x[params.sortThanBy]).ToArray();
      }
      if (params.sortByDesc && params.sortThanByDesc) {
        data = AsEnumerable(data).OrderByDescending(x => x[params.sortByDesc]).ThenByDescending(x => x[params.sortThanByDesc]).ToArray();
      }
    }

    this.data = data;
  }

  private splitStringToChipsData(params: any) {
    const data = [];
    if (params.data[params.colDef.field] === "")
      return [];

    params.data[params.colDef.field].split(',').forEach(x => {
      data.push({
        cssClass: null,
        value: x
      });
    });
    return data;
  }

  afterGuiAttached?(params?: any): void {
    this.params = params;
  }
}
export interface IChipsData {
  cssClass: string;
  value: string;
}
export interface ChipsDataProvider {
  getChips(field: string, cssClass: string): IChipsData[];
}