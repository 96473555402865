import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ScreenSizeService } from '@fitech-workspace/core-lib';
import { IDateRange } from '@fitech-workspace/shared/ui/controls-lib';
import { GridBaseComponent, SparklineRendererComponent } from '@fitech-workspace/shared/ui/map-lib';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { getAlarmsColumnDefs, getRowColorByDate } from './grids/machine-alarms-grid-columns';
import { getCurrentMachineStatesColumnDefs } from './grids/machine-state-grid-columns';
import { Alarm } from '../../../models/alarm.model';
import { Sensor } from '../../../models/sensor.model';
import { State } from '../../../models/state.model';
import { MachineSensorsHmiListComponent } from '../machine-sensors-hmi-list/machine-sensors-hmi-list.component';
import { ISensorAddedWithAlarm } from '../models/machine-components.model';

@Component({
	selector: 'fitech-workspace-machine-sensors-list',
	templateUrl: './machine-sensors-list.component.html',
	styleUrls: ['./machine-sensors-list.component.scss'],
})
export class MachineSensorsListComponent implements OnInit, OnDestroy {
	@Input() machineAlarmsGridData: Alarm[] = [];
	@Input() currentMachineSensorStatesGridData: State[] = [];
	@Input() loading = false;
	@Input() trendSensorIds: number[] = [];

	@Output() sensorAdded = new EventEmitter<Sensor>();
	@Output() sensorRemoved = new EventEmitter<Sensor>();
	@Output() sensorAddedWithAlarm = new EventEmitter<ISensorAddedWithAlarm>();
	@Output() currentStateChange = new EventEmitter<boolean>();
	@Output() dateRangeChange = new EventEmitter<IDateRange>();

	@ViewChild('currentMachineStatesGrid') private _currentMachinesStatesGrid: GridBaseComponent;
	@ViewChild('machineAlarmsGrid', { static: true }) private _machineAlarmsGrid: GridBaseComponent;
	@ViewChild('machineSensorsListHmi') private _machineSensorsListHmi: MachineSensorsHmiListComponent;

	alarmsColDefs: any;
	currentStateColDefs: any;
	onResizeSub: Subscription;
	isCurrentStateActive = true;

	get useHmiSensorList(): boolean {
		return true;
	}

	constructor(private _screenSizeService: ScreenSizeService) {}

	ngOnInit(): void {
		this.currentStateColDefs = getCurrentMachineStatesColumnDefs(this._screenSizeService.size);
		this.alarmsColDefs = getAlarmsColumnDefs(this._screenSizeService.size);

		this._machineAlarmsGrid.gridOptions.getRowStyle = getRowColorByDate;

		this.onResizeSub = this._screenSizeService.onResize$.pipe(delay(0)).subscribe((x) => {
			this.currentStateColDefs = getCurrentMachineStatesColumnDefs(this._screenSizeService.size);
			this.alarmsColDefs = getAlarmsColumnDefs(this._screenSizeService.size);
		});
	}

	onGridRowClicked(params: any) {
		const target = params.event.target || params.event.srcElement;
		if (target.className.includes('grid-action-element')) {
			if (target.className.includes('add-machine-sensor')) {
				if (target.className.includes('disabled')) {
					return;
				}
				this.sensorAdded.emit(params.data.source);
				return;
			}
			if (target.className.includes('remove-machine-sensor')) {
				if (target.className.includes('disabled')) {
					return;
				}
				this.sensorRemoved.emit(params.data.source);
				return;
			}
			if (target.className.includes('chart-alarm')) {
				if (target.className.includes('disabled')) {
					return;
				}
				this.sensorAddedWithAlarm.emit(this.addSensorWithAlarmOptions(params));
				return;
			}
		}
	}

	onSensorAdded(sensor: Sensor) {
		this.sensorAdded.emit(sensor);
	}

	onSensorRemoved(sensor: Sensor) {
		this.sensorRemoved.emit(sensor);
	}

	onQuickFilterChanged(filterValue: string, gridName: string) {
		const grid = gridName === 'currentMachineStatesGrid' ? this._currentMachinesStatesGrid : this._machineAlarmsGrid;

		if (filterValue === '' || filterValue == null) {
			grid.clearQuickFilter();
		} else {
			grid.applyQuickFilter(filterValue);
		}
	}

	onDateRangeChanged(event: IDateRange) {
		this.dateRangeChange.emit(event);
	}

	onCurrentStatesClicked() {
		this.isCurrentStateActive = true;
		this.currentStateChange.emit(this.isCurrentStateActive);
	}

	onAlarmsClicked() {
		this.isCurrentStateActive = false;
		this.currentStateChange.emit(this.isCurrentStateActive);
	}

	addSensorWithAlarmOptions(params: any): ISensorAddedWithAlarm {
		const from = params.data.from;
		const to = params.data.to;
		const dateRange: IDateRange = {
			from: moment(from).add(-5, 'minutes').toDate(),
			to: to ? moment(to).add(5, 'minutes').toDate() : moment().toDate(),
			type: 'c',
		};
		const alarm: Alarm = params.data;
		return { alarm, dateRange };
	}

	refresh() {
		if (this._currentMachinesStatesGrid) {
			this._currentMachinesStatesGrid.refresh();
		}
		if (this._machineAlarmsGrid) {
			this._machineAlarmsGrid.refresh();
		}
		if (this._machineSensorsListHmi) {
			this._machineSensorsListHmi.refresh();
		}
	}

	ngOnDestroy() {
		if (this.onResizeSub) {
			this.onResizeSub.unsubscribe();
		}
	}
}
