import { ISensorAddedWithAlarm } from '../../models/machine-components.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LineChartComponent, IChartSeriesConfig, LineChartConfigBuilder, EnableStatesEnum, IChartConfig, IMarkAreasConfig, IMarkAreaData, ChartSeriesTypeEnum } from '@fitech-workspace/shared/ui/chart-lib';
import { AsEnumerable } from 'linq-es2015';
import { map } from 'rxjs/operators';
import { IDateRange } from '@fitech-workspace/shared/ui/controls-lib';
import { BackendApiService } from '../../../../api/backend-api.service';
import { Sensor } from '../../../../models/sensor.model';

@Component({
  selector: 'fitech-workspace-sensors-chart-with-alarms',
  templateUrl: './sensors-chart-with-alarms.component.html',
  styleUrls: ['./sensors-chart-with-alarms.component.scss']
})
export class SensorsChartWithAlarmsComponent implements OnInit {
  @ViewChild("chart", { static: true }) lineChart: LineChartComponent;

  lineChartConfig: IChartConfig;
  dateRange: IDateRange;
  constructor(
    private apiService: BackendApiService
  ) { }

  ngOnInit() {
    this._initializeLineChart();
  }

  private _initializeLineChart() {
    this.lineChartConfig = {
      autoRefreshChartData: true,
      showFrequency: true,
      showStartStopRefreshData: true,
      minMaxSeries: EnableStatesEnum.EnabledAndSetOff,
      chartOptions: new LineChartConfigBuilder().setDefault().build()
    }

    this.lineChart.initialize(this.lineChartConfig);
  }

  alarmFunc(from: Date, to: Date, sensorId: number) {

    return this.apiService.getAlarmsThresholds(from, to, null, sensorId);
  }

  addSeriesWithAlarm(options: ISensorAddedWithAlarm) {
    const seriesConfigs = [];
    const alarm = options.alarm;
    this.dateRange = options.dateRange;

    this.lineChart.clearData();

    const dataSeries: IChartSeriesConfig = {
      name: alarm.sensorName,
      type: ChartSeriesTypeEnum.data,
      units: alarm.sensorUnits,
      yAxisMinMax: { min: alarm.sensorMinValue, max: alarm.sensorMaxValue },
      getDataFunc: (from: Date, to: Date, frequency?: number, data?: any) => {
        return this.apiService.getStateHistory(from, to, null, alarm.sensorId, frequency)
      }
    }
    seriesConfigs.push(dataSeries);

    const alarmThresholdHigh: IChartSeriesConfig = {
      name: "Alarm high",
      type: ChartSeriesTypeEnum.alarmThresholdSolid,
      units: alarm.sensorUnits,
      getDataFunc: (from: Date, to: Date, frequency?: number, data?: any) => this.alarmFunc(from, to, alarm.sensorId).pipe(map(x => AsEnumerable(x.high).Where(y => !!y.value).ToArray()))
    };
    seriesConfigs.push(alarmThresholdHigh);
    const alarmThresholdHighHist: IChartSeriesConfig = {
      name: "Alarm high hysteresis",
      type: ChartSeriesTypeEnum.alarmThresholdDotted,
      units: alarm.sensorUnits,
      getDataFunc: (from: Date, to: Date, frequency?: number, data?: any) => this.alarmFunc(from, to, alarm.sensorId).pipe(map(x => AsEnumerable(x.highHysteresis).Where(y => !!y.value).ToArray()))
    };
    seriesConfigs.push(alarmThresholdHighHist);

    // alarm threshold series LOW
    const alarmThresholdLow: IChartSeriesConfig = {
      name: "Alarm low",
      type: ChartSeriesTypeEnum.alarmThresholdSolid,
      units: alarm.sensorUnits,
      getDataFunc: (from: Date, to: Date, frequency?: number, data?: any) => this.alarmFunc(from, to, alarm.sensorId).pipe(map(x => AsEnumerable(x.low).Where(y => !!y.value).ToArray()))
    };
    seriesConfigs.push(alarmThresholdLow);
    const alarmThresholdLowHist: IChartSeriesConfig = {
      name: "Alarm low hysteresis",
      type: ChartSeriesTypeEnum.alarmThresholdDotted,
      units: alarm.sensorUnits,
      getDataFunc: (from: Date, to: Date, frequency?: number, data?: any) => this.alarmFunc(from, to, alarm.sensorId).pipe(map(x => AsEnumerable(x.lowHysteresis).Where(y => !!y.value).ToArray()))
    };
    seriesConfigs.push(alarmThresholdLowHist);

    //mark areas
    const markAreasConfig: IMarkAreasConfig = {
      getChartMarkAreasFunc: (from: Date, to: Date, data?: any) => this.apiService.getAlarms(from, to, null, null, alarm.sensorId)
        .pipe(map(x => x.map(y => <IMarkAreaData>{ name: y.type, from: y.from, to: y.to, color: y.getAlarmColor() })))
    }

    this.lineChart.onDateRangeChanged(this.dateRange);
    seriesConfigs.map(series => this.lineChart.addSeries(series));

    this.lineChart.addMarkAreas(markAreasConfig);
    this.lineChart.readMarkAreas();

  }

}
