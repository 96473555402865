import { Injectable } from '@angular/core';
import { CmBackendApiService } from '../api/cm-backend-api.service';
import { NotificationService } from '@fitech-workspace/core-lib';
import { HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class FloorPlanService {
	private _sasMap: Map<number, string> = new Map();

	constructor(private _apiService: CmBackendApiService, private _notificationService: NotificationService) {}

	getSasUri(locationId: number, url: string): string {
		if (this._sasMap.has(locationId) && this.checkExpiration(this._sasMap.get(locationId))) {
			return this._sasMap.get(locationId);
		}
	}

	getSasUriFromApi(locationId: number, url: string): Observable<string> {
		return this._apiService.getFloorPlanSasUri(locationId, url).pipe(
			tap((res: string) => {
				this._sasMap.set(locationId, res);
			}),
		);
	}

	private checkExpiration(sasUri: string): boolean {
		const exp: RegExp = /(se=\d{4}-\d{2}-\d{2})/;
		const dateNow: Date = new Date();

		const result: RegExpExecArray = exp.exec(sasUri);
		if (result) {
			const len: number = result[0].length;
			const dateExpirionStr = result[0].slice(-len + 3);
			const dateExirion = new Date(dateExpirionStr);
			return dateExirion > dateNow;
		}
		return false;
	}
}
