import { MachineState } from '../machine-state.model';
import { MachineMarker } from './machine-marker';
import { AsEnumerable } from 'linq-es2015';

export class MachineIconFactory {

  public getIcon(machineMarker: MachineMarker): { iconHtml: string, iconClass: string } {
    {
      const machine = machineMarker.extraData.machine;
      if (machine.defaultSensorState && machine.defaultSensorState.source.type === 'gas_flamable_digi') {
        return this.getFlamableSystemIcon(machineMarker);
      }
      return this.getDefaultIcon(machineMarker);
    }
  }

  public getDefaultIcon(machineMarker: MachineMarker) {
    const machine = machineMarker.extraData.machine;
    return {
      iconHtml:
        `<div class="sensor-detail" data-toggle="tooltip" trackerId="${machineMarker.id}" style="background-color:${machineMarker.stateColor.color}">
        <nobr>
        <i class="sensor-detail-icon fas fa-cogs"></i>
        <span>${machine.source.name}</span>
      </div>`,
      iconClass: 'machine'
    }
  }

  public getFlamableSystemIcon(machineMarker: MachineMarker) {
    const machine = machineMarker.extraData.machine;
    const machineTemperatureSensor = AsEnumerable(machine.sensorsStates).FirstOrDefault(x => x.source.type == "temperature");
    const machineHumiditySensor = AsEnumerable(machine.sensorsStates).FirstOrDefault(x => x.source.type == "humidity");
    return {
      iconHtml: `<div class="sensor-detail sensor-flammable" data-toggle="tooltip" trackerId="${machineMarker.id}" 
      style="background-color:${machineMarker.stateColor.color};">
        <i class="sensor-detail-icon fas fa-fire-extinguisher"></i>
        <div class="sensors-container">
          <div class="sensor-value">${machineTemperatureSensor.value} ${machineTemperatureSensor.source.units}</div>
          <div class="sensor-value">${machineHumiditySensor.value} ${machineHumiditySensor.source.units}</div>
     
     }     </div>
      </div>`,
      iconClass: machine.defaultSensorState.source.type
    };
  }

}