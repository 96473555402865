import { Component } from '@angular/core';
import { ITooltipParams } from '@ag-grid-community/core';
import { ITooltipAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'fitech-workspace-tooltip-component',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltip implements ITooltipAngularComp {

  data: string;

  agInit(params: ITooltipParams): void {
    this.data = params.value;
  }
}