import { SensorDisplayTypeEnum } from './sensor-display-type.enum';

export class SensorTypes {
    static temperature: SensorType = { key: 1, type: "temperature", sourceProperty: "temperature", sufix: "T", icon: 'fas fa-temperature-low', displayType: SensorDisplayTypeEnum.analog };
    static temperature2: SensorType = { key: 1, type: "temperature", sourceProperty: "temperature2", sufix: "T2", icon: 'fas fa-temperature-low', displayType: SensorDisplayTypeEnum.analog };
    static humidity: SensorType = { key: 2, type: "humidity", sourceProperty: "humidity", sufix: "H", icon: 'fas fa-tint', displayType: SensorDisplayTypeEnum.analog };
    static pressure: SensorType = { key: 3, type: "pressure", sourceProperty: "pressure", sufix: "P", icon: 'fas fa-tachometer-alt', displayType: SensorDisplayTypeEnum.analog };
    static ambient_light: SensorType = { key: 4, type: "ambient_light", sourceProperty: "ambient_light", sufix: "L", icon: 'far fa-lightbulb', displayType: SensorDisplayTypeEnum.analog };
    static pm_2_5: SensorType = { key: 5, type: "pm_2_5", sourceProperty: "pm_2_5", sufix: "PM2.5", icon: 'fab fa-cloudversify', displayType: SensorDisplayTypeEnum.analog };
    static pm_10: SensorType = { key: 6, type: "pm_10", sourceProperty: "pm_10", sufix: "PM10", icon: 'fas fa-smog', displayType: SensorDisplayTypeEnum.analog };
    static item_counter: SensorType = { key: 7, type: "item_counter", sourceProperty: "item_counter", sufix: "CNT", icon: 'fas fa-calculator', displayType: SensorDisplayTypeEnum.analogStep };
    static item_counter_gain: SensorType = { key: 8, type: "item_counter_gain", sourceProperty: "item_counter_gain", sufix: "CNT", icon: 'fas fa-calculator', displayType: SensorDisplayTypeEnum.discrete };
    //welders
    static voltage: SensorType = { key: 12, type: "voltage", sourceProperty: "voltage", sufix: "V", icon: 'fas fa-bolt', displayType: SensorDisplayTypeEnum.analog };
    static current: SensorType = { key: 13, type: "current", sourceProperty: "current", sufix: "C", icon: 'fas fa-water', displayType: SensorDisplayTypeEnum.analog };
    static percentage: SensorType = { key: 14, type: "percentage", sourceProperty: "percentage", sufix: "R", icon: 'fas fa-percentage', displayType: SensorDisplayTypeEnum.analog };
    static time: SensorType = { key: 15, type: "time", sourceProperty: "time", sufix: "D", icon: 'fas fa-stopwatch', displayType: SensorDisplayTypeEnum.analogStep };
    static frequency: SensorType = { key: 16, type: "frequency", sourceProperty: "frequency", sufix: "F", icon: 'fas fa-wave-square', displayType: SensorDisplayTypeEnum.analogStep };
    static power: SensorType = { key: 17, type: "power", sourceProperty: "power", sufix: "W", icon: 'fas fa-car-battery', displayType: SensorDisplayTypeEnum.analog };
    static battery: SensorType = { key: 18, type: "battery", sourceProperty: "battery", sufix: "BAT", icon: 'fas fa-battery-half', displayType: SensorDisplayTypeEnum.analogStep };

    //insure-tech
    static flood_sensor: SensorType = { key: 30, type: "flood_sensor", sourceProperty: "flood_sensor", sufix: "FLO", icon: 'fas fa-faucet', noValue: true, displayType: SensorDisplayTypeEnum.discrete };
    static fire_sensor: SensorType = { key: 31, type: "fire_sensor", sourceProperty: "fire_sensor", sufix: "FIR", icon: 'fas fa-fire-extinguisher', noValue: true, displayType: SensorDisplayTypeEnum.discrete };
    static intrusion_sensor: SensorType = { key: 32, type: "intrusion_sensor", sourceProperty: "intrusion_sensor", sufix: "INTR", icon: 'fas fa-door-open', noValue: true, displayType: SensorDisplayTypeEnum.discrete };
    //general
    static gen_analog: SensorType = { key: 40, type: "gen_analog", sourceProperty: "gen_analog", sufix: "GA", icon: 'fas fa-genderless', displayType: SensorDisplayTypeEnum.analog };
    static gen_analog_step: SensorType = { key: 41, type: "gen_analog_step", sourceProperty: "gen_analog_step", sufix: "GAS", icon: 'fas fa-genderless', displayType: SensorDisplayTypeEnum.analogStep };
    static gen_string: SensorType = { key: 42, type: "gen_string", sourceProperty: "gen_string", sufix: "GS", icon: 'fas fa-align-center', displayType: SensorDisplayTypeEnum.string };
    static gen_alarm: SensorType = { key: 50, type: "gen_alarm", sourceProperty: "gen_alarm", sufix: "GAA", icon: 'fas fa-bell', displayType: SensorDisplayTypeEnum.discrete };
    //digital
    static gas_co_digi: SensorType = { key: 70, type: "gas_co_digi", sourceProperty: "gas_co_digi", sufix: "DGCO", icon: 'fas fa-wind', displayType: SensorDisplayTypeEnum.discrete };
    static gas_flamable_digi: SensorType = { key: 71, type: "gas_flamable_digi", sourceProperty: "gas_flamable_digi", sufix: "DGFL", icon: 'fas fa-fire-extinguisher', displayType: SensorDisplayTypeEnum.discrete };
    static gas_smoke_digi: SensorType = { key: 72, type: "gas_smoke_digi", sourceProperty: "gas_smoke_digi", sufix: "DGSM", icon: 'fas fa-fire-extinguisher', displayType: SensorDisplayTypeEnum.discrete };
    static ir_smoke_digi: SensorType = { key: 73, type: "ir_smoke_digi", sourceProperty: "ir_smoke_digi", sufix: "DISM", icon: 'fas fa-fire-extinguisher', displayType: SensorDisplayTypeEnum.discrete };
    static humidity1_digi: SensorType = { key: 74, type: "humidity1_digi", sourceProperty: "humidity1_digi", sufix: "DH1", icon: 'fas fa-tint', displayType: SensorDisplayTypeEnum.discrete };
    static humidity2_digi: SensorType = { key: 75, type: "humidity2_digi", sourceProperty: "humidity2_digi", sufix: "DH2", icon: 'fas fa-tint', displayType: SensorDisplayTypeEnum.discrete };
    static temp1_digi: SensorType = { key: 76, type: "temp1_digi", sourceProperty: "temp1_digi", sufix: "DT1", icon: 'fas fa-temperature-low', displayType: SensorDisplayTypeEnum.discrete };
    static temp2_digi: SensorType = { key: 77, type: "temp2_digi", sourceProperty: "temp2_digi", sufix: "DT2", icon: 'fas fa-temperature-low', displayType: SensorDisplayTypeEnum.discrete };
    static motion1_digi: SensorType = { key: 78, type: "motion1_digi", sourceProperty: "motion1_digi", sufix: "DMO1", icon: 'fas fa-shoe-prints', displayType: SensorDisplayTypeEnum.discrete };
    static motion2_digi: SensorType = { key: 79, type: "motion2_digi", sourceProperty: "motion2_digi", sufix: "DMO2", icon: 'fas fa-shoe-prints', displayType: SensorDisplayTypeEnum.discrete };
    static motion3_digi: SensorType = { key: 80, type: "motion3_digi", sourceProperty: "motion3_digi", sufix: "DMO3", icon: 'fas fa-shoe-prints', displayType: SensorDisplayTypeEnum.discrete };
    static motion4_digi: SensorType = { key: 81, type: "motion4_digi", sourceProperty: "motion4_digi", sufix: "DMO4", icon: 'fas fa-shoe-prints', displayType: SensorDisplayTypeEnum.discrete };
    static sw1_digi: SensorType = { key: 82, type: "sw1_digi", sourceProperty: "sw1_digi", sufix: "DSW1", icon: 'fas fa-wave-square', displayType: SensorDisplayTypeEnum.discrete };
    static sw2_digi: SensorType = { key: 83, type: "sw2_digi", sourceProperty: "sw2_digi", sufix: "DSW2", icon: 'fas fa-wave-square', displayType: SensorDisplayTypeEnum.discrete };
    static sw3_digi: SensorType = { key: 84, type: "sw3_digi", sourceProperty: "sw3_digi", sufix: "DSW3", icon: 'fas fa-wave-square', displayType: SensorDisplayTypeEnum.discrete };
    static sw4_digi: SensorType = { key: 85, type: "sw4_digi", sourceProperty: "sw4_digi", sufix: "DSW4", icon: 'fas fa-wave-square', displayType: SensorDisplayTypeEnum.discrete };
    static sw5_digi: SensorType = { key: 86, type: "sw5_digi", sourceProperty: "sw5_digi", sufix: "DSW5", icon: 'fas fa-wave-square', displayType: SensorDisplayTypeEnum.discrete };
    static sw6_digi: SensorType = { key: 87, type: "sw6_digi", sourceProperty: "sw6_digi", sufix: "DSW6", icon: 'fas fa-wave-square', displayType: SensorDisplayTypeEnum.discrete };
    static sw7_digi: SensorType = { key: 88, type: "sw7_digi", sourceProperty: "sw7_digi", sufix: "DSW7", icon: 'fas fa-wave-square', displayType: SensorDisplayTypeEnum.discrete };
    static sw8_digi: SensorType = { key: 89, type: "sw8_digi", sourceProperty: "sw8_digi", sufix: "DSW8", icon: 'fas fa-wave-square', displayType: SensorDisplayTypeEnum.discrete };
    //tech
    static tech_rssi: SensorType = { key: 100, type: "tech_rssi", sourceProperty: "tech_rssi", sufix: "RSSI", icon: 'fas fa-broadcast-tower', displayType: SensorDisplayTypeEnum.analog };
    static tech_rsrp: SensorType = { key: 101, type: "tech_rsrp", sourceProperty: "tech_rsrp", sufix: "RSRP", icon: 'fas fa-broadcast-tower', displayType: SensorDisplayTypeEnum.analog };
    static tech_sinr: SensorType = { key: 102, type: "tech_sinr", sourceProperty: "tech_sinr", sufix: "SINR", icon: 'fas fa-broadcast-tower', displayType: SensorDisplayTypeEnum.analog };
    static tech_rsrq: SensorType = { key: 103, type: "tech_rsrq", sourceProperty: "tech_rsrq", sufix: "RSRQ", icon: 'fas fa-broadcast-tower', displayType: SensorDisplayTypeEnum.analog };
    static tech_last_xfer_time: SensorType = { key: 103, type: "tech_last_xfer_time", sourceProperty: "tech_last_xfer_time", sufix: "LAST_XFER_TIME", icon: 'fas fa-broadcast-tower', displayType: SensorDisplayTypeEnum.analog };
    static tech_battery_voltage: SensorType = { key: 104, type: "tech_battery_voltage", sourceProperty: "tech_battery_voltage", sufix: "BAT", icon: 'fas fa-battery-three-quarters', displayType: SensorDisplayTypeEnum.analog };

    //control
    static control_toggle: SensorType = { key: 1000, type: "control_toggle", sourceProperty: "control_toggle", sufix: "CTOGGLE", icon: 'fas fa-toggle-on', displayType: SensorDisplayTypeEnum.discrete };

    static getBySourceProperty(sourceProperty: string): SensorType {
        var key = "";
        for (key in SensorTypes) {
            if ((SensorTypes[key] as SensorType).sourceProperty === sourceProperty)
                return SensorTypes[key] as SensorType;
        }

        return null;
    }

    static getByType(type: string): SensorType {
        var key = "";
        for (key in SensorTypes) {
            if ((SensorTypes[key] as SensorType).type === type)
                return SensorTypes[key] as SensorType;
        }

        return null;
    }

    static getTypeDictionary(withTech = false): { key: number, value: string }[] {
        const result = [];
        var key = "";
        for (key in SensorTypes) {
            const sensor = (SensorTypes[key] as SensorType);
            if (!sensor || (!withTech && sensor.sourceProperty.startsWith("tech")))
                continue;

            if (result.filter(x => x.key === sensor.key).length > 0)
                continue;

            result.push({ key: sensor.key, value: sensor.type });
        }

        return result;
    }
}


export interface SensorType {
    key: number;
    type: string;
    sourceProperty: string;
    sufix: string;
    icon: string;
    displayType: SensorDisplayTypeEnum;
    noValue?: boolean;
}