import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CellClickedEvent, ColumnApi, GridApi, GridOptions, IRowNode, Module } from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
declare let $: any;

@Component({
	selector: 'tra-grid-base',
	templateUrl: './grid-base.component.html',
	styleUrls: ['./grid-base.component.scss'],
})
export class GridBaseComponent implements OnInit, OnChanges, AfterViewChecked {
	@Input() columnDefs: any;
	@Input() rowData: any;
	@Input() rowHeight = 25;
	@Input() isPermissionRead = true;
	@Input() isLoadingResults = false;

	@Output() rowClicked = new EventEmitter<any>();

	modules: Module[] = [ClientSideRowModelModule, CsvExportModule];
	gridApi: GridApi;
	gridColumnApi: ColumnApi;
	frameworkComponents: any = {};

	// TODO: verify gridOptions - when I added type from library many of them were unsupported
	gridOptions: GridOptions = {
		defaultColDef: {
			filter: false,
			sortable: true,
		},
		rowSelection: 'multiple',
		rowMultiSelectWithClick: false,
		suppressScrollOnNewData: true,
		suppressRowClickSelection: true,
		getRowStyle: null,
		headerHeight: 25,
		icons: {
			sortAscending: '<i class="fas fa-long-arrow-alt-up"></i>',
			sortDescending: '<i class="fas fa-long-arrow-alt-down"></i>',
		},
		suppressHorizontalScroll: window.innerWidth > 420,
		onCellClicked: (e: CellClickedEvent) => {
			if (e.column['userProvidedColDef'].name !== 'buttons') {
				// cell is from non-select column
				e.node.setSelected(!e.node.isSelected);
			}
		},
	};

	defaultColDef = {
		headerComponentParams: {
			sortable: true,
			menuIcon: 'fa-bars',
			template:
				'<div class="ag-cell-label-container grey-tooltip" role="presentation">' +
				'  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
				'  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
				'    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
				'    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
				'    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
				'    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
				'    <span ref="eText" class="ag-header-cell-text" role="columnheader" data-toggle="tooltip" title=""></span>' +
				'    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
				'  </div>' +
				'</div>',
		},
	};

	readonly overlayLoadingTemplate = `<i class="fas fa-sync fa-spin refresh-spinner icon--refreshing"></i>`;

	ngOnInit(): void {
		this.gridOptions.rowHeight = this.rowHeight;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!!changes?.isPermissionRead && this.gridApi && !changes.isPermissionRead.currentValue) {
			this.showNoPermissionsMessage();
		}

		if (changes.isLoadingResults) {
			this.toggleLoadingOverlay(this.isLoadingResults);
		}
	}

	onGridReady(params: any): void {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

		if (!this.isPermissionRead && !this.rowData?.length) {
			this.showNoPermissionsMessage();
		} else {
			this.gridApi.hideOverlay();
		}

		this.autoSizeColumns();
	}

	toggleLoadingOverlay(showOverlay: boolean): void {
		if (showOverlay) {
			this.gridApi?.showLoadingOverlay();
		} else {
			if (this.gridOptions?.loadingOverlayComponent) {
				this.gridApi?.hideOverlay();
			}
		}
	}

	onRowClicked(params: any): void {
		$('.grey-tooltip').tooltip('hide');
		this.rowClicked.emit(params);
	}

	selectDataInGrid(dataId: any, columnId: string = 'id'): any[] {
		this.gridApi.deselectAll();
		const that = this;
		const selected = [];
		this.gridApi.forEachNodeAfterFilter((node: IRowNode<any>) => {
			if (node.data[columnId] == dataId) {
				node.setSelected(true);
				that.gridApi.ensureNodeVisible(node, null);

				if (node.data) {
					selected.push(node.data);
				}
			}
		});

		return selected;
	}

	ngAfterViewChecked(): void {
		this.setGridTooltips();
	}

	applyQuickFilter(filterValue: string): void {
		this.columnDefs.forEach((col: any) => {
			col.getQuickFilterText = (params: any): any => {
				if (params.value && typeof params.value !== 'object') {
					return params.value.replace(/ /g, '.');
				} else {
					return params.value;
				}
			};
		});

		this.gridApi.setQuickFilter(filterValue.replace(/ /g, '.'));
	}

	quickFilterTextFix(params: any): any {
		if (params.value) {
			return params.value.replace(/ /g, '.');
		} else {
			return params.value;
		}
	}

	refresh(): void {
		this.gridApi?.setRowData(this.rowData);
	}

	clearQuickFilter(): void {
		this.gridApi.setQuickFilter('');
	}

	autoSizeColumns(): void {
		const allColumnIds = this.columnDefs.map((x: any) => x.field);
		this.gridColumnApi.autoSizeColumns(allColumnIds);
	}

	sizeToFit(): void {
		this.gridApi.sizeColumnsToFit();
	}

	private setGridTooltips(): void {
		$('.grey-tooltip').tooltip({
			trigger: 'hover',
			html: true,
			template: '<div class="tooltip grid-tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner grid-tooltip-inner"></div></div>',
			title: function () {
				return this.innerText;
			},
		});
	}

	private showNoPermissionsMessage(): void {
		this.gridOptions.overlayNoRowsTemplate = `The user doesn't have enough permissions to read data.`;
		this.gridApi.showNoRowsOverlay();
	}
}
