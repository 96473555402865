import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	private _snackBarRef: any;
	private _snackBarRefOnDismiss$: any;

	constructor(public snackBar: MatSnackBar) {}

	showError(info: string, checkDuplicates: boolean = false, duration: number = null, showOk: boolean = true): void {
		showOk = !duration ? true : showOk;
		this.show(info, 'error-snackbar', checkDuplicates, duration, showOk);
	}

	showSuccess(info: string, checkDuplicates: boolean = false, duration: number = 3000, showOk: boolean = false): void {
		showOk = !duration ? true : showOk;
		this.show(info, 'success-snackbar', checkDuplicates, duration, showOk);
	}

	showInfo(info: string, checkDuplicates: boolean = false, duration: number = 3000, showOk: boolean = false): void {
		showOk = !duration ? true : showOk;
		this.show(info, 'info-snackbar', checkDuplicates, duration, showOk);
	}

	hide(): void {
		this.snackBar.dismiss();
	}

	private show(info: string, panelClass: string = null, checkDuplicates: boolean = false, duration: number = null, showOk: boolean = true): void {
		if (this.isDuplicated(info) && checkDuplicates) {
			return;
		}

		this._snackBarRef = this.snackBar.open(info, showOk ? 'OK' : null, {
			panelClass: [panelClass],
			duration: duration,
		});

		this._snackBarRefOnDismiss$ = this._snackBarRef.afterDismissed().subscribe(() => {
			this._snackBarRef = null;
			this._snackBarRefOnDismiss$.unsubscribe();
		});
	}

	private isDuplicated(text: string): boolean {
		return this._snackBarRef?.instance?.data?.message === text;
	}
}
