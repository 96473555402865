import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { withCache } from '@ngneat/cashew';
import { AsEnumerable, asEnumerable } from 'linq-es2015';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '@fitech-workspace/auth-lib';
import { ObjectPositionHistory } from '@fitech-workspace/fisense-common-lib';
import { AlarmThresholdData, IChartData } from '@fitech-workspace/shared/ui/chart-lib';
import { Alarm } from '../models/alarm.model';
import { DevicesStats } from '../models/devices-stats.model';
import { Machine } from '../models/machine.model';
import { Sensor } from '../models/sensor.model';
import { State } from '../models/state.model';

export const BACKEND_API_URL = new InjectionToken<string>('BACKEND_API_URL');
export const BACKEND_CLIENT_TAG = new InjectionToken<string>('BACKEND_CLIENT_TAG');
export const BACKEND_API_HOSTMASTERKEY = new InjectionToken<string>('BACKEND_API_HOSTMASTERKEY');

@Injectable({
	providedIn: 'root'
})
export class BackendApiService {
	protected apiUrl: string;
	protected clientTag: string;
	protected apiHostMasterKey: string;

	constructor(
		private _userService: UserService,
		protected httpClient: HttpClient,
		@Optional()
		@Inject(BACKEND_API_URL)
		trackersApiUrl?: string,
		@Optional()
		@Inject(BACKEND_API_HOSTMASTERKEY)
		apiHostMasterKey?: string,
		@Optional()
		@Inject(BACKEND_CLIENT_TAG)
		clientTag?: string
	) {
		this.apiUrl = trackersApiUrl;
		this.apiHostMasterKey = apiHostMasterKey;
		this.clientTag = clientTag;
	}

	public getCurrentStates(locationId: number, clientIds?: number[], onlyWithSamplingMs?: boolean): Observable<State[]> {
		const params: any = {};
		if (this.apiHostMasterKey) {
			params.code = this.apiHostMasterKey;
		}

		if (locationId) {
			params.locationId = locationId;
		}

		if (clientIds != null) {
			params.clientIds = clientIds;
		}

		if (onlyWithSamplingMs != null) {
			params.onlyWithSamplingMs = onlyWithSamplingMs;
		} else if (this._userService.currentUser.userAllowedClientsWithChildClients) {
			params.clientIds = AsEnumerable(this._userService.currentUser.userAllowedClientsWithChildClients)
				.Select((x) => x.clientId)
				.ToArray();
		}

		return this.httpClient.get<any[]>(this.apiUrl + 'GetMeasurementsCurrent', { params }).pipe(
			map((res) => {
				return res.map((data) => new State(data));
			})
		);
	}

	public getAlarmsGroupedByTypeWithStyles(from: Date, to: Date, clientIds: number[], locationId?: number, sensorId?: number): Observable<any[][]> {
		return this.getAlarms(from, to, clientIds, locationId, sensorId).pipe(
			map((res) => {
				const alarms = AsEnumerable(res)
					.OrderBy((x) => x.type)
					.GroupBy((x) => x.type)
					.Select((x) =>
						AsEnumerable(x)
							.Select((y) => ({ data: y, color: y.getAlarmColor() }))
							.ToArray()
					)
					.ToArray();
				return alarms;
			})
		);
	}

	public getAlarms(from: Date, to: Date, clientIds: number[], locationId?: number, sensorId?: number): Observable<Alarm[]> {
		const params: any = {
			from: moment(from).toISOString(),
			to: moment(to).toISOString(),
		};

		if (this.apiHostMasterKey) {
			params.code = this.apiHostMasterKey;
		}
		if (locationId != null) {
			params.locationId = locationId;
		}
		if (sensorId != null) {
			params.sensorId = sensorId;
		}

		if (clientIds != null) {
			params.clientIds = clientIds;
		} else {
			if (this._userService.currentUser.userAllowedClientsWithChildClients != null) {
				params.clientIds = AsEnumerable(this._userService.currentUser.userAllowedClientsWithChildClients)
					.Select((x) => x.clientId)
					.ToArray();
			}
		}

		return this.httpClient.get<any[]>(this.apiUrl + 'GetAlarmsHistory', { params }).pipe(
			map((res) => {
				return res.map((data) => new Alarm(data));
			})
		);
	}

	public getAlarmsThin(from: Date, to: Date, clientIds: number[], locationId?: number, zoneIds?: number[], sensorId?: number): Observable<Alarm[]> {
		const params: any = {
			from: moment(from).toISOString(),
			to: moment(to).toISOString(),
		};

		if (this.apiHostMasterKey) {
			params.code = this.apiHostMasterKey;
		}
		if (locationId != null) {
			params.locationId = locationId;
		}
		if (zoneIds != null) {
			params.zoneIds = zoneIds;
		}
		if (sensorId != null) {
			params.sensorId = sensorId;
		}

		if (clientIds != null) {
			params.clientIds = clientIds;
		} else {
			if (this._userService.currentUser.userAllowedClientsWithChildClients != null) {
				params.clientIds = AsEnumerable(this._userService.currentUser.userAllowedClientsWithChildClients)
					.Select((x) => x.clientId)
					.ToArray();
			}
		}

		return this.httpClient.get<any[]>(this.apiUrl + 'GetAlarmsHistoryThin', { params }).pipe(
			map((res) => {
				return res.map((data) => new Alarm(data));
			})
		);
	}

	public getAlarmsActive(clientIds?: number[]): Observable<Alarm[]> {
		const params: any = {};
		if (this.apiHostMasterKey) {
			params.code = this.apiHostMasterKey;
		}
		if (clientIds) {
			params.clientIds = clientIds;
		} else {
			if (this._userService.currentUser.userAllowedClients != null) {
				params.clientIds = AsEnumerable(this._userService.currentUser.userAllowedClientsWithChildClients)
					.Select((x) => x.clientId)
					.ToArray();
			}
		}
		return this.httpClient.get<Alarm[]>(this.apiUrl + 'GetAlarmsActive', { params }).pipe(map((res) => res.map((data) => new Alarm(data))));
	}

	public getMeasurementsCurrentSpecified(numberOfSensors?: number, clientIds?: number[]): Observable<State[]> {
		const params: any = {};
		if (this.apiHostMasterKey) {
			params.code = this.apiHostMasterKey;
		}
		if (numberOfSensors) {
			params.takeNo = numberOfSensors;
		}
		if (clientIds) {
			params.clientIds = clientIds;
		} else {
			if (this._userService.currentUser.userAllowedClients != null) {
				params.clientIds = AsEnumerable(this._userService.currentUser.userAllowedClientsWithChildClients)
					.Select((x) => x.clientId)
					.ToArray();
			}
		}
		return this.httpClient.get<State[]>(this.apiUrl + 'GetMeasurementsCurrentSpecified', { params }).pipe(
			map((res) => {
				return res.map((data) => new State(data));
			})
		);
	}

	// get data
	public getStateHistory(from: Date, to: Date, clientIds: number[], sensorId?: number, frequency?: number): Observable<State[]> {
		const params: any = {
			from: moment(from).toISOString(),
			to: moment(to).toISOString(),
			frequencyInSeconds: frequency,
		};

		if (this.apiHostMasterKey) {
			params.code = this.apiHostMasterKey;
		}

		if (sensorId != null) {
			params.sensorId = sensorId;
			params.sensorIds = [sensorId]; // new version of api use sensorIds parameter, but we also using old version so for backward-comparability we need both params
		}

		if (clientIds != null) {
			params.clientIds = clientIds;
		} else {
			if (this._userService.currentUser.userAllowedClientsWithChildClients != null) {
				params.clientIds = AsEnumerable(this._userService.currentUser.userAllowedClientsWithChildClients)
					.Select((x) => x.clientId)
					.ToArray();
			}
		}

		return this.httpClient.get<any[]>(this.apiUrl + 'GetMeasurementsHistoryAggregated', { params }).pipe(
			map((res) => {
				return asEnumerable(res)
					.Select((data) => {
						const value = typeof data.value !== 'undefined' ? data.value : typeof data.avg !== 'undefined' ? data.avg : 0;
						return {
							timestamp: data.timestamp,
							value: value,
							min: data.min,
							max: data.max,
						};
					})
					.ToArray() as State[];
			})
		);
	}

	getStateHistoryCsvLink(from: Date, to: Date, clientIds: number[], sensorId?: number): string {
		const params: any = {
			from: moment(from).toISOString(),
			to: moment(to).toISOString(),
		};

		if (this.apiHostMasterKey) {
			params.code = this.apiHostMasterKey;
		}

		if (sensorId != null) {
			params.sensorId = sensorId;
		}

		if (clientIds != null) {
			params.clientIds = clientIds;
		} else {
			if (this._userService.currentUser.userAllowedClientsWithChildClients != null) {
				params.clientIds = AsEnumerable(this._userService.currentUser.userAllowedClientsWithChildClients)
					.Select((x) => x.clientId)
					.ToArray();
			}
		}

		let clientIdsParam = '';
		if (params.clientIds != null) {
			params.clientIds.forEach((x) => {
				clientIdsParam += `&clientIds=${x}`;
			});
		}

		return `${this.apiUrl}GetMeasurementsHistoryCsv?from=${params.from}&to=${params.to}&code=${params.code}&sensorId=${params.sensorId}${clientIdsParam}`;
	}

	public getAlarmsThresholds(from: Date, to: Date, clientIds: number[], sensorId?: number): Observable<AlarmThresholdData> {
		const params: any = {
			from: moment(from).toISOString(),
			to: moment(to).toISOString(),
		};

		if (this.apiHostMasterKey) {
			params.code = this.apiHostMasterKey;
		}

		if (sensorId != null) {
			params.sensorId = sensorId;
		}

		if (clientIds != null) {
			params.clientIds = clientIds;
		}

		return this.httpClient
			.get<any[]>(this.apiUrl + 'GetAlarmsThresholds', {
				params,
				context: withCache(),
			})
			.pipe(
				map((res) => {
					const results: AlarmThresholdData = { high: [], low: [], highHysteresis: [], lowHysteresis: [] };

					if (res == null || res.length == 0) {
						return results;
					}

					if (AsEnumerable(res).Count((x) => x.alarmType === 'High') > 0) {
						results.high = AsEnumerable(res)
							.Where((x) => x.alarmType === 'High')
							.Select((x) => {
								return { value: x.value, timestamp: x.timestamp } as IChartData;
							})
							.ToArray();
					}
					if (AsEnumerable(res).Count((x) => x.alarmType === 'Low') > 0) {
						results.low = AsEnumerable(res)
							.Where((x) => x.alarmType === 'Low')
							.Select((x) => {
								return { value: x.value, timestamp: x.timestamp } as IChartData;
							})
							.ToArray();
					}
					if (AsEnumerable(res).Count((x) => x.alarmType === 'HighHysteresis') > 0) {
						results.highHysteresis = AsEnumerable(res)
							.Where((x) => x.alarmType === 'HighHysteresis')
							.Select((x) => {
								return { value: x.value, timestamp: x.timestamp } as IChartData;
							})
							.ToArray();
					}
					if (AsEnumerable(res).Count((x) => x.alarmType === 'LowHysteresis') > 0) {
						results.lowHysteresis = AsEnumerable(res)
							.Where((x) => x.alarmType === 'LowHysteresis')
							.Select((x) => {
								return { value: x.value, timestamp: x.timestamp } as IChartData;
							})
							.ToArray();
					}
					if (AsEnumerable(res).Count((x) => x.alarmType === 'HighWarning') > 0) {
						results.highWarning = AsEnumerable(res)
							.Where((x) => x.alarmType === 'HighWarning')
							.Select((x) => {
								return { value: x.value, timestamp: x.timestamp } as IChartData;
							})
							.ToArray();
					}
					if (AsEnumerable(res).Count((x) => x.alarmType === 'LowWarning') > 0) {
						results.lowWarning = AsEnumerable(res)
							.Where((x) => x.alarmType === 'LowWarning')
							.Select((x) => {
								return { value: x.value, timestamp: x.timestamp } as IChartData;
							})
							.ToArray();
					}
					return results;
				})
			);
	}

	public getMachines(locationId: number, clientIds?: number[]): Observable<Machine[]> {
		const params: any = {};

		if (this.apiHostMasterKey) {
			params.code = this.apiHostMasterKey;
		}
		if (locationId != null) {
			params.locationId = locationId;
		}
		if (clientIds != null) {
			params.clientIds = clientIds;
		} else {
			if (this._userService.currentUser.userAllowedClientsWithChildClients != null) {
				params.clientIds = AsEnumerable(this._userService.currentUser.userAllowedClientsWithChildClients)
					.Select((x) => x.clientId)
					.ToArray();
			}
		}

		return this.httpClient.get<any[]>(this.apiUrl + 'GetMachines', { params }).pipe(
			map((res) => {
				return res.map((data) => new Machine(data));
			})
		);
	}

	public getSensors(locationId?: number, clientIds?: number[]): Observable<Sensor[]> {
		const params: any = {};

		if (this.apiHostMasterKey) {
			params.code = this.apiHostMasterKey;
		}
		if (locationId != null) {
			params.locationId = locationId;
		}
		if (clientIds != null) {
			params.clientIds = clientIds;
		} else {
			if (this._userService.currentUser.userAllowedClientsWithChildClients != null) {
				params.clientIds = AsEnumerable(this._userService.currentUser.userAllowedClientsWithChildClients)
					.Select((x) => x.clientId)
					.ToArray();
			}
		}

		return this.httpClient.get<any[]>(this.apiUrl + 'GetSensors', { params }).pipe(
			map((res) => {
				return res.map((data) => Sensor.createFromSensor(data));
			})
		);
	}

	public getDevicePositionHistory(from: Date, to: Date, deviceId: number, clientIds?: number[]) {
		const params: any = {};
		if (this.apiHostMasterKey) {
			params.code = this.apiHostMasterKey;
		}

		if (from) {
			params.from = from.toISOString();
		}

		if (to) {
			params.to = to.toISOString();
		}

		if (deviceId) {
			params.deviceId = deviceId;
		}

		if (clientIds != null) {
			params.clientIds = clientIds;
		} else if (this._userService.currentUser.userAllowedClientsWithChildClients) {
			params.clientIds = AsEnumerable(this._userService.currentUser.userAllowedClientsWithChildClients)
				.Select((x) => x.clientId)
				.ToArray();
		}

		return this.httpClient
			.get<ObjectPositionHistory[]>(this.apiUrl + 'GetDevicePositionHistory', { params })
			.pipe(map((res) => res.map(({ id, deviceId, positionX, positionY, timestamp }) => ({ id, deviceId, positionX, positionY, timestamp }))));
	}

	public getDeviceStats(clientIds?: number[]): Observable<DevicesStats> {
		const params: any = {};
		if (this.apiHostMasterKey) {
			params.code = this.apiHostMasterKey;
		}
		if (clientIds) {
			params.clientIds = clientIds;
		} else {
			if (this._userService.currentUser.userAllowedClients != null) {
				params.clientIds = AsEnumerable(this._userService.currentUser.userAllowedClientsWithChildClients)
					.Select((x) => x.clientId)
					.ToArray();
			}
		}
		return this.httpClient.get<DevicesStats>(this.apiUrl + 'GetDevicesStats', { params }).pipe(map((res) => res));
	}

	public GetAlarmsNotAcked(from: Date, clientIds: number[], locationId?: number): Observable<Alarm[]> {
		const params: any = {};

		if (from) {
			params.from = moment(from).toISOString();
		}

		if (this.apiHostMasterKey) {
			params.code = this.apiHostMasterKey;
		}
		if (locationId != null) {
			params.locationId = locationId;
		}

		if (clientIds != null) {
			params.clientIds = clientIds;
		} else {
			if (this._userService.currentUser.userAllowedClientsWithChildClients != null) {
				params.clientIds = AsEnumerable(this._userService.currentUser.userAllowedClientsWithChildClients)
					.Select((x) => x.clientId)
					.ToArray();
			}
		}

		return this.httpClient.get<any[]>(this.apiUrl + 'GetAlarmsNotAcked', { params }).pipe(
			map((res) => {
				return res.map((data) => new Alarm(data));
			})
		);
	}

	public ChangeAlarmState(clientIds: number[], alarmGuids: string[], state: string): Observable<any[]> {
		const body: any = {
			alarmGuids: alarmGuids,
		};

		if (this.apiHostMasterKey) {
			body.code = this.apiHostMasterKey;
		}

		if (clientIds != null) {
			body.clientIds = clientIds;
		} else {
			if (this._userService.currentUser.userAllowedClientsWithChildClients != null) {
				body.clientIds = AsEnumerable(this._userService.currentUser.userAllowedClientsWithChildClients)
					.Select((x) => x.clientId)
					.ToArray();
			}
		}

		body.state = state;

		return this.httpClient.post<any[]>(this.apiUrl + 'ChangeAlarmState', body);
	}
}
