import { ApolloQueryResult } from '@apollo/client/core';
import { KeyVal } from '@fitech-workspace/core-lib';
import { CreateObjectDto, EditObjectDto } from '@fitech-workspace/shared/data-access/master-data-api-lib';
import { Apollo, MutationResult } from 'apollo-angular';
import gql from 'graphql-tag';
import { AsEnumerable } from 'linq-es2015';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryNameGraphQLEnum } from '../enums/query-name-graphql-enum';
import { IMasterObjectDefinition } from '../models/master-object-definition.model';
import { IMasterObjectHistory } from '../models/master-object-history.model';

export class MasterObjectsRepositoryQl {
	private _baseGraphQlSoftDelete = `mutation softDelete($id: Int!) { $$type$$ { softDelete(id: $id) } }`;

	constructor(private _apollo: Apollo, public definition: IMasterObjectDefinition, private _queryName: QueryNameGraphQLEnum) {}

	deleteObject(objectId: number, forceChildsUnassigment?: boolean, forceParentsUnassigment?: boolean, forceChildsDeletion?: boolean): any {
		const qlMutation =
			this.definition.type.slice(-1) === 's'
				? this._baseGraphQlSoftDelete.replace('$$type$$', this.definition.type + 'es')
				: this._baseGraphQlSoftDelete.replace('$$type$$', this.definition.type + 's');

		return this._apollo.mutate({
			mutation: gql`
				${qlMutation}
			`,
			variables: { id: objectId },
			refetchQueries: [
				{
					query: gql`
						${this.definition.graphQlQuery}
					`,
				},
			],
		});
	}

	getObjectHistoryLogs(id: any): Observable<IMasterObjectHistory[]> {
		throw new Error('Method not implemented.');
	}

	createObject(createReq: any): Observable<CreateObjectDto> {
		const qlMutation = this.definition.graphQlCreate;
		if (!qlMutation) {
			throw new Error('Missing GraphQl create mutation definition. Check definition property of MasterObjectComponent');
		}

		if (createReq.dateCreated === '') {
			createReq.dateCreated = null;
		}

		const querySplited = qlMutation.split('{').map((x: string) => x.replace(/\s/g, ''));
		const typeName = querySplited[1];

		return this._apollo
			.mutate({
				mutation: gql`
					${qlMutation}
				`,
				variables: createReq,
				refetchQueries: [
					{
						query: gql`
							${this.definition.graphQlQuery}
						`,
					},
				],
			})
			.pipe(map((result: MutationResult<any>) => result.data[typeName]?.create));
	}

	editObject(editReq: any): Observable<EditObjectDto> {
		const qlMutation = this.definition.graphQlUpdate;
		if (!qlMutation) {
			throw new Error('Missing GraphQl update mutation definition. Check definition property of MasterObjectComponent');
		}
		const querySplited = qlMutation.split('{').map((x: string) => x.replace(/\s/g, ''));
		const typeName = querySplited[1];

		return this._apollo
			.mutate({
				mutation: gql`
					${qlMutation}
				`,
				variables: editReq,
				refetchQueries: [
					{
						query: gql`
							${this.definition.graphQlQuery}
						`,
					},
				],
			})
			.pipe(
				map((result: MutationResult<any>) => {
					return result.data[typeName];
				})
			);
	}

	getAll(): Observable<unknown> {
		return this.getBy();
	}

	getBy(where: string = null): Observable<unknown> {
		let qlQuery = this.definition.graphQlQuery;
		if (!qlQuery) {
			throw 'Missing GraphQl query definition. Check definition property of MasterObjectComponent';
		}
		const querySplited = qlQuery.split('{').map((x: string) => x.replace(/\s/g, ''));
		const queriesGroupName = querySplited[1];
		const queryName = querySplited[2];

		if (where) {
			qlQuery = qlQuery.replace(queryName, `${queryName}(where: ${where})`);
		}

		const query = gql`
			${qlQuery}
		`;
		return this._apollo
			.query({
				query: query,
				fetchPolicy: 'network-only',
			})
			.pipe(
				map((result: MutationResult<any>) => {
					return result.data[queriesGroupName][queryName];
				})
			);
	}

	getByClient(clientIds: number[] | number): Observable<unknown> {
		let where = `{clientId: {eq:${clientIds}}}`;
		if (Array.isArray(clientIds)) {
			where = `{clientId: {in: [${clientIds}]}}`;
		}

		return this.getBy(where);
	}

	getSimpleByClient(clientIds: number[] | number): Observable<KeyVal[]> {
		return this.getByClient(clientIds).pipe(
			map((res: any[]) => {
				return AsEnumerable(res)
					.Select((x: any) => {
						return { value: x.name, key: x.id };
					})
					.ToArray();
			})
		);
	}

	getCustomQuery<T>(qlQuery: string): Observable<T> {
		if (!qlQuery) {
			throw 'Missing GraphQl query definition. Check definition property of MasterObjectComponent';
		}

		const querySplited = qlQuery.split(/{|\(/).map((x: string) => x.replace(/\s/g, ''));
		const queriesGroupName = querySplited[1];
		const queryName = querySplited[2];

		const query = gql`
			${qlQuery}
		`;
		return this._apollo
			.query({
				query: query,
				fetchPolicy: 'network-only',
			})
			.pipe(
				map((result: ApolloQueryResult<T>) => {
					return result.data[queriesGroupName][queryName];
				})
			);
	}

	getCustomSimpleQuery<T>(fields: string, whereConditions?: string): Observable<T> {
		const whereClause = whereConditions?.length ? `(where: { ${whereConditions} })` : '';

		const graphQlQuerySimple = `
          query {
            ${this._queryName} {
              all ${whereClause} {
                ${fields}
              }
            }
          }
        `;

		return this.getCustomQuery<T>(graphQlQuerySimple);
	}
}
