import { HttpClientModule } from '@angular/common/http';
import { Inject, InjectionToken, NgModule } from '@angular/core';
import { ApolloLink, InMemoryCache, from } from '@apollo/client/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { GraphQlResponseHandlingService } from './services/graph-ql-response-handling.service';

export const MASTER_DATA_QL_URL = new InjectionToken<string>('MASTER_DATA_QL_URL');

let uri;

export function createApollo(
	httpLink: HttpLink,
	graphQlResponseHandlingService: GraphQlResponseHandlingService
): {
	link: ApolloLink;
	cache: InMemoryCache;
} {
	return {
		link: from([graphQlResponseHandlingService.createErrorLink(), httpLink.create({ uri })]),
		cache: new InMemoryCache({
			typePolicies: {
				Query: {
					fields: {
						locationsQuery: {
							merge: true,
						},
						sensorsGroupsQuery: {
							merge: true,
						},
						sensorsQuery: {
							merge: true,
						},
						shiftsQuery: {
							merge: true,
						},
						tagsQuery: {
							merge: true,
						},
						componentsQuery: {
							merge: true,
						},
						toolsQuery: {
							merge: true,
						},
						zonesQuery: {
							merge: true,
						},
						linesQuery: {
							merge: true,
						},
					},
				},
			},
		}),
	};
}

@NgModule({
	imports: [ApolloModule],
	exports: [HttpClientModule],
	providers: [
		{
			provide: APOLLO_OPTIONS,
			useFactory: createApollo,
			deps: [HttpLink, GraphQlResponseHandlingService],
		},
	],
})
export class GraphQlModule {
	constructor(@Inject(MASTER_DATA_QL_URL) baseUrl?: string) {
		uri = `${baseUrl}/graphql` as any;
	}
}
