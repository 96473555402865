import { AsEnumerable } from 'linq-es2015';
import { IGpsPosition, IMapMarkerData } from '@fitech-workspace/shared/ui/map-lib';
import * as moment from 'moment';
import { State } from '../state.model';
import { Sensor } from '../sensor.model';

export class PlanMarker implements IMapMarkerData {
  constructor(state?: State) {
    if (state) {
      for (const property in state) {
        if (state.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>state)[property];
        }
      }

      const sensor = state.source as Sensor;
      this.id = state.sourceId;
      this.iconClass = sensor.type;
      this.lastTimestamp = state['timestamp'];
      this.setState(state);
      this.iconHtml = `

   <div class="sensor-detail" data-toggle="tooltip" trackerId="${this.id}" style="background-color:${this.stateColor.color}">
        <i class="sensor-detail-icon  ${Sensor.getIconClass(this.iconClass)}"></i>
        <div class="sensor-detail-container">
        <span class="" style="font-size: 14px; white-space:nowrap;">${Math.round((state.value + Number.EPSILON) * 100) / 100}  <span style = "font-size: 10px;" > ${' ' + sensor.units} </span></span>
       ${sensor.badgeClass ? `<div class="badge"><div><i class="` + sensor.badgeClass + `"></i></div></div>` : ''}
        </div>
      </div>`
      this.gps = {
        lat: state.source.positionX,
        lng: state.source.positionY
      };
      this.extraData = { source: sensor, alarmGuid: state.alarmGuid };
    }
  }
  id: any;
  iconClass: string;
  iconHtml?: string;
  stateColor: { state: string, color: string, priority: number };
  gps: IGpsPosition;
  lastTimestamp: Date;

  extraData?: {
    source?: Sensor;
    alarmGuid?: string;
  } = {};


  get hasAsyncCommunication(): boolean {
    return this.iconClass.endsWith('digi')
  }

  static getPlanMarkers(data: State[]): PlanMarker[] {
    const markers: PlanMarker[] = [];
    if (data && data.length > 0) {
      data.forEach(x => {
        markers.push(new PlanMarker(x));
      });
    }
    return markers;
  }

  static generateTooltipText(marker: IMapMarkerData) {
    const lastTimestampMoment = moment(marker.lastTimestamp);
    return `<div class="marker-tooltip-text">
       ${marker.extraData.source.name}
      </div>
      <hr>
      <div class="marker-tooltip-dateTop">
        ${marker.lastTimestamp ? lastTimestampMoment.fromNow() : '-'}
      </div>
      <div class="marker-tooltip-dateBottom">
        ${marker.lastTimestamp
        ? lastTimestampMoment.format('DD.MM.YY HH:mm')
        : '-'
      }
      </div>`;
  }
  setState(state) {
    if (moment(this.lastTimestamp) < moment().add(-6, 'hours') && !this.hasAsyncCommunication) {
      this.stateColor = Sensor.getSensorStateColor('inactive');
    } else if (state.alarmIsActive) {
      this.stateColor = Sensor.getSensorStateColor(('alarm-' + state.alarm.type).toLowerCase());
    } else {
      this.stateColor = Sensor.getSensorStateColor('active');
    }
  }
}
