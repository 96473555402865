import { Observable } from 'rxjs';
import { GlobalUserSettingDto, UserSettingDto } from '../models';

export abstract class UserSettingsFacadeBaseService {
	abstract isLocalUserSettingsLoaded$: Observable<boolean>;
	abstract globalUserSetting$(name: string): Observable<GlobalUserSettingDto>;
	abstract localUserSettings$(view: string, section?: string, element?: string): Observable<UserSettingDto[]>;
	abstract pushBatchUserSettings(userSettings: UserSettingDto[]): void;
	abstract pushBatchUserSetting(userSetting: UserSettingDto): void;
	abstract checkBatchedUserSettings(): void;
}
