<div class="sensors-container" #mainContainer>
  <div class="group">
    <div class="filter-container">
      <label for="filter" class="label-filter">Filter:</label>
      <input name="filter" id="quick-search-input" class="form-control" [formControl]="searchFilter" type="text"/>
    </div>
  </div>
  <div class="group" *ngFor="let groupDec of filteredGroupDeclarationToDisplay">
    <div class="group-title">{{groupDec.label}}</div>
    <div class="group-container">
      <fitech-workspace-hmi-indicator *ngFor="let state of groupDec.data; trackBy: trackByStateId"
        [ngClass]="{'for-wide-containers-1000': isWideContainer1000,'for-wide-containers-1500': isWideContainer1500}"
        [state]="state" [isAddedToReport]="trendSensorIds.includes(state?.sourceId)" (onChartToggle)="onChartToggle($event)">
      </fitech-workspace-hmi-indicator>
    </div>
  </div>
</div>
