<div class="mat-select-wrapper">
	<button
		*ngIf="isRefreshButton"
		class="mat-select-wrapper__refresh-btn"
		[title]="refreshButtonTitle"
		[disabled]="!isPermissionBackendRead || disabled"
		(click)="refreshItems($event)">
		<i class="fa fa-retweet" aria-hidden="true"></i>
	</button>
	<i *ngIf="refreshing" class="fas fa-sync fa-spin mat-select-wrapper__spinner"></i>
	<!-- Form control -->
	<mat-select
		#matSelect
		*ngIf="isControl"
		[id]="id"
		[placeholder]="placeholder"
		[formControl]="formControl"
		[multiple]="multiple"
		[disabled]="disabled || refreshing"
		(openedChange)="changeOpened($event)"
		(selectionChange)="onSelectionChange($event)">
		<ng-container *ngIf="{ value: isNoResults$ | async } as isNoResults">
			<mat-option *ngIf="!noItems && isSearch" disabled matSelectSearchOption [isNoResults]="isNoResults.value" (click)="$event.stopPropagation()">
				<ng-container *ngTemplateOutlet="search; context: isNoResults"></ng-container>
			</mat-option>
		</ng-container>
		<mat-option
			class="mat-option"
			*ngFor="let item of filteredItems$ | async; trackBy: trackByItemKey"
			[value]="item.key"
			[hidden]="item.isHidden"
			(click)="$event.stopPropagation()">
			{{ item.value }}
		</mat-option>
	</mat-select>
	<!-- Non control -->
	<mat-select
		#matSelect
		*ngIf="!isControl"
		[id]="id"
		[placeholder]="placeholder"
		[multiple]="multiple"
		[disabled]="disabled || refreshing"
		[value]="value"
		[required]="required"
		(openedChange)="changeOpened($event)"
		(selectionChange)="onSelectionChange($event)">
		<ng-container *ngIf="{ value: isNoResults$ | async } as isNoResults">
			<mat-option *ngIf="!noItems && isSearch" disabled matSelectSearchOption [isNoResults]="isNoResults.value" (click)="$event.stopPropagation()">
				<ng-container *ngTemplateOutlet="search; context: isNoResults"></ng-container>
			</mat-option>
		</ng-container>
		<mat-option
			class="mat-option"
			*ngFor="let item of filteredItems$ | async; trackBy: trackByItemKey"
			[value]="item.key"
			[hidden]="item.isHidden"
			(click)="$event.stopPropagation()">
			{{ item.value }}
		</mat-option>
	</mat-select>
</div>

<ng-template #search let-isNoResultValue="value">
	<div *ngIf="isSelectButtons" class="mat-select-wrapper__buttons">
		<button
			*ngIf="showAddNewItem && isNoResultValue"
			id="mat-select_buttons_add-item"
			class="btn btn--link mat-select-wrapper__link-btn"
			(click)="addNewItem()">
			Add
		</button>
		<button
			id="mat-select_buttons_select-all"
			class="btn btn--link mat-select-wrapper__link-btn"
			[disabled]="isNoResultValue"
			[attr.aria-disabled]="isNoResultValue"
			(click)="selectAll()">
			Select all
		</button>
		<button
			id="mat-select_buttons_clear-all"
			class="btn btn--link mat-select-wrapper__link-btn"
			[disabled]="isNoResultValue"
			[attr.aria-disabled]="isNoResultValue"
			(click)="clearAll()">
			Clear all
		</button>
	</div>
	<fitech-workspace-mat-select-search
		[isSelectButtons]="true"
		[control]="matSelectSearchControl"
		[isNoResults]="isNoResultValue">
	</fitech-workspace-mat-select-search>
</ng-template>
