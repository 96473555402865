<mat-form-field (click)="$event.stopPropagation()">
	<fitech-workspace-mat-select
		[initialPlaceholder]="question.label"
		[isRefreshButton]="false"
		[isSelectButtons]="isSelectButtons"
		[showAddNewItem]="showAddNewItem"
		[multiple]="!singleSelect"
		[forceClosingOnClickOutside]="true"
		[items]="items"
		[formControl]="matSelectControl"
		(selectedAll)="selectAll()"
		(clearedAll)="clearAll()"
		(addedNewItem)="addNewItem($event)"
		(openedChange)="changeOpened()">
	</fitech-workspace-mat-select>
</mat-form-field>
