import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { StringUtils } from './string-utils';

export class CustomValidators {
	/**
	 * @description
	 * Validator that requires at least one control to be selected.
	 *
	 * @returns An error map with the `{ minOneRequired: true }` property
	 * if the validation check fails, otherwise `null`.
	 */
	static atLeastOneCheckboxSelectedValidator(): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
			const formGroup: UntypedFormGroup = control as UntypedFormGroup;

			const isAnySelected: boolean =
				Object.keys(formGroup.controls).filter((key: string): boolean => formGroup.controls[key].value as boolean).length > 0;
			return isAnySelected ? null : { minOneRequired: true };
		};

		return validatorFn;
	}

	/**
	 * @description Validator that requires value to be comma separated list of N numbers (can be float point).
	 *
	 * @param listLength Allowed number of items in comma separated list.
	 * @param multiples Decides whether validator should check if list has exact number of items according to listLength param or its multiples.
	 *
	 * @returns An error map with the `{ listInvalid: true }` property if the validation check fails, otherwise `null`.
	 */
	static isCommaSeparatedListOfIntOrFloatPointNumbersValidator(listLength: number = 3, multiples: boolean = false): ValidatorFn {
		const validatorFn: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
			const splitted = control.value?.split(',');
			const multiplesCondition: boolean = multiples ? splitted?.length % listLength === 0 : splitted?.length === listLength;
			const floatPointRegExp = /^\s*[-]?\d+(\.\d+)?\s*$/;

			return multiplesCondition && !splitted.some((x: string) => StringUtils.isNullOrWhiteSpace(x) || !floatPointRegExp.test(x) || isNaN(Number(x)))
				? null
				: { listInvalid: true };
		};

		return validatorFn;
	}
}
