import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AsEnumerable } from 'linq-es2015';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ErrorHandlingService, TranslateService } from '@fitech-workspace/core-lib';
import { Alarm, MachineComponent, PlanRepositoryService, State } from '@fitech-workspace/fisense-common-lib';
import { DateRangeTypeEnum, IDateRange } from '@fitech-workspace/shared/ui/controls-lib';
import { CmBackendApiService } from 'apps/machines-app/src/app/shared/api/cm-backend-api.service';
import { ThtMachineDialogOptions } from '../../models/tht-machine-dialog-options.model';
import { getColumnsForThtMachine } from '../columns/tht-machine-dialog.columns';

@Component({
	selector: 'fitech-workspace-tht-machine-dialog',
	templateUrl: './tht-machine-dialog.component.html',
	styleUrls: ['./tht-machine-dialog.component.scss'],
})
export class ThtMachineDialogComponent implements OnInit, OnDestroy {
	title: string;
	subTitle: string;
	machineAlarmsGridData: Alarm[];
	currentMachineSensorStates: State[];
	isCurrentStateActive = true;
	dateRange: IDateRange;
	loadingAlarms = false;
	onDataChangedSubs: Subscription;
	machineId: number;
	gridColumns: any[];
	machineStatusSourceProperty = 'machineStatus';
	machineStatusState: State;
	lastItemSourceProperty = 'item';
	lastItemState: State;
	pcbCounterSourceProperty = 'pcbCounter';
	pcbCounterState: State;
	elementCounterSourceProperty = 'elementCounter';
	elementCounterState: State;
	globalCycleCounterSourceProperty = 'globalCycleCounter';
	globalCycleCounterState: State;

	private _apiSubscription: Subscription;

	constructor(
		private _dialogRef: MatDialogRef<MachineComponent>,
		private _backendApi: CmBackendApiService,
		private _planRepositoryService: PlanRepositoryService,
		private _translateService: TranslateService,
		private _errorHandlingService: ErrorHandlingService
	) {}

	ngOnInit(): void {
		this.gridColumns = getColumnsForThtMachine(this._translateService);
		this.dateRange = {
			from: moment().startOf('day').toDate(),
			to: moment().startOf('day').add(1, 'days').toDate(),
			type: DateRangeTypeEnum.LastDay,
		};
		this.getAlarms();
		this.onDataChangedSubs = this._planRepositoryService.dataChanged.subscribe(() => this.onDataChanged());
	}

	ngOnDestroy(): void {
		this._apiSubscription?.unsubscribe();
		this.onDataChangedSubs?.unsubscribe();
	}

	initialize(options: ThtMachineDialogOptions): void {
		this.title = options.title;
		this.subTitle = options.subTitle;
		this.machineId = options.machineId;
		this.formatStateIndicators(options.currentMachineSensorStatesGridData);
	}

	getAlarms(): void {
		this._apiSubscription?.unsubscribe();
		this._apiSubscription = null;

		this.loadingAlarms = true;
		this._apiSubscription = this._backendApi.getAlarms(this.dateRange.from, this.dateRange.to, null, null, null, null, [this.machineId]).subscribe(
			(res) => {
				this.machineAlarmsGridData = res;
				this.loadingAlarms = false;
			},
			(error: HttpErrorResponse) => {
				this._errorHandlingService.handleError(error, 'Fetching alarms failed');
				this.loadingAlarms = false;
			}
		);
	}

	onDataChanged(): void {
		const machineStates = this._planRepositoryService.getSensorStates();
		const states = (AsEnumerable(machineStates).FirstOrDefault((x) => (x as any).sourceId == this.machineId) as any).sensorsStates as State[];
		this.formatStateIndicators(states);
	}

	onDateRangeChanged(event: IDateRange): void {
		this.dateRange = event;
	}

	onCancel(): void {
		this._dialogRef.close();
	}

	private formatStateIndicators(states: State[]): void {
		const statesEnum = AsEnumerable(states);
		this.machineStatusState = statesEnum.FirstOrDefault((x) => x.source.sourceProperty == this.machineStatusSourceProperty);
		this.lastItemState = statesEnum.FirstOrDefault((x) => x.source.sourceProperty == this.lastItemSourceProperty);
		this.pcbCounterState = statesEnum.FirstOrDefault((x) => x.source.sourceProperty == this.pcbCounterSourceProperty);
		this.elementCounterState = statesEnum.FirstOrDefault((x) => x.source.sourceProperty == this.elementCounterSourceProperty);
		this.globalCycleCounterState = statesEnum.FirstOrDefault((x) => x.source.sourceProperty == this.globalCycleCounterSourceProperty);
	}
}
