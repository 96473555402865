import { Buffer } from 'buffer';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ArrayBufferFileReader, NotificationService } from '@fitech-workspace/core-lib';
import { Subscription } from 'rxjs';
import { FileQuestion } from '../../models/question-file';

@Component({
	selector: 'fitech-workspace-fileuploader-md',
	templateUrl: './dynamic-form-file-uploader.component.html',
	styleUrls: ['./dynamic-form-file-uploader.component.scss'],
})
export class DynamicFormFileUploaderComponent implements OnInit, OnDestroy {
	@Input() question: FileQuestion;
	@Input() form: UntypedFormGroup;
	@Input() showUndoButton: boolean;

	fileName: string;
	fileEvent: any;
	file: any;
	filePath: string;
	imageSource: any;
	fileSelected = false;
	fileUploaded = false;

	private _subscriptions = new Subscription();

	get isImgType(): boolean {
		return this.question.uploadFile ? true : false;
	}

	get fileLength(): number {
		if (this.form.controls[this.question.key].value) {
			return this.form.controls[this.question.key].value.length;
		}

		if (this.question.value && !this.form.controls[this.question.key].dirty) {
			return this.question.value.length;
		}

		return 0;
	}

	get fileValue(): string {
		if (this.form.controls[this.question.key].value) {
			return this.form.controls[this.question.key].value;
		}

		if (this.question.value) {
			return this.question.value;
		}
		return null;
	}

	constructor(private _notificationService: NotificationService, private _cd: ChangeDetectorRef) {}

	ngOnInit(): void {
		this._subscriptions.add(
			this.question.dataExchangeSub?.subscribe((file: File | Blob) => {
				if (file) {
					this.selectFile(undefined, file);
				} else {
					this.file && this.clear();
				}
				this._cd.detectChanges();
			})
		);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	selectFile(e?: InputEvent, file?: Blob | File): void {
		let target: HTMLInputElement;

		if (e) {
			target = e?.currentTarget as HTMLInputElement;
			this.file = target?.files[0];
			this.question.dataExchangeSub?.next(this.file);
		}

		if (file) {
			this.file = file;
		}

		if (this.file) {
			this.fileName = this.file.name;
			this.fileUploaded = false;
			this.fileSelected = true;
		}

		if (!this.isImgType) {
			// reader only for array buffer
			const reader = new ArrayBufferFileReader();

			reader.Read(e).subscribe((res: string) => {
				if (this.question.useBase64) {
					res = Buffer.from(res, 'binary').toString('base64');
				}

				this.form.controls[this.question.key].setValue(res);
				this.form.controls[this.question.key].markAsDirty();
			});
		} else {
			this.updateImageSourceWithDataUrlFromFile(target?.files[0] || this.file);
		}
	}

	uploadFile(): void {
		this.question.uploadFile(this.file).subscribe(
			(path: string) => {
				this.form.controls[this.question.key].setValue(path);
				this.form.controls[this.question.key].markAsDirty();
				this.filePath = path;
				this.fileSelected = false;
				this.fileUploaded = true;
				this._notificationService.showSuccess('file uploaded');
			},
			(err: HttpErrorResponse) => {
				this._notificationService.showError(err.message);
			}
		);
	}

	undo(): void {
		this.fileSelected = false;
		this.fileUploaded = false;
		this.file = null;
		this.fileName = null;
		this.question.value = null;
		this.imageSource = null;
		this.form.controls[this.question.key].setValue(this.question.value, { emitEvent: false });
		this.form.controls[this.question.key].markAsPristine();
		this.form.controls[this.question.key].markAsUntouched();

		this.question.dataExchangeSub?.next(null);
	}

	clear(): void {
		if (this.isImgType) {
			this.undo();
		} else {
			this.fileSelected = false;
			this.form.controls[this.question.key].setValue(null, { emitEvent: false });
			this.form.controls[this.question.key].markAsDirty();
		}
	}

	private updateImageSourceWithDataUrlFromFile(image: File | Blob): void {
		if (!image) {
			return;
		}

		const reader = new FileReader();
		reader.readAsDataURL(image);
		reader.onload = (event: any): void => {
			this.imageSource = event.currentTarget?.result;
		};
	}
}
