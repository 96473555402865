import { Component, OnInit, Input, Inject } from '@angular/core';
import { UserService, AUTH_ENV_CONFIG } from '../../services/user.service';
import {
  UntypedFormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NotificationService } from '@fitech-workspace/core-lib';
import { timer } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'fitech-workspace-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input() logoSrc: string;
  @Input() logoAdSrc: string;
  @Input() sigInAccountEnabled = true;
  @Input() sigInAdEnabled = true;
  private environmentConfig;
  returnUrl: any;
  $counter: any;
  secondsToRedirect: number;
  isInProgress: boolean;
  form: UntypedFormGroup;
  formAd: UntypedFormGroup;
  userNameControl = new UntypedFormControl();
  passwordControl = new UntypedFormControl();
  loginInput: string;
  passwordInput: string;
  isSubmitting = false;
  isSubmittingAd = false;
  isSubmitted = false;
  isAdLoginSelected: boolean;

  constructor(
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private activateRoute: ActivatedRoute,
  ) {

  }

  private autoRedirect() {
    this.secondsToRedirect = 15;
    const source = timer(1000, 1000);
    const subscribe = source.subscribe(val => {
      this.secondsToRedirect--;
      if (this.secondsToRedirect <= 0) {
        this.login();
      }
    });
  }

  ngOnInit() {
    this.createFormGroups(this.formBuilder);
    this.activateRoute.data.subscribe(data => {
      if (data != null) {
        if (data.sigInAdEnabled != null) {
          this.sigInAdEnabled = data.sigInAdEnabled;
          this.isAdLoginSelected = this.sigInAdEnabled;
        }
        if (data.sigInAccountEnabled != null)
          this.sigInAccountEnabled = data.sigInAccountEnabled;
        if (data.logoSrc != null)
          this.logoSrc = data.logoSrc;
        if (data.logoAdSrc != null)
          this.logoAdSrc = data.logoAdSrc;
      }
    });
  }

  //for ionic app
  ionViewWillEnter() {
    this.isSubmitted = false;
  }

  createFormGroups(fb: UntypedFormBuilder) {
    this.form = fb.group({
      userName: this.userNameControl,
      password: this.passwordControl
    });

    this.formAd = fb.group({});

    this.userNameControl.setValidators(Validators.required);
    this.passwordControl.setValidators(Validators.required);
  }

  shouldDisableAction() {
    return (
      this.isSubmitting ||
      this.isSubmitted ||
      this.userNameControl.invalid ||
      this.passwordControl.invalid
    );
  }

  login(): void {
    this.isSubmitting = true;
    this.userService
      .loginWith(this.userNameControl.value, this.passwordControl.value)
      .subscribe(
        res => {
          this.isSubmitting = false;
          this.isSubmitted = true;
        },
        err => {
          this.isSubmitting = false;
          let errMsg = 'Bad login attempt';
          if (err.error && err.error.error_description) {
            errMsg = err.error.error_description;
          }
          this.notificationService.showError(errMsg);
        }
      );
  }

  loginAd(): void {
    this.isSubmittingAd = true;
    this.userService
      .loginWithAzureAd();
  }

  selectAdLogin(isAdLoginSelected: boolean) {
    this.isAdLoginSelected = isAdLoginSelected;
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.login();
    }
  }

  isValid(key) {
    return this.form.controls[key].valid;
  }
  isTouched(key) {
    return this.form.controls[key].touched;
  }
  getErrorMsg(key) {
    let errorMsg = '';
    if (this.isTouched(key)) {
      if (this.form.controls[key].hasError('required')) {
        errorMsg += 'Field is required. ';
      }
    }
    return errorMsg;
  }
}
