import { Injectable } from '@angular/core';
import { IMasterObjectDefinition, MasterObjectsRepositoryQl, QueryNameGraphQLEnum } from '@fitech-workspace/master-data-lib';
import { Apollo } from 'apollo-angular';

const definition: IMasterObjectDefinition = {
	type: 'shift',
	graphQlQuery: `query { shiftsQuery {
         all {
                                clientId
                                dateCreated
                                dateModified
                                id
                                name
                                order
                                timeFrom
                                timeTo
                            client { name, id }
                            shiftLocations { id, dateCreated, locationId, shiftId, clientId, location { name, id }},
         }}}`,
	graphQlUpdate: `

                    mutation update (
                                $clientId: Int!
                                $dateCreated: DateTime!
                                $id: Int!
                                $name: String
                                $order: Int!
                                $timeFrom: String
                                $timeTo: String
                                $shiftLocations: [ShiftLocationInputTypeInput])
                    { shifts{ update(inputType: {
                                clientId: $clientId
                                dateCreated: $dateCreated
                                id: $id
                                name: $name
                                order: $order
                                timeFrom: $timeFrom
                                timeTo: $timeTo
                                shiftLocations: $shiftLocations
                     })
                     { id } }}`,
	graphQlCreate: `mutation create (
                                $clientId: Int!
                                $name: String
                                $order: Int!
                                $timeFrom: String
                                $timeTo: String
                                $shiftLocations: [ShiftLocationInputTypeInput])
                    { shifts{ create(inputType: {
                                clientId: $clientId
                                name: $name
                                order: $order
                                timeFrom: $timeFrom
                                timeTo: $timeTo
                                shiftLocations: $shiftLocations
                     })
                     { id } }}`,
};

@Injectable({
	providedIn: 'root',
})
export class ShiftsRepository extends MasterObjectsRepositoryQl {
	constructor(apollo: Apollo) {
		super(apollo, definition, QueryNameGraphQLEnum.ShiftsQuery);
	}
}
