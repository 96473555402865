/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSelector } from '@ngrx/store';
import { ArrayUtils, GroupedUserSettings, UserSettingBaseDto, UserSettingDto } from '@fitech-workspace/core-lib';
import { AppState, UserSettingsState } from '../models';

const selectUserSettingsState = (state: AppState): UserSettingsState => state.userSettingsState;

export const selectGlobalUserSettings = createSelector(selectUserSettingsState, (state: UserSettingsState) => state.globalUserSettings.data);

export const selectGlobalUserSetting = (name: string) =>
	createSelector(selectUserSettingsState, (state: UserSettingsState) => state.globalUserSettings.data.find((x: UserSettingBaseDto) => x.name === name));

export const selectLocalUserSettings = (view: string, section?: string, element?: string) =>
	createSelector(selectUserSettingsState, (state: UserSettingsState) =>
		state.localUserSettings.data.filter(
			(x: UserSettingDto) =>
				x.userSettingsLocation?.view === view &&
				(typeof section === 'undefined' || x.userSettingsLocation?.section === section) &&
				(typeof element === 'undefined' || x.userSettingsLocation?.element === element)
		)
	);

export const selectGroupedLocalUserSettings = createSelector(selectUserSettingsState, (state: UserSettingsState) => {
	const grouped = ArrayUtils.groupBy(
		state.localUserSettings.data,
		(x: UserSettingDto) => `${x.userSettingsLocation.view}||${x.userSettingsLocation.section}||${x.userSettingsLocation.element}`
	);
	return Array.from(grouped.keys()).map((key: string): GroupedUserSettings => {
		const settings: UserSettingDto[] = grouped.get(key);
		return {
			location: settings[0].userSettingsLocation,
			ids: settings.map((setting: UserSettingDto) => setting.id),
			settings: settings,
		};
	});
});

export const selectIsLocalUserSettingsLoaded = createSelector(selectUserSettingsState, (state: UserSettingsState) => state.localUserSettings.isLoaded);

export const selectIsBatchedUserSettings = createSelector(selectUserSettingsState, (state: UserSettingsState) => state.batchedUserSettings.data.length > 0);

export const selectIsAllDataSaved = createSelector(selectUserSettingsState, (state: UserSettingsState) => !state.batchedUserSettings.data.length);

export const selectBatchedUserSettings = createSelector(selectUserSettingsState, (state: UserSettingsState) => state.batchedUserSettings.data);

export const selectBatchedUserSettingsProcessingData = createSelector(selectUserSettingsState, (state: UserSettingsState) => state.batchedUserSettings);

export const selectBatchedUserSettingsRetryErrors = createSelector(
	selectUserSettingsState,
	(state: UserSettingsState) => state.batchedUserSettings.retryErrors
);
