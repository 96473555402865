<div class="md-chips">
  <div *ngFor="let item of data" class="md-chip">
    <div class="md-chip-container">
      <button mat-icon-button class="md-chip-btn" matTooltip="{{item.tooltip  | translate}}: {{item.id}}">
        <fitech-workspace-icon id="md-chip-btn-icon" [iconKey]="item.icon" (click)="onClick(item)">
        </fitech-workspace-icon>
      </button>
    </div>
    {{item.value}}
  </div>
</div>