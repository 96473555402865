import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SparklineRendererComponent } from './components/sparkline-renderer/sparkline-renderer.component';
import { SparklineComponent } from './components/sparkline-renderer/sparkline.component';
import { ErrorIndicatorRenderer } from './components/error-indicator.renderer';

@NgModule({
  declarations: [
    SparklineRendererComponent,
    SparklineComponent,
    ErrorIndicatorRenderer
  ],
  imports: [
    CommonModule
  ], exports: [
    SparklineRendererComponent,
    SparklineComponent,
    ErrorIndicatorRenderer
  ]
})
export class SharedUiGridsAddonsModule { }
