import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ColorPickerModule } from 'ngx-color-picker';
import { CoreLibModule } from '@fitech-workspace/core-lib';
import { AutocompleteMdComponent } from './components/autocomplete-md/autocomplete-md.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicFormCheckboxInputComponent } from './components/dynamic-form-checkbox-input/dynamic-form-checkbox-input.component';
import { DynamicFormColorPickerComponent } from './components/dynamic-form-color-picker/dynamic-form-color-picker.component';
import { DynamicFormFileUploaderComponent } from './components/dynamic-form-file-uploader/dynamic-form-file-uploader.component';
import { DynamicFormInputComponent } from './components/dynamic-form-input/dynamic-form-input.component';
import { DynamicFormMultiDropdownInputComponent } from './components/dynamic-form-multi-dropdown-input/dynamic-form-multi-dropdown-input.component';
import { DynamicFormSelectInputComponent } from './components/dynamic-form-select-input/dynamic-form-select-input.component';
import { DynamicFormSwitchInputComponent } from './components/dynamic-form-switch/dynamic-form-switch-input.component';
import { MatSelectExtendedComponent } from './components/mat-select-extended/mat-select-extended.component';
import { MatSelectSearchComponent } from './components/mat-select-search/mat-select-search.component';
import { MatSelectSearchOptionDirective } from './directives/mat-select-search-option.directive';

@NgModule({
	declarations: [
		AutocompleteMdComponent,
		DynamicFormComponent,
		DynamicFormInputComponent,
		DynamicFormSelectInputComponent,
		DynamicFormMultiDropdownInputComponent,
		DynamicFormCheckboxInputComponent,
		DynamicFormFileUploaderComponent,
		DynamicFormColorPickerComponent,
		DynamicFormSwitchInputComponent,
		MatSelectSearchComponent,
		MatSelectSearchOptionDirective,
		MatSelectExtendedComponent,
	],
	imports: [CommonModule, CoreLibModule, MatAutocompleteModule, MatFormFieldModule, ColorPickerModule],
	exports: [
		AutocompleteMdComponent,
		DynamicFormComponent,
		DynamicFormInputComponent,
		DynamicFormSelectInputComponent,
		DynamicFormMultiDropdownInputComponent,
		DynamicFormCheckboxInputComponent,
		DynamicFormFileUploaderComponent,
		MatSelectSearchComponent,
		MatSelectSearchOptionDirective,
		MatSelectExtendedComponent,
	],
})
export class SharedUiInputsMdModule {}
