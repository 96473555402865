import { UserAllowedClientDto } from '@fitech-workspace/shared/data-access/master-data-api-lib';

export class UserAllowedClient implements UserAllowedClientDto {
    clientId: number;
    clientName: string;

    static fromJS(data: any): UserAllowedClient {
        data = typeof data === 'object' ? data : {};
        const result = new UserAllowedClient();
        result.init(data);
        return result;
    }

    init(data?: any) {
        if (data) {
            this.clientId = data["clientId"];
            this.clientName = data["clientName"];
        }
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["clientId"] = this.clientId;
        data["clientName"] = this.clientName;
        return data;
    }
}


