import { SensorTypes } from './sensor-types.model';
import { SensorDisplayTypeEnum } from './sensor-display-type.enum';

export class Sensor {
  id: number;
  name: string;
  type: string;
  units: string;
  clientId?: number;
  zoneName?: string;
  deviceId: number;
  deviceImei?: string;
  locationName?: string;
  alarmHigh: number;
  highHisteresisValue: number;
  alarmLow: number;
  lowHisteresisValue: number;
  positionX: number;
  positionY: number;
  badgeClass?: string;
  visible: number;
  enabled: number;
  minValue?: number;
  maxValue?: number;
  machineId?: number;
  machineName?: string;
  machinePositionX?: number;
  machinePositionY?: number;
  isMachineDefaultSensor: boolean;
  addedToReport = false;
  sourceProperty: string;
  sensorsGroupType?: string;

  get displayType(): SensorDisplayTypeEnum {
    const type = SensorTypes.getByType(this.type);

    if (type)
      return type.displayType;

    return SensorDisplayTypeEnum.analog;
  }

  constructor(data?: any) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  static createFromState(data: any): Sensor {
    const sensor = new Sensor();

    if (data) {
      sensor.id = data['sensorId'];
      sensor.name = data['sensorName'];
      sensor.type = data['sensorPointType'];
      sensor.units = data['sensorUnits'];
      sensor.clientId = data['sensorClientId'];
      sensor.zoneName = data['sensorZoneName'];
      sensor.deviceImei = data['sensorDeviceImei'];
      sensor.deviceId = data['sensorDeviceId'];
      sensor.locationName = data['sensorLocationName'];
      sensor.positionX = data['sensorPositionX'];
      sensor.positionY = data['sensorPositionY'];
      sensor.alarmHigh = data['sensorAlarmHighValue'];
      sensor.alarmLow = data['sensorAlarmLowValue'];
      sensor.lowHisteresisValue = data['sensorAlarmLowHisteresisValue'];
      sensor.highHisteresisValue = data['sensorAlarmHighHisteresisValue'];
      sensor.visible = data['sensorVisible'] ? data['sensorVisible'] : 0;
      sensor.enabled = data['sensorEnabled'] ? data['sensorEnabled'] : 0;
      sensor.minValue = +data['sensorMinValue'];
      sensor.maxValue = +data['sensorMaxValue'];
      sensor.machineId = data['machineId'];
      sensor.machineName = data['machineName'];
      sensor.machinePositionX = data['machinePositionX'];
      sensor.machinePositionY = data['machinePositionY'];
      sensor.isMachineDefaultSensor = data['isMachineDefaultSensor'];
      sensor.sourceProperty = data['sensorSourceProperty'];
      sensor.sensorsGroupType = data['sensorsGroupType'];
    }

    return sensor;
  }

  static createFromSensor(data: any): Sensor {
    const sensor = new Sensor(data); //most fields are mapped here

    if (data) {
      sensor.id = data['sensorId'];
      sensor.type = data['pointType'];
      sensor.positionX = data['floorPlanPositionX'];
      sensor.positionY = data['floorPlanPositionY'];
    }

    return sensor;
  }

  static getIconClass(type: string): string {
    if (!type)
      return null;
    return SensorTypes.getBySourceProperty(type)
      ? SensorTypes.getBySourceProperty(type).icon
      : 'far fa-question-circle';
  }
  static getSensorStateColor(state: string): any {
    return state === 'alarm-high'
      ? { state, color: 'rgba(220, 53, 70, 0.906)', priority: 3 } :
      state === 'alarm-low'
        ? { state, color: 'rgba(134, 98, 224, 0.8)', priority: 2 } :
        state === 'inactive'
          ? { state, color: 'rgba(155, 155, 155, 0.8)', priority: 0 } :
          state === 'active'
            ? { state, color: 'rgba(141, 201, 29, 0.824)', priority: 1 } :
            state === 'edited'
              ? { state, color: 'rgba(219, 184, 5, 0.78)', priority: 1 } :
              state === 'notedited'
                ? { state, color: 'rgba(26, 115, 232, 0.782)', priority: 0 } :
                state === 'disabled'
                  ? { state, color: 'rgba(26, 115, 232, 0.782)', priority: 0 } :
                  state === 'pause'
                    ? { state, color: 'rgba(196, 146, 219, 0.782)', priority: 0 } :
                    state === 'warning'
                      ? { state, color: 'rgba(253, 178, 0, 0.782)', priority: 0 } :
                      { state, color: 'rgba(26, 115, 232, 0.782)', priority: 0 };
  }
}
