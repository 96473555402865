<div class="modal fade" id="versionsDialog" tabindex="-1" role="dialog" aria-hidden="true" data-keyboard="false" data-backdrop="static">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-header-with-button">
					<h5>Versions</h5>
					<fitech-workspace-button-refresh [refreshing]="refreshing" [isSmaller]="true" (refreshed)="refresh()"></fitech-workspace-button-refresh>
				</div>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="content">
					<ng-container
						*ngTemplateOutlet="version; context: { appLabel: 'Analytics Platform', versionNumber: versions?.analyticsPlatform }"></ng-container>
					<ng-container *ngTemplateOutlet="version; context: { appLabel: 'Frontend API', versionNumber: versions?.frontendApi }"></ng-container>
					<ng-container
						*ngTemplateOutlet="version; context: { appLabel: 'Processing IoT Hub', versionNumber: versions?.processingIotHub }"></ng-container>
				</div>
				<div id="bottom-container-dialog">
					<button class="btn btn--tertiary" (click)="closeModal()">Back</button>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #version let-appLabel="appLabel" let-versionNumber="versionNumber">
	<span>{{ appLabel }}</span>
	<p class="version-number">
		<strong>{{ versionNumber }}</strong>
	</p>
</ng-template>
