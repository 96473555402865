import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapBaseComponent } from './components/map-base/map-base.component';

@NgModule({
  declarations: [
    MapBaseComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    MapBaseComponent
  ]
})
export class SharedUiMapsModule { }
