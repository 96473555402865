import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'fitech-workspace-simple-avatar-renderer',
  templateUrl: './simple-avatar-renderer.component.html',
  styleUrls: ['./simple-avatar-renderer.component.scss']
})
export class SimpleAvatarRendererComponent implements ICellRendererAngularComp {

  public params: any;
  text: any;
  hexColor: string;
  letter: any;


  refresh(params: any): boolean {
    return true;
  }
  agInit(params: any): void {
    if (params.value) {
      this.text = params.value;
      this.letter = this.text && this.text.length > 0 ? this.text[0] : null;
      this.hexColor = this.stringToHslColor(this.text);
    }
  }
  afterGuiAttached?(params?: any): void {
    this.params = params;
  }

  private stringToHslColor(input: string, saturation: number = 70, lightness = 50) {
    var hash = 0;
    for (var i = 0; i < input.length; i++) {
      hash = input.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
  }
}


